import '../Style/ContactUs.css';
import { useNavigate } from 'react-router-dom';

export default function ContactUs(){
    const navigate = useNavigate();

    const output = (
        <div id="ContactUs">
            <div className="logo-block">
                <img src="/Images/logo3.png"></img>
            </div>
            <button className='backButton' onClick={()=>navigate(-1)}>&#8592; Back</button>
            <div className='document'>
                <img className='theme' src="/Images/Background1.png"></img>
                <h1 className='title'>Contact Us</h1>
                <h3 className='email'>Customer Support Email:</h3>
                <p>scheduleeaseee@gmail.com</p>
                <h3 className='number'>Contact Support Number:</h3>
                <p>-</p>
            </div>
        </div>
    );

    return output;
}