import '../Style/UpdateFaq.css';
import { useState, useEffect, useContext } from 'react';
import { updateFaq } from '../Actions/Faq';

export default function UpdateFaq({setUpdateFaq, faq}){
    const [faqData, setFaqData] = useState({question:faq.question, answer:faq.answer});

    const handleChange = (e)=>{
        const {value, name} = e.target;
        setFaqData({...faqData,[name]:value});
    }

    const handleSubmit = (e)=>{
        e.preventDefault();
        updateFaq(faq._id,faqData);
        setFaqData({question:'', answer:''});
        setUpdateFaq(false);
    }

    const output = (<div id='UpdateFaq'>
                        <div className="table" >
                            <p className='close-tab' onClick={()=>setUpdateFaq(false)}>&#10006;</p>
                            <h2>Update This FAQ</h2>
                            <div className='qBlock'>
                                <p>Question:</p>
                                <textarea className='questionInput' defaultValue={faqData.question} name="question" onChange={handleChange}></textarea>
                            </div>
                            <div className='aBlock'>
                                <p>Answer:</p>
                                <textarea className='answerInput' defaultValue={faqData.answer} name="answer" onChange={handleChange}></textarea>
                            </div>
                            <button onClick={handleSubmit}>Submit</button>
                        </div>                       
                    </div>);

    return output;
}