import "../Style/SignIn.css";
import { Link } from "react-router-dom";
import { useState, useEffect, useRef } from "react";
import { signIn } from "../Actions/User";
import { UserDataContext } from "../Data/Session";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";

function SignIn(){
    const [isFocused1, setFocused1] = useState(false);
    const [isFocused2, setFocused2] = useState(false);
    const [input, setInput] = useState({
        email: null,
        password: null
    });
    const [display1, setDisplay1] = useState('none');
    const [display2, setDisplay2] = useState('none');
    const {sessionId} = useContext(UserDataContext);
    const navigate = useNavigate();

    function handleBlur1(){
        setFocused1(false);
    }

    function handleBlur2(){
        setFocused2(false);
    }

    function handleFocus1(){
        setFocused1(true);
    }

    function handleFocus2(){
        setFocused2(true);
    }

    const handleSubmit = async(e)=>{
        e.preventDefault();
       
        const response = await signIn({...input, sessionId:sessionId});

        if(response&& response.data && !response.data.email){
            setDisplay1('block');
        }
        if(response&& response.data && !response.data.password){
            setDisplay2('block');
        }
        else if(response&& response.data && response.data.success){
            navigate('/');
        }
    }

    function handleChange(e){
        const {name, value} = e.target;

        setInput({
            ...input,
            [name]: value
        })

        if(name=='email'){
            setDisplay1('none');
        }
        else if(name == 'password'){
            setDisplay2('none');
        }
    }

    const output = (<>
                        <div id="signInPage">
                            <div className="logo-block">
                                <img src="/Images/logo3.png"></img>
                            </div>
                            <div className="signInUnderliner">
                                <div className="siLine1"></div>
                                <div className="siLine2"></div>
                            </div>
                            <form onSubmit={handleSubmit}>
                                <h2>Enter your email and password to sign in.</h2>
                                <div className='email'>
                                    <label htmlFor="emailInput" className={`emailPlaceholderSI ${isFocused1 || input.email ? 'active':''}`}>
                                        Email
                                    </label>
                                    <input id='emailInput' type="text" name="email" onFocus={handleFocus1} onBlur={handleBlur1} onChange={handleChange}></input>
                                    <p className="required" style={{display:display1}}>Incorrect Email</p>
                                </div>
                                <div className='password'>
                                    <label htmlFor="passwordInput" className={`passwordPlaceholderSI ${isFocused2 || input.password ? 'active':''}`}>
                                        Password
                                    </label>
                                    <input id='passwordInput' type="text" name="password" onFocus={handleFocus2} onBlur={handleBlur2} onChange={handleChange}></input>
                                    <p className="required" style={{display:display2}}>Incorrect Password</p>
                                    <Link to="/forgotPassword">Forgot Password?</Link>
                                </div>
                                <div className="sign-in">
                                    <button type='submit'>
                                        Sign In
                                    </button> 
                                </div> 
                                <div className="link">
                                    <div>
                                        <p>Don't have an account?</p>
                                        <Link to="/signUp1">Sign Up</Link>  
                                    </div>
                                </div>
                            </form>   
                        </div>
        
                    </>);

    return output;
}

export default SignIn; 