import '../Style/Scheduling.css';
import { useState, useEffect, useRef, useContext} from 'react';
import { UserDataContext } from '../Data/Session';
import { startOfMonth, endOfMonth, startOfWeek, endOfWeek, eachDayOfInterval, format } from 'date-fns';
import { createSchedule, deleteSchedule } from '../Actions/Schedule';

export default function Scheduling({serviceType, setScheduleOpen}){

    const [currentHour, setHour] = useState(new Date().getHours())
    const [starting, setStarting] = useState(true)
    const [Year, setYear] = useState(new Date().getFullYear());    
    const [Month, setMonth] = useState(new Date().getMonth());
    const [Day, setDay] = useState(new Date().getDate());
    const [calendarDates, setCalendarDates] = useState([]);
    const [Schedule, setSchedule] = useState(null);
    const [Slot, setSlot] = useState(null);
    const [dayColor,setDayColor] = useState(['black','black','black','black','black','black','black','black','black','black','black','black','black','black','black','black','black','black','black','black','black','black','black','black','black','black','black','black','black','black','black','black','black','black','black','black','black','black','black','black','black','black']);
    const [dayFont,setDayFont] = useState(['15px','15px','15px','15px','15px','15px','15px','15px','15px','15px','15px','15px','15px','15px','15px','15px','15px','15px','15px','15px','15px','15px','15px','15px','15px','15px','15px','15px','15px','15px','15px','15px','15px','15px','15px','15px','15px','15px','15px','15px','15px','15px']);
    const [dayFontWeight, setDayFontWeight] = useState(['normal','normal','normal','normal','normal','normal','normal','normal','normal','normal','normal','normal','normal','normal','normal','normal','normal','normal','normal','normal','normal','normal','normal','normal','normal','normal','normal','normal','normal','normal','normal','normal','normal','normal','normal','normal','normal','normal','normal','normal','normal','normal']);
    const [Availability, setAvailability] = useState([true,true,true,true,true,true]);
    const [Resting, setResting] = useState([false,false,false,false,false,false]);
    const {userData, bookings, schedules} = useContext(UserDataContext);        
      
    const monthsArray = ['January','February','March','April','May','June','July','August','September','October','November','December'];
    const houseSlots = ['','8:00-12:00','13:00-17:00'];
    const carSlots = ['','7:00-8:00','9:00-10:00','11:00-12:00','13:00-14:00','15:00-16:00'];
    const gardenSlots = ['','7:00-11:00','12:00-16:00'];
    const poolSlots = ['','8:00-10:00','11:00-13:00','14:00-16:00'];
    const negotiableSlots = ['','7:00-11:00','12:00-16:00']    

    function NullFunc(){
    }
         
    function MonthUp(){
        if(Month==11){
            setMonth(0);
            setYear(Year+1);
        }
        else{
            setMonth(Month+1);
        }
    }
    
    function MonthDown(){
        if(Month==0){
            setMonth(11);
            setYear(Year-1);
        }
        else{
            setMonth(Month-1);
        }
    }
        
    function ScheduleDate(dateNo){ 
        if((format(calendarDates[dateNo], 'dd/MM/yyyy') != Schedule && ( calendarDates[dateNo]-(new Date()))>0 || format(calendarDates[dateNo], 'dd/MM/yyyy')==format(new Date(), 'dd/MM/yyyy'))){
            setSchedule(format(calendarDates[dateNo], 'dd/MM/yyyy')); 
            let colors = ['black','black','black','black','black','black','black','black','black','black','black','black','black','black','black','black','black','black','black','black','black','black','black','black','black','black','black','black','black','black','black','black','black','black','black','black','black','black','black','black','black','black'];
            let fonts = ['15px','15px','15px','15px','15px','15px','15px','15px','15px','15px','15px','15px','15px','15px','15px','15px','15px','15px','15px','15px','15px','15px','15px','15px','15px','15px','15px','15px','15px','15px','15px','15px','15px','15px','15px','15px','15px','15px','15px','15px','15px','15px'];
            let weights = ['normal','normal','normal','normal','normal','normal','normal','normal','normal','normal','normal','normal','normal','normal','normal','normal','normal','normal','normal','normal','normal','normal','normal','normal','normal','normal','normal','normal','normal','normal','normal','normal','normal','normal','normal','normal','normal','normal','normal','normal','normal','normal']
            colors[dateNo] = 'darkblue';
            fonts[dateNo] = '20px';
            weights[dateNo] = 'bold';
            setDayColor(colors);
            setDayFont(fonts);
            setDayFontWeight(weights);
        }
    }
    
    function ScheduleSlot(slotNo){  
        const data = {createdDate:new Date().getTime(), 
                      service: serviceType, 
                      servicerId: userData._id, 
                      type: (serviceType=='Pool Cleaning' || serviceType=='Car Cleaning' || serviceType=='House Cleaning' || serviceType=='Gardening')?'fixed':'negotiable',  
                      schedule:{date: Schedule,
                                slot: slotNo 
                                }
                    }
        // console.log('Schedule Added: ',data);
        createSchedule(data);
    }
    
    function UnScheduleSlot(slotNo){
        const schedule = schedules.find((data)=>(data.schedule.date==Schedule && data.schedule.slot==slotNo))

        if(schedule){
            // console.log('Schedule Removed: ',schedule);
            deleteSchedule(schedule._id);
        }
    }
           
    //Setting up the calendar dates for the selected month
    useEffect(()=>{
        const startDate = startOfWeek(startOfMonth(new Date(Year, Month)));
        const endDate = endOfWeek(endOfMonth(new Date(Year, Month)));
    
        const dates = eachDayOfInterval({ start: startDate, end: endDate });
        setCalendarDates(dates);
    },[Year,Month]);
    
    //Setting the current day as the initial schedule date selected 
    useEffect(() => {
        if(calendarDates.length>0){
            // Initialize the colors array to all 'black'
            let colors = new Array(calendarDates.length).fill('black');
            let fonts = new Array(calendarDates.length).fill('15px');
            let weights = new Array(calendarDates.length).fill('normal');
            
            // Find the index of the current day in the dates array
            calendarDates.forEach((date, index) => {
                if(Schedule && Schedule == format(date, 'dd/MM/yyyy')){
                    colors[index] = 'darkblue'; // Set selected day color to darkblue
                    fonts[index] = '20px';
                    weights[index] = 'bold';
                    setSchedule(format(date, 'dd/MM/yyyy')); 
                }
                else if (starting==true && date.getDate() === Day && date.getMonth() === Month) {
                    colors[index] = 'darkblue'; // Set current day color to darkblue
                    fonts[index] = '20px';
                    weights[index] = 'bold';
                    setSchedule(format(date, 'dd/MM/yyyy')); 
                }
            });
        
            // Set the day color state
            setDayColor(colors);
            setDayFont(fonts);
            setDayFontWeight(weights);
            setStarting(false);
        }
    }, [calendarDates]);
    
    useEffect(()=>{
        if(Schedule && bookings && currentHour){
            const currentDate = format(new Date(), 'dd/MM/yyyy');
            const filteredBookings = bookings.filter((booking)=>(booking.appointment.date==Schedule && booking.servicerId==userData._id)) //get all bookings on the selected calendar date
            let availability = [true,true,true,true,true,true]; //There can be at most 5 slots, but slots start at index 1  (for car cleaning)
    
            //Loop through each booking on that selected day
            filteredBookings.forEach((booking, index)=>{
                
                if(booking.appointment.slot2){
                    availability[booking.appointment.slot1] = false;
                    availability[booking.appointment.slot2] = false;
                }
                else{
                    availability[booking.appointment.slot1] = false;
                }
            });
            
            if(serviceType == 'House Cleaner' && currentDate==Schedule){
                if(currentHour>13 || currentHour==13){
                    availability[2] = false;
                    availability[1] = false;
                }
                else if(currentHour>8 || currentHour==8){
                    availability[1] = false;
                }
            }
            else if(serviceType == 'Pool Cleaner' && currentDate==Schedule){
                if(currentHour>14 || currentHour==14){
                    availability[3] = false;
                    availability[2] = false;
                    availability[1] = false;
                }
                else if(currentHour>11 || currentHour==11){
                    availability[2] = false;
                    availability[1] = false;
                }
                else if(currentHour>8 || currentHour==8){
                    availability[1] = false;
                }
            }
            else if(serviceType == 'Car Cleaner' && currentDate==Schedule){
                if(currentHour>15 || currentHour==15){
                    availability[5] = false;
                    availability[4] = false;
                    availability[3] = false;
                    availability[2] = false;
                    availability[1] = false;
                }
                else if(currentHour>13 || currentHour==13){
                    availability[4] = false;
                    availability[3] = false;
                    availability[2] = false;
                    availability[1] = false;
                }
                else if(currentHour>11 || currentHour==11){
                    availability[3] = false;
                    availability[2] = false;
                    availability[1] = false;
                }
                else if(currentHour>9 || currentHour==9){
                    availability[2] = false;
                    availability[1] = false;
                }
                else if(currentHour>7 || currentHour==7){
                    availability[1] = false;
                }
            }
            else if(serviceType == 'Gardener' && currentDate==Schedule){
                if(currentHour>12 || currentHour==12){
                    availability[2] = false;
                    availability[1] = false;
                }
                else if(currentHour>7 || currentHour==7){
                    availability[1] = false;
                }
            }
            else if(currentDate==Schedule){
                if(currentHour>12 || currentHour==12){
                    availability[2] = false;
                    availability[1] = false;
                }
                else if(currentHour>7 || currentHour==7){
                    availability[1] = false;
                }   
            }

            setAvailability(availability);
        }
    },[Schedule, bookings, currentHour])

    useEffect(()=>{
        if(Schedule && schedules && userData ){
            const filteredSchedules = schedules.filter((schedule)=>(schedule.schedule.date==Schedule && schedule.servicerId==userData._id )) //get all schedules on the selected calendar date
            let resting = [false,false,false,false,false,false]; //There can be at most 5 slots, but slots start at index 1  (for car cleaning)

            //Loop through each booking on that selected day
            filteredSchedules.forEach((schedule, index)=>{
                resting[schedule.schedule.slot] = true;
            });
        
            setResting(resting);
        }
    },[Schedule, schedules, userData])
        
    useEffect(()=>{
        const loop = setInterval(()=>{
            setHour(new Date().getHours());
        }, 1000);

        return () => clearInterval(loop);
    },[])


    const output = (
        <div id='schedule-page'>
                <div className='schedule-body'>
                    <p className='close-schedule' onClick={()=>setScheduleOpen(false)}>&#10006;</p>
                    <h2 className='schedule-title'>Schedule</h2>
                    <div className='main-disclaimer'><h3>Set your schedule</h3></div>
                    <div className='schedule-calender'>
                        <div className='Month-selector'>
                            <p>{monthsArray[Month]}{' '}{Year}</p>
                            <img className='month-above' src='/Images/up.png' onClick={MonthUp}></img>
                            <img className='month-below' src='/Images/down.png' onClick={MonthDown}></img>
                        </div>
                        <div className='Day-selector'>
                            {calendarDates.length==42 &&
                                (<><div className='grid'><p>Su</p><p>Mo</p><p>Tu</p><p>We</p><p>Th</p><p>Fr</p><p>Sa</p></div>
                                <div className='grid'><p onClick={()=>ScheduleDate(0)} style={{fontWeight: dayFontWeight[0], fontSize: dayFont[0], color: dayColor[0]}}>{format(calendarDates[0], 'd')}</p> <p onClick={()=>ScheduleDate(1)} style={{fontWeight: dayFontWeight[1], fontSize: dayFont[1], color: dayColor[1]}}>{format(calendarDates[1], 'd')}</p> <p onClick={()=>ScheduleDate(2)} style={{fontWeight: dayFontWeight[2], fontSize: dayFont[2], color: dayColor[2]}}>{format(calendarDates[2], 'd')}</p> <p onClick={()=>ScheduleDate(3)} style={{fontWeight: dayFontWeight[3], fontSize: dayFont[3], color: dayColor[3]}}>{format(calendarDates[3], 'd')}</p> <p onClick={()=>ScheduleDate(4)} style={{fontWeight: dayFontWeight[4], fontSize: dayFont[4], color: dayColor[4]}}>{format(calendarDates[4], 'd')}</p> <p onClick={()=>ScheduleDate(5)} style={{fontWeight: dayFontWeight[5], fontSize: dayFont[5], color: dayColor[5]}}>{format(calendarDates[5], 'd')}</p> <p onClick={()=>ScheduleDate(6)} style={{fontWeight: dayFontWeight[6], fontSize: dayFont[6], color: dayColor[6]}}>{format(calendarDates[6], 'd')}</p></div>
                                <div className='grid'><p onClick={()=>ScheduleDate(7)} style={{fontWeight: dayFontWeight[7], fontSize: dayFont[7], color: dayColor[7]}}>{format(calendarDates[7], 'd')}</p> <p onClick={()=>ScheduleDate(8)} style={{fontWeight: dayFontWeight[8], fontSize: dayFont[8], color: dayColor[8]}}>{format(calendarDates[8], 'd')}</p> <p onClick={()=>ScheduleDate(9)} style={{fontWeight: dayFontWeight[9], fontSize: dayFont[9], color: dayColor[9]}}>{format(calendarDates[9], 'd')}</p> <p onClick={()=>ScheduleDate(10)} style={{fontWeight: dayFontWeight[10], fontSize: dayFont[10], color: dayColor[10]}}>{format(calendarDates[10], 'd')}</p> <p onClick={()=>ScheduleDate(11)} style={{fontWeight: dayFontWeight[11], fontSize: dayFont[11], color: dayColor[11]}}>{format(calendarDates[11], 'd')}</p> <p onClick={()=>ScheduleDate(12)} style={{fontWeight: dayFontWeight[12], fontSize: dayFont[12], color: dayColor[12]}}>{format(calendarDates[12], 'd')}</p> <p onClick={()=>ScheduleDate(13)} style={{fontWeight: dayFontWeight[13], fontSize: dayFont[13], color: dayColor[13]}}>{format(calendarDates[13], 'd')}</p> </div>
                                <div className='grid'><p onClick={()=>ScheduleDate(14)} style={{fontWeight: dayFontWeight[14], fontSize: dayFont[14], color: dayColor[14]}}>{format(calendarDates[14], 'd')}</p> <p onClick={()=>ScheduleDate(15)} style={{fontWeight: dayFontWeight[15], fontSize: dayFont[15], color: dayColor[15]}}>{format(calendarDates[15], 'd')}</p> <p onClick={()=>ScheduleDate(16)} style={{fontWeight: dayFontWeight[16], fontSize: dayFont[16], color: dayColor[16]}}>{format(calendarDates[16], 'd')}</p> <p onClick={()=>ScheduleDate(17)} style={{fontWeight: dayFontWeight[17], fontSize: dayFont[17], color: dayColor[17]}}>{format(calendarDates[17], 'd')}</p> <p onClick={()=>ScheduleDate(18)} style={{fontWeight: dayFontWeight[18], fontSize: dayFont[18], color: dayColor[18]}}>{format(calendarDates[18], 'd')}</p> <p onClick={()=>ScheduleDate(19)} style={{fontWeight: dayFontWeight[19], fontSize: dayFont[19], color: dayColor[19]}}>{format(calendarDates[19], 'd')}</p> <p onClick={()=>ScheduleDate(20)} style={{fontWeight: dayFontWeight[20], fontSize: dayFont[20], color: dayColor[20]}}>{format(calendarDates[20], 'd')}</p></div>
                                <div className='grid'><p onClick={()=>ScheduleDate(21)} style={{fontWeight: dayFontWeight[21], fontSize: dayFont[21], color: dayColor[21]}}>{format(calendarDates[21], 'd')}</p> <p onClick={()=>ScheduleDate(22)} style={{fontWeight: dayFontWeight[22], fontSize: dayFont[22], color: dayColor[22]}}>{format(calendarDates[22], 'd')}</p> <p onClick={()=>ScheduleDate(23)} style={{fontWeight: dayFontWeight[23], fontSize: dayFont[23], color: dayColor[23]}}>{format(calendarDates[23], 'd')}</p> <p onClick={()=>ScheduleDate(24)} style={{fontWeight: dayFontWeight[24], fontSize: dayFont[24], color: dayColor[24]}}>{format(calendarDates[24], 'd')}</p> <p onClick={()=>ScheduleDate(25)} style={{fontWeight: dayFontWeight[25], fontSize: dayFont[25], color: dayColor[25]}}>{format(calendarDates[25], 'd')}</p> <p onClick={()=>ScheduleDate(26)} style={{fontWeight: dayFontWeight[26], fontSize: dayFont[26], color: dayColor[26]}}>{format(calendarDates[26], 'd')}</p> <p onClick={()=>ScheduleDate(27)} style={{fontWeight: dayFontWeight[27], fontSize: dayFont[27], color: dayColor[27]}}>{format(calendarDates[27], 'd')}</p></div>
                                <div className='grid'><p onClick={()=>ScheduleDate(28)} style={{fontWeight: dayFontWeight[28], fontSize: dayFont[28], color: dayColor[28]}}>{format(calendarDates[28], 'd')}</p> <p onClick={()=>ScheduleDate(29)} style={{fontWeight: dayFontWeight[29], fontSize: dayFont[29], color: dayColor[29]}}>{format(calendarDates[29], 'd')}</p> <p onClick={()=>ScheduleDate(30)} style={{fontWeight: dayFontWeight[30], fontSize: dayFont[30], color: dayColor[30]}}>{format(calendarDates[30], 'd')}</p> <p onClick={()=>ScheduleDate(31)} style={{fontWeight: dayFontWeight[31], fontSize: dayFont[31], color: dayColor[31]}}>{format(calendarDates[31], 'd')}</p> <p onClick={()=>ScheduleDate(32)} style={{fontWeight: dayFontWeight[32], fontSize: dayFont[32], color: dayColor[32]}}>{format(calendarDates[32], 'd')}</p> <p onClick={()=>ScheduleDate(33)} style={{fontWeight: dayFontWeight[33], fontSize: dayFont[33], color: dayColor[33]}}>{format(calendarDates[33], 'd')}</p> <p onClick={()=>ScheduleDate(34)} style={{fontWeight: dayFontWeight[34], fontSize: dayFont[34], color: dayColor[34]}}>{format(calendarDates[34], 'd')}</p></div>
                                <div className='grid'><p onClick={()=>ScheduleDate(35)} style={{fontWeight: dayFontWeight[35], fontSize: dayFont[35], color: dayColor[35]}}>{format(calendarDates[35], 'd')}</p> <p onClick={()=>ScheduleDate(36)} style={{fontWeight: dayFontWeight[36], fontSize: dayFont[36], color: dayColor[36]}}>{format(calendarDates[36], 'd')}</p> <p onClick={()=>ScheduleDate(37)} style={{fontWeight: dayFontWeight[37], fontSize: dayFont[37], color: dayColor[37]}}>{format(calendarDates[37], 'd')}</p> <p onClick={()=>ScheduleDate(38)} style={{fontWeight: dayFontWeight[38], fontSize: dayFont[38], color: dayColor[38]}}>{format(calendarDates[38], 'd')}</p> <p onClick={()=>ScheduleDate(39)} style={{fontWeight: dayFontWeight[39], fontSize: dayFont[39], color: dayColor[39]}}>{format(calendarDates[39], 'd')}</p> <p onClick={()=>ScheduleDate(40)} style={{fontWeight: dayFontWeight[40], fontSize: dayFont[40], color: dayColor[40]}}>{format(calendarDates[40], 'd')}</p> <p onClick={()=>ScheduleDate(41)} style={{fontWeight: dayFontWeight[41], fontSize: dayFont[41], color: dayColor[41]}}>{format(calendarDates[41], 'd')}</p></div>
                            </>)}
                            {calendarDates.length==35 &&
                                (<><div className='grid'><p>Su</p><p>Mo</p><p>Tu</p><p>We</p><p>Th</p><p>Fr</p><p>Sa</p></div>
                                <div className='grid'><p onClick={()=>ScheduleDate(0)} style={{fontWeight: dayFontWeight[0], fontSize: dayFont[0], color: dayColor[0]}}>{format(calendarDates[0], 'd')}</p> <p onClick={()=>ScheduleDate(1)} style={{fontWeight: dayFontWeight[1], fontSize: dayFont[1], color: dayColor[1]}}>{format(calendarDates[1], 'd')}</p> <p onClick={()=>ScheduleDate(2)} style={{fontWeight: dayFontWeight[2], fontSize: dayFont[2], color: dayColor[2]}}>{format(calendarDates[2], 'd')}</p> <p onClick={()=>ScheduleDate(3)} style={{fontWeight: dayFontWeight[3], fontSize: dayFont[3], color: dayColor[3]}}>{format(calendarDates[3], 'd')}</p> <p onClick={()=>ScheduleDate(4)} style={{fontWeight: dayFontWeight[4], fontSize: dayFont[4], color: dayColor[4]}}>{format(calendarDates[4], 'd')}</p> <p onClick={()=>ScheduleDate(5)} style={{fontWeight: dayFontWeight[5], fontSize: dayFont[5], color: dayColor[5]}}>{format(calendarDates[5], 'd')}</p> <p onClick={()=>ScheduleDate(6)} style={{fontWeight: dayFontWeight[6], fontSize: dayFont[6], color: dayColor[6]}}>{format(calendarDates[6], 'd')}</p></div>
                                <div className='grid'><p onClick={()=>ScheduleDate(7)} style={{fontWeight: dayFontWeight[7], fontSize: dayFont[7], color: dayColor[7]}}>{format(calendarDates[7], 'd')}</p> <p onClick={()=>ScheduleDate(8)} style={{fontWeight: dayFontWeight[8], fontSize: dayFont[8], color: dayColor[8]}}>{format(calendarDates[8], 'd')}</p> <p onClick={()=>ScheduleDate(9)} style={{fontWeight: dayFontWeight[9], fontSize: dayFont[9], color: dayColor[9]}}>{format(calendarDates[9], 'd')}</p> <p onClick={()=>ScheduleDate(10)} style={{fontWeight: dayFontWeight[10], fontSize: dayFont[10], color: dayColor[10]}}>{format(calendarDates[10], 'd')}</p> <p onClick={()=>ScheduleDate(11)} style={{fontWeight: dayFontWeight[11], fontSize: dayFont[11], color: dayColor[11]}}>{format(calendarDates[11], 'd')}</p> <p onClick={()=>ScheduleDate(12)} style={{fontWeight: dayFontWeight[12], fontSize: dayFont[12], color: dayColor[12]}}>{format(calendarDates[12], 'd')}</p> <p onClick={()=>ScheduleDate(13)} style={{fontWeight: dayFontWeight[13], fontSize: dayFont[13], color: dayColor[13]}}>{format(calendarDates[13], 'd')}</p> </div>
                                <div className='grid'><p onClick={()=>ScheduleDate(14)} style={{fontWeight: dayFontWeight[14], fontSize: dayFont[14], color: dayColor[14]}}>{format(calendarDates[14], 'd')}</p> <p onClick={()=>ScheduleDate(15)} style={{fontWeight: dayFontWeight[15], fontSize: dayFont[15], color: dayColor[15]}}>{format(calendarDates[15], 'd')}</p> <p onClick={()=>ScheduleDate(16)} style={{fontWeight: dayFontWeight[16], fontSize: dayFont[16], color: dayColor[16]}}>{format(calendarDates[16], 'd')}</p> <p onClick={()=>ScheduleDate(17)} style={{fontWeight: dayFontWeight[17], fontSize: dayFont[17], color: dayColor[17]}}>{format(calendarDates[17], 'd')}</p> <p onClick={()=>ScheduleDate(18)} style={{fontWeight: dayFontWeight[18], fontSize: dayFont[18], color: dayColor[18]}}>{format(calendarDates[18], 'd')}</p> <p onClick={()=>ScheduleDate(19)} style={{fontWeight: dayFontWeight[19], fontSize: dayFont[19], color: dayColor[19]}}>{format(calendarDates[19], 'd')}</p> <p onClick={()=>ScheduleDate(20)} style={{fontWeight: dayFontWeight[20], fontSize: dayFont[20], color: dayColor[20]}}>{format(calendarDates[20], 'd')}</p></div>
                                <div className='grid'><p onClick={()=>ScheduleDate(21)} style={{fontWeight: dayFontWeight[21], fontSize: dayFont[21], color: dayColor[21]}}>{format(calendarDates[21], 'd')}</p> <p onClick={()=>ScheduleDate(22)} style={{fontWeight: dayFontWeight[22], fontSize: dayFont[22], color: dayColor[22]}}>{format(calendarDates[22], 'd')}</p> <p onClick={()=>ScheduleDate(23)} style={{fontWeight: dayFontWeight[23], fontSize: dayFont[23], color: dayColor[23]}}>{format(calendarDates[23], 'd')}</p> <p onClick={()=>ScheduleDate(24)} style={{fontWeight: dayFontWeight[24], fontSize: dayFont[24], color: dayColor[24]}}>{format(calendarDates[24], 'd')}</p> <p onClick={()=>ScheduleDate(25)} style={{fontWeight: dayFontWeight[25], fontSize: dayFont[25], color: dayColor[25]}}>{format(calendarDates[25], 'd')}</p> <p onClick={()=>ScheduleDate(26)} style={{fontWeight: dayFontWeight[26], fontSize: dayFont[26], color: dayColor[26]}}>{format(calendarDates[26], 'd')}</p> <p onClick={()=>ScheduleDate(27)} style={{fontWeight: dayFontWeight[27], fontSize: dayFont[27], color: dayColor[27]}}>{format(calendarDates[27], 'd')}</p></div>
                                <div className='grid'><p onClick={()=>ScheduleDate(28)} style={{fontWeight: dayFontWeight[28], fontSize: dayFont[28], color: dayColor[28]}}>{format(calendarDates[28], 'd')}</p> <p onClick={()=>ScheduleDate(29)} style={{fontWeight: dayFontWeight[29], fontSize: dayFont[29], color: dayColor[29]}}>{format(calendarDates[29], 'd')}</p> <p onClick={()=>ScheduleDate(30)} style={{fontWeight: dayFontWeight[30], fontSize: dayFont[30], color: dayColor[30]}}>{format(calendarDates[30], 'd')}</p> <p onClick={()=>ScheduleDate(31)} style={{fontWeight: dayFontWeight[31], fontSize: dayFont[31], color: dayColor[31]}}>{format(calendarDates[31], 'd')}</p> <p onClick={()=>ScheduleDate(32)} style={{fontWeight: dayFontWeight[32], fontSize: dayFont[32], color: dayColor[32]}}>{format(calendarDates[32], 'd')}</p> <p onClick={()=>ScheduleDate(33)} style={{fontWeight: dayFontWeight[33], fontSize: dayFont[33], color: dayColor[33]}}>{format(calendarDates[33], 'd')}</p> <p onClick={()=>ScheduleDate(34)} style={{fontWeight: dayFontWeight[34], fontSize: dayFont[34], color: dayColor[34]}}>{format(calendarDates[34], 'd')}</p></div>
                            </>)}
                        </div>
                        {serviceType != 'Car Washer' && serviceType != 'House Cleaner' && serviceType != 'Pool Cleaner' && serviceType != 'Gardener' && (
                            <div className='slots-block unfixed-slots'>
                                <div className='slot' onClick={()=>(Availability[1]==false)?NullFunc:(Resting[1]==false)?ScheduleSlot(1):UnScheduleSlot(1)} style={{opacity:(Slot==1 || Availability[1]==false || Resting[1]==true)?0.5:1}}>
                                    <p className='slot-name'>Slot 1</p>
                                    <p className='slot-duration'>7:00-11:00</p>
                                    {Availability[1]==true && Resting[1]==false && (
                                        <p className='available-tag'>available</p>
                                    )}
                                    {Availability[1]==false && (
                                        <p className='unavailable-tag'>unavailable</p>
                                    )}
                                    {Resting[1]==true && (
                                        <p className='resting-tag'>Resting</p>
                                    )}
                                </div>
                                <div className='slot' onClick={()=>(Availability[2]==false)?NullFunc:(Resting[2]==false)?ScheduleSlot(2):UnScheduleSlot(2)} style={{opacity:(Slot==2 || Availability[2]==false || Resting[2]==true)?0.5:1}}>
                                    <p className='slot-name'>Slot 2</p>
                                    <p className='slot-duration'>12:00-16:00</p>
                                        {Availability[2]==true && Resting[2]==false && (
                                            <p className='available-tag'>available</p>
                                        )}
                                        {Availability[2]==false && (
                                            <p className='unavailable-tag'>unavailable</p>
                                        )}
                                        {Resting[2]==true && (
                                            <p className='resting-tag'>Resting</p>
                                        )}
                                    <div className='slot-filter'></div>
                                </div>
                            </div>
                        )}
                        {serviceType == 'House Cleaner' && (
                            <div className='slots-block house-slots'>
                                    <div className='slot' onClick={()=>(Availability[1]==false)?NullFunc:(Resting[1]==false)?ScheduleSlot(1):UnScheduleSlot(1)} style={{opacity:(Slot==1 || Availability[1]==false || Resting[1]==true)?0.5:1}}>
                                        <p className='slot-name'>Slot 1</p>
                                        <p className='slot-duration'>8:00-12:00</p>
                                        {Availability[1]==true && Resting[1]==false && (
                                            <p className='available-tag'>available</p>
                                        )}
                                        {Availability[1]==false && (
                                            <p className='unavailable-tag'>unavailable</p>
                                        )}
                                        {Resting[1]==true && (
                                            <p className='resting-tag'>Resting</p>
                                        )}
                                        <div className='slot-filter'></div>
                                    </div>
                                    <div className='slot' onClick={()=>(Availability[2]==false)?NullFunc:(Resting[2]==false)?ScheduleSlot(2):UnScheduleSlot(2)} style={{opacity:(Slot==2 || Availability[2]==false || Resting[2]==true)?0.5:1}}>
                                        <p className='slot-name'>Slot 2</p>
                                        <p className='slot-duration'>13:00-17:00</p>
                                        {Availability[2]==true && Resting[2]==false && (
                                            <p className='available-tag'>available</p>
                                        )}
                                        {Availability[2]==false && (
                                            <p className='unavailable-tag'>unavailable</p>
                                        )}
                                        {Resting[2]==true && (
                                            <p className='resting-tag'>Resting</p>
                                        )}
                                        <div className='slot-filter'></div>
                                    </div>
                            </div>
                        )}
                        {serviceType == 'Pool Cleaner' && (
                            <div className='slots-block pool-slots'>
                                    <div className='slot' onClick={()=>(Availability[1]==false)?NullFunc:(Resting[1]==false)?ScheduleSlot(1):UnScheduleSlot(1)} style={{opacity:(Slot==1 || Availability[1]==false || Resting[1]==true)?0.5:1}}>
                                        <p className='slot-name'>Slot 1</p>
                                        <p className='slot-duration'>8:00-10:00</p>
                                        {Availability[1]==true && Resting[1]==false && (
                                            <p className='available-tag'>available</p>
                                        )}
                                        {Availability[1]==false && (
                                            <p className='unavailable-tag'>unavailable</p>
                                        )}
                                        {Resting[1]==true && (
                                            <p className='resting-tag'>Resting</p>
                                        )}
                                        <div className='slot-filter'></div>
                                    </div>
                                    <div className='slot' onClick={()=>(Availability[2]==false)?NullFunc:(Resting[2]==false)?ScheduleSlot(2):UnScheduleSlot(2)} style={{opacity:(Slot==2 || Availability[2]==false || Resting[2]==true)?0.5:1}}>
                                        <p className='slot-name'>Slot 2</p>
                                        <p className='slot-duration'>11:00-13:00</p>
                                        {Availability[2]==true && Resting[2]==false && (
                                            <p className='available-tag'>available</p>
                                        )}
                                        {Availability[2]==false && (
                                            <p className='unavailable-tag'>unavailable</p>
                                        )}
                                        {Resting[2]==true && (
                                            <p className='resting-tag'>Resting</p>
                                        )}
                                        <div className='slot-filter'></div>
                                    </div>
                                    <div className='slot' onClick={()=>(Availability[3]==false)?NullFunc:(Resting[3]==false)?ScheduleSlot(3):UnScheduleSlot(3)} style={{opacity:(Slot==3 || Availability[3]==false || Resting[3]==true)?0.5:1}}>
                                        <p className='slot-name'>Slot 3</p>
                                        <p className='slot-duration'>14:00-16:00</p>
                                        {Availability[3]==true && Resting[3]==false && (
                                            <p className='available-tag'>available</p>
                                        )}
                                        {Availability[3]==false && (
                                            <p className='unavailable-tag'>unavailable</p>
                                        )}
                                        {Resting[3]==true && (
                                            <p className='resting-tag'>Resting</p>
                                        )}
                                        <div className='slot-filter'></div>
                                    </div>
                            </div>
                        )}
                        {serviceType == 'Gardener' && (
                            <div className='slots-block garden-slots'>
                                    <div className='slot' onClick={()=>(Availability[1]==false)?NullFunc:(Resting[1]==false)?ScheduleSlot(1):UnScheduleSlot(1)} style={{opacity:(Slot==1 || Availability[1]==false || Resting[1]==true)?0.5:1}}>
                                        <p className='slot-name'>Slot 1</p>
                                        <p className='slot-duration'>7:00-11:00</p>
                                        {Availability[1]==true && Resting[1]==false && (
                                            <p className='available-tag'>available</p>
                                        )}
                                        {Availability[1]==false && (
                                            <p className='unavailable-tag'>unavailable</p>
                                        )}
                                        {Resting[1]==true && (
                                            <p className='resting-tag'>Resting</p>
                                        )}
                                        <div className='slot-filter'></div>
                                    </div>
                                    <div className='slot' onClick={()=>(Availability[2]==false)?NullFunc:(Resting[2]==false)?ScheduleSlot(2):UnScheduleSlot(2)} style={{opacity:(Slot==2 || Availability[2]==false || Resting[2]==true)?0.5:1}}>
                                        <p className='slot-name'>Slot 2</p>
                                        <p className='slot-duration'>12:00-16:00</p>
                                        {Availability[2]==true && Resting[2]==false && (
                                            <p className='available-tag'>available</p>
                                        )}
                                        {Availability[2]==false && (
                                            <p className='unavailable-tag'>unavailable</p>
                                        )}
                                        {Resting[2]==true && (
                                            <p className='resting-tag'>Resting</p>
                                        )}
                                        <div className='slot-filter'></div>
                                    </div>
                            </div>
                        )}
                        {serviceType == 'Car Washer' && (
                            <div className='slots-block car-slots'>
                                    <div className='slot' onClick={()=>(Availability[1]==false)?NullFunc:(Resting[1]==false)?ScheduleSlot(1):UnScheduleSlot(1)} style={{opacity:(Slot==1 || Availability[1]==false || Resting[1]==true)?0.5:1}}>
                                        <p className='slot-name'>Slot 1</p>
                                        <p className='slot-duration'>7:00-8:00</p>
                                        {Availability[1]==true && Resting[1]==false && (
                                            <p className='available-tag'>available</p>
                                        )}
                                        {Availability[1]==false && (
                                            <p className='unavailable-tag'>unavailable</p>
                                        )}
                                        {Resting[1]==true && (
                                            <p className='resting-tag'>Resting</p>
                                        )}
                                    </div>
                                    <div className='slot' onClick={()=>(Availability[2]==false)?NullFunc:(Resting[2]==false)?ScheduleSlot(2):UnScheduleSlot(2)} style={{opacity:(Slot==2 || Availability[2]==false || Resting[2]==true)?0.5:1}}>
                                        <p className='slot-name'>Slot 2</p>
                                        <p className='slot-duration'>9:00-10:00</p>
                                        {Availability[2]==true && Resting[2]==false && (
                                            <p className='available-tag'>available</p>
                                        )}
                                        {Availability[2]==false && (
                                            <p className='unavailable-tag'>unavailable</p>
                                        )}
                                        {Resting[2]==true && (
                                            <p className='resting-tag'>Resting</p>
                                        )}
                                        <div className='slot-filter'></div>
                                    </div>
                                    <div className='slot' onClick={()=>(Availability[3]==false)?NullFunc:(Resting[3]==false)?ScheduleSlot(3):UnScheduleSlot(3)} style={{opacity:(Slot==3 ||  Availability[3]==false || Resting[3]==true)?0.5:1}}>
                                        <p className='slot-name'>Slot 3</p>
                                        <p className='slot-duration'>11:00-12:00</p>
                                        {Availability[3]==true && Resting[3]==false && (
                                            <p className='available-tag'>available</p>
                                        )}
                                        {Availability[3]==false && (
                                            <p className='unavailable-tag'>unavailable</p>
                                        )}
                                        {Resting[3]==true && (
                                            <p className='resting-tag'>Resting</p>
                                        )}
                                        <div className='slot-filter'></div>
                                    </div>
                                    <div className='slot' onClick={()=>(Availability[4]==false)?NullFunc:(Resting[4]==false)?ScheduleSlot(4):UnScheduleSlot(4)} style={{opacity:(Slot==4 || Availability[4]==false || Resting[4]==true)?0.5:1}}>
                                        <p className='slot-name'>Slot 4</p>
                                        <p className='slot-duration'>13:00-14:00</p>
                                        {Availability[4]==true && Resting[4]==false && (
                                            <p className='available-tag'>available</p>
                                        )}
                                        {Availability[4]==false && (
                                            <p className='unavailable-tag'>unavailable</p>
                                        )}
                                        {Resting[4]==true && (
                                            <p className='resting-tag'>Resting</p>
                                        )}
                                        <div className='slot-filter'></div>
                                    </div>
                                    <div className='slot' onClick={()=>(Availability[5]==false)?NullFunc:(Resting[5]==false)?ScheduleSlot(5):UnScheduleSlot(5)} style={{opacity:(Slot==5 || Availability[5]==false || Resting[5]==true)?0.5:1}}>
                                        <p className='slot-name'>Slot 5</p>
                                        <p className='slot-duration'>15:00-16:00</p>
                                        {Availability[5]==true && Resting[5]==false && (
                                            <p className='available-tag'>available</p>
                                        )}
                                        {Availability[5]==false && (
                                            <p className='unavailable-tag'>unavailable</p>
                                        )}
                                        {Resting[5]==true && (
                                            <p className='resting-tag'>Resting</p>
                                        )}
                                        <div className='slot-filter'></div>
                                    </div>
                            </div>
                        )}
                    </div>
                    <div className='submit-block'>
                    </div>
                </div> 
        </div>
        );

    return output;
}