import '../Style/AddVideo.css';
import { useState, useEffect, useContext } from 'react';
import { UserDataContext } from "../Data/Session";
import { createVideo } from '../Actions/Video';

export default function AddVideo({setAddVideo, servicer, accountType}){
    const {userData} = useContext(UserDataContext); 
    const [videoPost, setVidPost] = useState({description:'', vidFile:null});

    const handleChange2 = (e)=>{
        const {value, name} = e.target;
        if(name=='description'){
            setVidPost({...videoPost,[name]:value});
        }
        else{
            setVidPost({...videoPost,[name]:e.target.files[0]});
        }
    }

    const handleSubmit2 = (e)=>{
        e.preventDefault();
        createVideo({...videoPost, createdDate:new Date().getTime(),userId:(accountType=='user')?userData._id:servicer._id});
        setVidPost({description:'', vidFile:null});
        setAddVideo(0);
    }

    const output = (<div id='AddVideo'>
                        <div className="adContent">
                            <p className='close-tab' onClick={()=>setAddVideo(false)}>&#10006;</p>
                            <h1>Post A Video Of Your Work</h1>
                            <form className="form" onSubmit={handleSubmit2}>
                                <p>Video:</p>
                                <input name='vidFile' type='file' onChange={handleChange2} value={(videoPost.vidFile)?videoPost.vidFile.filename:''}></input>
                                <p>Description:</p>
                                <textarea maxlength="200" name="description" onChange={handleChange2} value={videoPost.description}></textarea>
                                <button type='submit'>Post</button>
                            </form>
                        </div>
                    </div>);

    return output;
}