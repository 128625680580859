import '../Style/PrivacyPolicy.css'
import { useNavigate } from 'react-router-dom';


export default function PrivacyPolicy(){

    const navigate = useNavigate();

    const output = (
        <div id="PrivacyPolicy">
            <div className="logo-block">
                <img src="/Images/logo3.png"></img>
            </div>
            <button className='backButton' onClick={()=>navigate(-1)}>&#8592; Back</button>
            <div className='document'>
                <h2>{'Privacy Policy (PP)'}</h2>
                <div className='policy-section'>
                    <p className='policy-title'>1. Introduction</p>
                    <p className='policy-text'>ScheduleEase respects your privacy and is committed to protecting your personal information in compliance with the <span className="bold">Protection of Personal Information Act, 2013 (Act No. 4 of 2013) (POPIA)</span>. This policy outlines how we collect, use, and protect your data.</p>
                </div>
                <div className='policy-section'>
                    <p className='policy-title'>2. Information We Collect</p>
                    <p className='policy-text'>We collect the following information:</p>
                    <ul>
                        <li><span className="bold">Users:</span> Name, surname, email, phone number, password, address, images, videos, and payment information.</li>
                        <li><span className="bold">Service Providers:</span> Additional information includes ID number, job description, service type, and job tally.</li>
                    </ul>
                </div>
                <div className='policy-section'>
                    <p className='policy-title'>3. How We Use Your Information</p>
                    <p className='policy-text'>We use your information to:</p>
                    <ul>
                        <li>Facilitate connections between users and service providers.</li>
                        <li>Process secure payments via Peach Payments.</li>
                        <li>Improve our platform and personalize user experiences.</li>
                        <li>Verify the identity and criminal record of service providers.</li>
                    </ul>
                </div>
                <div className='policy-section'>
                    <p className='policy-title'>4. Payment Information</p>
                    <ul>
                        <li>Card data is securely tokenized by Peach Payments, and we only store tokens, last 4 digits, card type, and expiry dates.</li>
                        <li>Payment liability is shifted to card issuers via 3D Secure Authorization.</li>
                    </ul>
                </div>
                <div className='policy-section'>
                    <p className='policy-title'>5. Sharing Your Data</p>
                    <ul>
                        <li>We do not share user data with third parties, except for payment processing through Peach Payments.</li>
                        <li>Service Providers' job descriptions, service types, ratings, and location data may be displayed publicly on the platform to enable user engagement.</li>
                    </ul>
                </div>
                <div className='policy-section'>
                    <p className='policy-title'>6. User Rights</p>
                    <p className='policy-text'>In compliance with <span className="bold">POPIA</span>, you have the right to:</p>
                    <ul>
                        <li>Access, update, or delete your personal information.</li>
                        <li>Object to the processing of your data.</li>
                        <li>Request the restriction of your data processing.</li>
                    </ul>
                    <p className='policy-text'>To exercise your rights, contact us at <span className="bold">scheduleeaseee@gmail.com</span></p>
                </div>
                <div className='policy-section'>
                    <p className='policy-title'>7. Data Security</p>
                    <ul>
                        <li>We use SSL encryption to secure data transfers between your browser and our server.</li>
                        <li>Passwords and ID numbers are encrypted before being stored in our database.</li>
                        <li>Database access is restricted to our AWS backend server via IP whitelisting.</li>
                    </ul>
                </div>
                <div className='policy-section'>
                    <p className='policy-title'>8. Cookies and Tracking</p>
                    <p className='policy-text'>We use cookies to enhance user experiences. You can manage your cookie preferences via your browser settings.</p>
                </div>
                <div className='policy-section'>
                    <p className='policy-title'>9. Ratings and Reviews</p>
                    <ul>
                        <li>Rating data is displayed to maintain transparency and assist users in decision-making.</li>
                        <li>Visible data includes the name, surname, and profile picture of the rater.</li>
                    </ul>
                </div>
                <div className='policy-section'>
                    <p className='policy-title'>10. Liability and Third-Party Interactions</p>
                    <ul>
                        <li>ScheduleEase acts solely as a platform to connect users and service providers.</li>
                        <li>While we verify the identity and criminal record of service providers to ensure they are South African and free of criminal records, we are not liable for any harm, damages, or misconduct arising during interactions or transactions between users and service providers.</li>
                        <li>Users are encouraged to exercise discretion and communicate clearly with service providers.</li>
                    </ul>
                </div>
                <div className='policy-section'>
                    <p className='policy-title'>11. Trademarks and Intellectual Property</p>
                    <ul>
                        <li>All logos, trademarks, and branding related to <span className="bold">ScheduleEase</span> are the exclusive property of ScheduleEase.</li>
                        <li>Users may not use any of ScheduleEase's trademarks, logos, or branding without explicit written permission.</li>
                    </ul>
                </div>
                <div className='policy-section'>
                    <p className='policy-title'>12. Retention Policy</p>
                    <p className='policy-text'>We retain user data as long as necessary to fulfill the purpose for which it was collected. Users may request deletion of their data at any time.</p>
                </div>
                <div className='policy-section'>
                    <p className='policy-title'>13. Compliance</p>
                    <p className='policy-text bold'>We operate in compliance with the following laws and standards:</p>
                    <ul className='numerical-points'>
                        <li><span className="bold">Protection of Personal Information Act, 2013 (Act No. 4 of 2013):</span> This Act regulates how personal information is collected, stored, and processed, ensuring data privacy and security.</li>
                        <li><span className="bold">Consumer Protection Act, 2008 (Act No. 68 of 2008):</span> This Act ensures fair practices for consumers, promoting transparency and accountability in the marketplace.</li>
                        <li><span className="bold">Payment Card Industry Data Security Standard (PCI DSS):</span> These internationally recognized standards secure cardholder data during payment processing and are updated periodically to address emerging threats.</li>
                    </ul>
                </div>
                <div className='policy-section'>
                    <p className='policy-title'>14. Updates to this Policy</p>
                    <p className='policy-text'>We may update this Privacy Policy from time to time. Users will be notified of significant changes via email or platform notifications.</p>
                </div>
                <div className='policy-section'>
                    <p className='policy-title'>15. Contact Us</p>
                    <p className='policy-text'>For questions or concerns about this Privacy Policy, contact us at <span className="bold">scheduleeaseee@gmail.com</span></p>
                </div>
                
            </div>

        </div>
    );

    return output;
}