import "../Style/ForgotPassword.css";
import { useState, useEffect, useRef } from "react";
import { signIn } from "../Actions/User";
import { Link } from "react-router-dom";
import { UserDataContext } from "../Data/Session";
import { useContext } from "react";
import { forgotPassword } from "../Actions/User";

function SignIn(){
    const [isFocused, setFocused] = useState(false);
    const [email, setEmail] = useState(null)
    const [display, setDisplay] = useState('none');
    const {sessionId} = useContext(UserDataContext);
    const [buttonText, setText] = useState('Send');
    const [Message, setMessage] = useState(false);

    function handleBlur(){
        setFocused(false);
    }
    function handleFocus(){
        setFocused(true);
    }

    const handleSubmit = async(e)=>{
        e.preventDefault();
        const response = await forgotPassword({email:email, sessionId:sessionId});
        setText('Resend');
        // console.log("Response: ",response.noEmail)
        if(response && response.noEmail!=null && response.noEmail == true){
            setDisplay('block');
        }
        else if(response && response.noEmail!=null && response.noEmail == false){
            setMessage(true);
        }
    }

    function handleChange(e){
        const {value} = e.target;
        setEmail(value)
        setDisplay('none');
    }

    const output = (<>
                        <div id="forgotPasswordPage">
                            {Message==true && (
                                <div className='fp-popup'>
                                    <p className='popup-closer' onClick={()=>setMessage(false)}>&#10006;</p>
                                    <p className='popup-message'>Password reset link sent successfully! Check your email and click the link.</p>
                                </div>
                            )}

                            <div className="logo-block">
                                <img src="/Images/logo3.png"></img>
                            </div>
                            <div className="Underliner">
                                <div className="Line1"></div>
                                <div className="Line2"></div>
                            </div>
                            <form onSubmit={handleSubmit}>
                                <h2>Enter your email to recieve the password reset link.</h2>
                                <div className='email'>
                                    <label htmlFor="emailInput" className={`emailPlaceholder ${isFocused || email ? 'active':''}`}>
                                        Email
                                    </label>
                                    <input id='emailInput' type="text" name="email" onFocus={handleFocus} onBlur={handleBlur} onChange={handleChange}></input>
                                    <p className="required" style={{display:display}}>Email not found</p>
                                </div>
                                <div className="send">
                                    <button type='submit'>
                                        {buttonText}
                                    </button> 
                                </div> 
                                <div className="link">
                                    <div>
                                        <p>Don't have an account?</p>
                                        <Link to="/signUp1">Sign Up</Link>  
                                    </div>
                                </div>
                            </form>  
                        </div>
        
                    </>);

    return output;
}

export default SignIn; 