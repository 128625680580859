import '../Style/Rater.css';
import { useState } from 'react';
import { useContext } from 'react';
import { UserDataContext } from '../Data/Session';
import { createRating } from '../Actions/Rating';

export default function Rater({setRater, servicerId, bookingId}){
    const { userData } = useContext(UserDataContext);
    const [star1, setStar1] = useState(false);
    const [star2, setStar2] = useState(false);
    const [star3, setStar3] = useState(false);
    const [star4, setStar4] = useState(false);
    const [star5, setStar5] = useState(false);
    const [data, setData] = useState({
                                        rating: 0,
                                        comment: ''
                                    });

    function SubmitRating(){
        const ratingData = {
                        rating: data.rating,
                        comment: data.comment,
                        createdDate: new Date().getTime(),
                        raterId: userData._id,
                        servicerId: servicerId,
                        bookingId: bookingId
                     };

        createRating(ratingData);
        setRater(false);
    }

    const output = (<div id='Rater'>
                        <div className='rater-block'>
                            <h3>Rating</h3>
                            <div className='list'>
                                <img src={`/Images/${(data.rating>0)?'star-yellow.png':'star.png'}`} onClick={()=>setData({...data,rating:(data.rating==1)?0:1})}></img>
                                <img src={`/Images/${(data.rating>1)?'star-yellow.png':'star.png'}`} onClick={()=>setData({...data,rating:(data.rating==2)?0:2})}></img>
                                <img src={`/Images/${(data.rating>2)?'star-yellow.png':'star.png'}`} onClick={()=>setData({...data,rating:(data.rating==3)?0:3})}></img>
                                <img src={`/Images/${(data.rating>3)?'star-yellow.png':'star.png'}`} onClick={()=>setData({...data,rating:(data.rating==4)?0:4})}></img>
                                <img src={`/Images/${(data.rating>4)?'star-yellow.png':'star.png'}`} onClick={()=>setData({...data,rating:(data.rating==5)?0:5})}></img>
                            </div>
                            <p>Comment:</p>
                            <textarea onChange={(e)=>setData({...data, comment:e.target.value})}></textarea>
                            <button className='Delete' onClick={SubmitRating}>Submit</button>
                            <button className='Cancel' onClick={()=>setRater(false)}>Cancel</button>
                        </div>
                    </div>)

    return output;

}