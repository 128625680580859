import axios from 'axios';

const url = 'https://scheduleease.co.za/api/schedule/';

///////////////////////////// GET REQUEST /////////////////////////////////////

export const getSchedules = async() => {
    try {
        const {data} = await axios.get(url);
        return data;
    } catch (error) {
        console.log(error)
    }
}

////////////////////////////// POST REQUEST ///////////////////////////////////

export const createSchedule = (newData) => { 
    
    try {  
        axios.post(url,newData);
        
    } catch (error) {
       console.log(error);
    }
}

///////////////////////////////// UPDATE REQUEST /////////////////////////////////////////

export const updateSchedule = (id, updatedData) =>{
    try { 
        axios.patch(`${url}${id}`,updatedData);
  
    } catch (error) {
      console.log(error); 
    }
}

/////////////////////////////// DELETE REQUEST /////////////////////////////////////////
export const deleteSchedule = (id)=> {
    try {
      axios.delete(`${url}${id}`);
    } catch (error) {
        console.log(error);
    }
}