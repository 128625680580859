/*
 * © 2023-2024 Nqobani Vuka Dlamini
 * All rights reserved.
 */
import "../Style/SignUp3.css";
import { useState,useRef } from "react";
import { Link} from "react-router-dom";
import { UserDataContext } from "../Data/Session";
import { useContext } from "react";
import { updateServicer } from "../Actions/Servicer"; 
import { useNavigate } from "react-router-dom";

function SignUp3(){
    const {userData, sessionId, servicers} = useContext(UserDataContext);
    const [display2,setD2] = useState("none");
    const [display3,setD3] = useState("none");
    const [display4,setD4] = useState("none");
    const [display5,setD5] = useState("none");
    const [Message, setMessage] = useState(false);

    const [selected, setSelected] = useState(0);
    const [servicerData, setData] = useState({description:'', imgFile:null, idNumber:null,service:'House Cleaner'});
    const [image, setImage] = useState(null)

    const navigate = useNavigate();

    function IDChecker(id){
        if(id.length!=13){
            return false;
        }
        else{
            const birthDate = id.slice(0,2); 
            const birthMonth = id.slice(2,4); 
            const citizenship = id.slice(10,11);
            const aNumber = id.slice(11,12);
            
            if(parseInt(birthDate)>31 || parseInt(birthMonth)>12 || [0,1].includes(parseInt(citizenship))==false || [8,9].includes(parseInt(aNumber))==false){
                return false;
            }
            else{
                //Luhn algorthm check
                const n1 = parseInt(id.slice(0,1));
                const n2 = parseInt(((id.slice(1,2)*2) > 9)?id.slice(1,2)*2:id.slice(1,2)*2-9);
                const n3 = parseInt(id.slice(2,3));
                const n4 = parseInt(((id.slice(3,4)*2) > 9)?id.slice(3,4)*2:id.slice(3,4)*2-9);
                const n5 = parseInt(id.slice(4,5));
                const n6 = parseInt(((id.slice(5,6)*2) > 9)?id.slice(5,6)*2:id.slice(5,6)*2-9);
                const n7 = parseInt(id.slice(6,7));
                const n8 = parseInt(((id.slice(7,8)*2) > 9)?id.slice(7,8)*2:id.slice(7,8)*2-9);
                const n9 = parseInt(id.slice(8,9));
                const n10 = parseInt(((id.slice(9,10)*2) > 9)?id.slice(9,10)*2:id.slice(9,10)*2-9);
                const n11 = parseInt(id.slice(10,11));
                const n12 = parseInt(((id.slice(11,12)*2) > 9)?id.slice(11,12)*2:id.slice(11,12)*2-9);
                const n13 = parseInt(id.slice(12));

                const sum = n1+n2+n3+n4+n5+n6+n7+n8+n9+n10+n11+n12+n13;
        
                return (sum%10==0)?true:false
            }
        }
    }

    const handleChange1 = (e)=>{
        const {name,value} = e.target;
        
        setData({
            ...servicerData,
            [name]:value
        });
        
        if(name=='idNumber'){
            setD3('none');
            setD4('none');
            setD5('none');
        }

    }

    const handleChange2 = (selection, index)=>{
        setData({...servicerData,service:selection});
        setSelected(index);
    }
    
    const handleImage = (e) =>{
        const file = e.target.files[0];
        const reader = new FileReader();

        setD2('none');
        setData({...servicerData,imgFile:file});
        reader.onloadend = () => {
            setImage(reader.result);
        };
        reader.readAsDataURL(file);
    }

    const handleSubmit = async(e)=>{
        e.preventDefault();
        
        if(servicers && userData && userData.type && userData.type=='Servicer' ){
            let allGood = true;
            
            if(servicerData.imgFile==null){
                allGood = false;
                setD2('block');
            }
            if(servicerData.idNumber==null){
                allGood = false;
                setD3('block');
            }
            else if(IDChecker(servicerData.idNumber)==false){
                allGood = false;
                setD4('block');
            }
            if(allGood==true){
                const response = await updateServicer(userData._id,{...servicerData, sessionId:sessionId});
                // console.log('Response: ',response)
                if(response.updated && response.updated==true){
                    navigate('/Account/user');
                }
                else if(response.updated!=null && response.updated==false && response.idUnavailable==true){
                    setD5('block');
                }
                else{
                    setMessage(true);
                }
            }
        }
    }

    const output = (<>
                        <div id="newSellerPage2">
                            {Message==true && (
                                <div className='s3-popup'>
                                    <p className='popup-closer' onClick={()=>setMessage(false)}>&#10006;</p>
                                    <p className='popup-message'>An Error Occurred, Please Retry</p>
                                </div>
                            )}
                            <div className="logo-block">
                                <img src="/Images/logo3.png"></img>
                            </div>
                            <div className="emailUnderliner">
                                <div className="euLine1"></div>
                                <div className="euLine2"></div>
                            </div>
                            <div className="inputSection1">
                                <form onSubmit={handleSubmit}  enctype="multipart/form-data">
                                    <h2>Complete your account creation</h2>
                                    <div className="s3Underliner">
                                        <div className="s3UL1"></div>
                                        <div className="s3UL2"></div>
                                    </div>
                                    
                                    <div className='profileInput'><img src={(image)?image:"/Images/user.png"}></img></div>
                                    <div className='label-holder'>
                                        <p className="s3TXT">Enter a self portrait:</p>
                                        <p className="reqTag" style={{display:display2}}>required</p>
                                    </div>
                                    <input type="file" name='imgFile' className="s3Input2 fileInput" onChange={handleImage}></input>
                                    
                                    <div className='label-holder'>
                                        <p className="s3TXT">Enter your id number:</p>
                                        <p className="reqTag" style={{display:display3}}>required</p>
                                        <p className="reqTag" style={{display:display4}}>invalid id</p>
                                        <p className="reqTag" style={{display:display5}}>already in use</p>
                                    </div>
                                    <input type='number' name='idNumber' className="idInput" onChange={handleChange1}></input>
                                    
                                    <div className='label-holder'>
                                        <p className="s3TXT">Enter a description of your services:</p>
                                    </div>
                                    <textarea maxlength="220" name='description' className="s3Input4" onChange={handleChange1}></textarea>
                                    
                                    <div className='label-holder'>
                                        <p className="s3TXT">Select your title:</p>
                                    </div>
                                    <div className="s3Stack s3s1">
                                        <div className="isInput" onClick={()=>handleChange2('House Cleaner',0)} style={{backgroundColor: (selected==0)?'lightblue':'white'}}>
                                            <p style={{color: (selected==0)?'white':'black'}}>House Cleaner</p>
                                        </div>
                                        <div className="isInput"  onClick={()=>handleChange2('Painter',1)} style={{backgroundColor: (selected==1)?'lightblue':'white'}}>
                                            <p style={{color: (selected==1)?'white':'black'}}>Painter</p>
                                        </div>
                                        <div className="isInput"  onClick={()=>handleChange2('Pool Cleaner',2)} style={{backgroundColor: (selected==2)?'lightblue':'white'}}>
                                            <p style={{color: (selected==2)?'white':'black'}}>Pool Cleaner</p>
                                        </div>
                                        <div className="isInput"  onClick={()=>handleChange2('Gardener',3)} style={{backgroundColor: (selected==3)?'lightblue':'white'}}>
                                            <p style={{color: (selected==3)?'white':'black'}}>Gardener</p>
                                        </div>
                                        <div className="isInput"  onClick={()=>handleChange2('Electrician',4)} style={{backgroundColor: (selected==4)?'lightblue':'white'}}>
                                            <p style={{color: (selected==4)?'white':'black'}}>Electrician</p>
                                        </div>
                                        <div className="isInput"  onClick={()=>handleChange2('Plumber',5)} style={{backgroundColor: (selected==5)?'lightblue':'white'}}>
                                            <p style={{color: (selected==5)?'white':'black'}}>Plumber</p>
                                        </div>
                                        <div className="isInput"  onClick={()=>handleChange2('Car Cleaner',6)} style={{backgroundColor: (selected==6)?'lightblue':'white'}}>
                                            <p style={{color: (selected==6)?'white':'black'}}>Car Cleaner</p>
                                        </div>
                                        <div className="isInput"  onClick={()=>handleChange2('Pet Caretaker',7)} style={{backgroundColor: (selected==7)?'lightblue':'white'}}>
                                            <p style={{color: (selected==7)?'white':'black'}}>Pet Caretaker</p>
                                        </div>
                                        <div className="isInput"  onClick={()=>handleChange2('Waste Remover',8)} style={{backgroundColor: (selected==8)?'lightblue':'white'}}>
                                            <p style={{color: (selected==8)?'white':'black'}}>Waste Remover</p>
                                        </div>
                                        <div className="isInput"  onClick={()=>handleChange2('Pest Controller',9)} style={{backgroundColor: (selected==9)?'lightblue':'white'}}>
                                            <p style={{color: (selected==9)?'white':'black'}}>Pest Controller</p>
                                        </div>
                                        <div className="isInput"  onClick={()=>handleChange2('Guuter Cleaner',10)} style={{backgroundColor: (selected==10)?'lightblue':'white'}}>
                                            <p style={{color: (selected==10)?'white':'black'}}>Gutter Cleaner</p>
                                        </div>
                                    </div>
                                        
                                    <button className='s3Button' type="Submit">Submit</button>

                                </form>
                                
                            </div>         
                        </div>
                    </>
    );

    return output;
}

export default SignUp3;