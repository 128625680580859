import '../Style/Thumbnail.css';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { UserDataContext } from '../Data/Session';
import { useContext } from 'react';

export default function Thumbnail(props){
    const navigate = useNavigate();
    const [left,setLeft] = useState(45+200*props.pos-200);
    const [pos, setPos] = useState(props.pos);
    const [total, setTot] = useState(props.total);
    const [transition, setTrans] = useState(1.5);
    const [data, setData] = useState(null);
    const {servicers} = useContext(UserDataContext);

    function Prev(){
        if(pos == 0){
            setLeft(left+(total-1)*200);
            setPos(pos+(total-1));
            setTrans(0);
        }
        else{
            setLeft(left-200);
            setPos(pos-1);
            setTrans(1.5);
        }
    }

    function Next(){
        if(pos == (total-1)){
            setLeft(left-(total-1)*200);
            setPos(pos-(total-1));
            setTrans(0);
        }
        else{
            setLeft(left+200);
            setPos(pos+1);
            setTrans(1.5);
        }
    }

    useEffect(()=>{
        if(servicers && props.data){
            setData( servicers.find((servicer)=>(servicer._id==props.data)) );
        }
    },[props.data, servicers])

    const output = (<>
                    <div id='Thumbnail' style={{left: left,transition: `${transition}s`}} onClick={()=>navigate(`/account/servicer/${props.data}`)}>
                        <div className='ImgHolder'>
                            <img src={(data && data.profileLink && data.profileLink!='')?data.profileLink:'/Images/user.png'}></img>
                        </div>
                        <p className='name'>{(data)?data.name:''}</p>
                        <p className='surname'>{(data)?data.surname:''}</p>
                        <p className='jobTitle'><span>. </span>{(data)?data.service:''}<span> .</span></p>
                    </div>
                    <div ref={props.linkP} onClick={Prev} style={{display:'none'}}></div>
                    <div ref={props.linkN} onClick={Next} style={{display:'none'}}></div>
                    </>);

    return output;
}