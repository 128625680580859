import '../Style/CancelBlock.css';
import { deleteBooking } from '../Actions/Booking';
import { useNavigate } from 'react-router-dom';

export default function CancelBlock({bookingId, setCancelOpen}){

    const navigate = useNavigate();

    const output = (<div id='CancelBlock'>
                        <div>
                            <h1>Are you sure you want to cancel the booking?</h1>
                            <button className='Delete' onClick={()=>{deleteBooking(bookingId); navigate('/services');}}>Yes</button>
                            <button className='Cancel' onClick={()=>setCancelOpen(false)}>No</button>
                        </div>
                    </div>)

    return output;

}