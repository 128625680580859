import { useParams } from 'react-router-dom';
import '../Style/Booking.css';
import { useState, useEffect, useRef} from 'react';
import { Link } from 'react-router-dom';
import CarCleaning from '../Booking Cards/CarCleaning';
import HouseCleaning from '../Booking Cards/HouseCleaning';
import { startOfMonth, endOfMonth, startOfWeek, endOfWeek, eachDayOfInterval, format } from 'date-fns';
import { createBooking } from '../Actions/Booking';
import { useContext } from 'react';
import { UserDataContext } from '../Data/Session';

export default function Booking({serviceType, setBooking, servicer}){

const [currentHour, setHour] = useState(new Date().getHours())
const [starting, setStarting] = useState(true)
const [Year, setYear] = useState(new Date().getFullYear());    
const [Month, setMonth] = useState(new Date().getMonth());
const [Day, setDay] = useState(new Date().getDate());
const [TimeRange, setTimeRange] = useState(0);
const [calendarDates, setCalendarDates] = useState([]);
const [Appointment, setAppointment] = useState(null);
const [DateTime, setDateTime] = useState(null);
const [Slot1, setSlot1] = useState(null);
const [Slot2, setSlot2] = useState(null);
const [Message0, setMessage0] = useState(true);
const [Message1, setMessage1] = useState(false);
const [Message2, setMessage2] = useState(false);
const [Message3, setMessage3] = useState(false);
const [Message4, setMessage4] = useState(false);
const [dayColor,setDayColor] = useState(['black','black','black','black','black','black','black','black','black','black','black','black','black','black','black','black','black','black','black','black','black','black','black','black','black','black','black','black','black','black','black','black','black','black','black','black','black','black','black','black','black','black']);
const [dayFont,setDayFont] = useState(['15px','15px','15px','15px','15px','15px','15px','15px','15px','15px','15px','15px','15px','15px','15px','15px','15px','15px','15px','15px','15px','15px','15px','15px','15px','15px','15px','15px','15px','15px','15px','15px','15px','15px','15px','15px','15px','15px','15px','15px','15px','15px']);
const [dayFontWeight, setDayFontWeight] = useState(['normal','normal','normal','normal','normal','normal','normal','normal','normal','normal','normal','normal','normal','normal','normal','normal','normal','normal','normal','normal','normal','normal','normal','normal','normal','normal','normal','normal','normal','normal','normal','normal','normal','normal','normal','normal','normal','normal','normal','normal','normal','normal']);
const [Availability, setAvailability] = useState([true,true,true,true,true,true]);
const [Resting, setResting] = useState([false,false,false,false,false,false]);
const [nbOn1, setNO1] = useState(false);
const [nbOn2, setNO2] = useState(false);
const [totalTime, setTT] = useState(0);
const [carTime, setCT] = useState(0);
const [price, setPrice] = useState(0);
const [carCount, setCC] = useState(1);
const [roomCount, setRC] = useState(1);
const [bathroomCount, setBC] = useState(1);
const [extraTime, setET] = useState(0);
const [forms, setForms] = useState([{
                            carType: 'Light Vehicle',
                            cleanInterior: false,
                            polishTyres: false,
                            subTotal: 80,
                            subTime:0.5
                          }]);
const [commonform, setCForm] = useState({
                            laundry: false,
                            fridge: false,
                            cabinets: false,
                            oven: false,
                            windows: false,
                            extraTime: 0,
                            subtotalTime: 1.5,
                            subTotal: 150
                          });
const [bathroomForm, setBForm] = useState([{type:'Bathroom',windows: false,time: 0.5, subTotal: 35}]);
const [roomForm, setRForm] = useState([{type:'Room',windows: false,time: 0.5, subTotal: 15}]);
const [poolForm, setPForm] = useState({Inspect:false, Tile: false, Deck: false, Total:250});
const [gardenForm, setGForm] = useState({Inspect:false, Fertilize: false, Pest: false, Total:280});
const [toggle, setToggle] = useState(true);        
const [cartFull, setCartFull] = useState(false);
const {userData, sessionId, bookings, schedules} = useContext(UserDataContext);        

    //Laundry
    const [border1, setBorder1] = useState('2px solid black');
    const [border2, setBorder2] = useState('2px solid white');

    //Fridge
    const [border3, setBorder3] = useState('2px solid black');
    const [border4, setBorder4] = useState('2px solid white');

    //Cabinets
    const [border5, setBorder5] = useState('2px solid black');
    const [border6, setBorder6] = useState('2px solid white');

    //Oven
    const [border7, setBorder7] = useState('2px solid black');
    const [border8, setBorder8] = useState('2px solid white');

    //Windows
    const [border9, setBorder9] = useState('2px solid black');
    const [border10, setBorder10] = useState('2px solid white');

    //Pool
    const [border11, setBorder11] = useState('2px solid black');
    const [border12, setBorder12] = useState('2px solid white');
    const [border13, setBorder13] = useState('2px solid black');
    const [border14, setBorder14] = useState('2px solid white');
    const [border15, setBorder15] = useState('2px solid black');
    const [border16, setBorder16] = useState('2px solid white');

    //Garden
    const [border17, setBorder17] = useState('2px solid black');
    const [border18, setBorder18] = useState('2px solid white');
    const [border19, setBorder19] = useState('2px solid black');
    const [border20, setBorder20] = useState('2px solid white');
    const [border21, setBorder21] = useState('2px solid black');
    const [border22, setBorder22] = useState('2px solid white');

    //Laundry
    const [filler1, setFiller1] = useState('white');
    const [filler2, setFiller2] = useState('black');

    //Fridge
    const [filler3, setFiller3] = useState('white');
    const [filler4, setFiller4] = useState('black');

    //Cabinets
    const [filler5, setFiller5] = useState('white');
    const [filler6, setFiller6] = useState('black');

    //Oven
    const [filler7, setFiller7] = useState('white');
    const [filler8, setFiller8] = useState('black');

    //Windows
    const [filler9, setFiller9] = useState('white');
    const [filler10, setFiller10] = useState('black');

    //Pool
    const [filler11, setFiller11] = useState('white');
    const [filler12, setFiller12] = useState('black');
    const [filler13, setFiller13] = useState('white');
    const [filler14, setFiller14] = useState('black');
    const [filler15, setFiller15] = useState('white');
    const [filler16, setFiller16] = useState('black');

    //Garden
    const [filler17, setFiller17] = useState('white');
    const [filler18, setFiller18] = useState('black');
    const [filler19, setFiller19] = useState('white');
    const [filler20, setFiller20] = useState('black');
    const [filler21, setFiller21] = useState('white');
    const [filler22, setFiller22] = useState('black');

    const monthsArray = ['January','February','March','April','May','June','July','August','September','October','November','December'];
    const houseSlots = ['','8:00-12:00','13:00-17:00'];
    const carSlots = ['','7:00-8:00','9:00-10:00','11:00-12:00','13:00-14:00','15:00-16:00'];
    const gardenSlots = ['','7:00-11:00','12:00-16:00'];
    const poolSlots = ['','8:00-10:00','11:00-13:00','14:00-16:00'];
    const negotiableSlots = ['','7:00-11:00','12:00-16:00']

function NullFunc(){
}

function Add(){
    const newForms = [...forms,{carType: 'Light Vehicle',cleanInterior: false,polishTyres: false,subTotal: 80, subTime:0.5}];
    setForms(newForms);
    setCC(carCount+1);
}

function AddRoom(){
    const newForms = [...roomForm,{type:'Room',windows: false,time: 0.5, subTotal: 15}];
    setRForm(newForms);
    setRC(roomCount+1);
}

function AddBathroom(){
    const newForms = [...bathroomForm,{type:'Bathroom',windows: false,time: 0.5, subTotal: 35}];
    setBForm(newForms);
    setBC(bathroomCount+1);
}

function Minus(){
    if(forms.length > 1){
        const newForms = [...forms];
        newForms.splice(-1,1);
        setForms(newForms);
        setCC(carCount-1);
    }    
}

function MinusRoom(){
    if(roomForm.length > 1){
        const newForms = [...roomForm];
        newForms.splice(-1,1);
        setRForm(newForms);
        setRC(roomCount-1);
    }    
}

function MinusBathroom(){
    if(bathroomForm.length > 1){
        const newForms = [...bathroomForm];
        newForms.splice(-1,1);
        setBForm(newForms);
        setBC(bathroomCount-1);
    }    
}

function Laundry(input){
    const newForms = commonform;
    const laundry = newForms.laundry;
    let newSubtotal = newForms.subTotal;
    let time = newForms.subtotalTime;

    if(input && !laundry){
        newSubtotal += 80;
        time += 2;
        newForms.laundry = true;
        newForms.subTotal = newSubtotal;
        newForms.subtotalTime = time;
        setBorder1('2px solid white');
        setBorder2('2px solid black');
        setFiller1('black');
        setFiller2('white');
        setCForm(newForms);
    }
    else if(!input && laundry){
        newSubtotal -= 80;
        time -= 2;
        newForms.laundry = false;
        newForms.subTotal = newSubtotal;
        newForms.subtotalTime = time;
        setBorder2('2px solid white');
        setBorder1('2px solid black');
        setFiller2('black');
        setFiller1('white');
        setCForm(newForms);

    }
}

function Fridge(input){
    let newForms = commonform;
    const fridge = newForms.fridge;
    let newSubtotal = newForms.subTotal;
    let time = newForms.subtotalTime;

    if(input && !fridge){
        newSubtotal += 20;
        newForms.fridge = true;
        time += 0.5;
        newForms.subTotal = newSubtotal;
        newForms.subtotalTime = time;
        setBorder3('2px solid white');
        setBorder4('2px solid black');
        setFiller3('black');
        setFiller4('white');
        setCForm(newForms);
    }
    else if(!input && fridge){
        newSubtotal -= 20;
        newForms.fridge = false;
        time -=0.5;
        newForms.subTotal = newSubtotal;
        newForms.subtotalTime = time;
        setBorder4('2px solid white');
        setBorder3('2px solid black');
        setFiller4('black');
        setFiller3('white');
        setCForm(newForms);
    }
}

function Cabinets(input){
    let newForms = commonform;
    const cabinets = newForms.cabinets;
    let newSubtotal = newForms.subTotal;
    let time = newForms.subtotalTime;

    if(input && !cabinets){
        newSubtotal += 40;
        time += 0.5;
        newForms.cabinets = true;
        newForms.subTotal = newSubtotal;
        newForms.subtotalTime = time;
        setBorder5('2px solid white');
        setBorder6('2px solid black');
        setFiller5('black');
        setFiller6('white');
        setCForm(newForms);
    }
    else if(!input && cabinets){
        newSubtotal -= 40;
        time -= 0.5;
        newForms.cabinets = false;
        newForms.subTotal = newSubtotal;
        newForms.subtotalTime = time;
        setBorder6('2px solid white');
        setBorder5('2px solid black');
        setFiller6('black');
        setFiller5('white');
        setCForm(newForms);
    }
}

function Oven(input){
    let newForms = commonform;
    const oven = newForms.oven;
    let newSubtotal = newForms.subTotal;
    let time = newForms.subtotalTime;

    if(input && !oven){
        newSubtotal += 20;
        time += 0.5;
        newForms.oven = true;
        newForms.subTotal = newSubtotal;
        newForms.subtotalTime = time;
        setBorder7('2px solid white');
        setBorder8('2px solid black');
        setFiller7('black');
        setFiller8('white');
        setCForm(newForms);
    }
    else if(!input && oven){
        newSubtotal -= 20;
        time -= 0.5;
        newForms.oven = false;
        newForms.subTotal = newSubtotal;
        newForms.subtotalTime = time;
        setBorder8('2px solid white');
        setBorder7('2px solid black');
        setFiller8('black');
        setFiller7('white');
        setCForm(newForms);
    }
}

function Windows(input){
    let newForms = commonform;
    const windows = newForms.windows;
    let newSubtotal = newForms.subTotal;
    let time = newForms.subtotalTime;

    if(input && !windows){
        newSubtotal += 30;
        time += 1;
        newForms.windows = true;
        newForms.subTotal = newSubtotal;
        newForms.subtotalTime = time;
        setBorder9('2px solid white');
        setBorder10('2px solid black');
        setFiller9('black');
        setFiller10('white');
        setCForm(newForms);
    }
    else if(!input && windows){
        newSubtotal -= 30;
        time -= 1;
        newForms.windows = false;
        newForms.subTotal = newSubtotal;
        newForms.subtotalTime = time;
        setBorder10('2px solid white');
        setBorder9('2px solid black');
        setFiller10('black');
        setFiller9('white');
        setCForm(newForms);
    }
}

function Inspection(input){
    let newForms = poolForm;
    const inspect = newForms.Inspect;
    let newTotal = newForms.Total;

    if(input && !inspect){
        newTotal += 20;
        newForms.Inspect = true;
        newForms.Total = newTotal;
        setBorder11('2px solid white');
        setBorder12('2px solid black');
        setFiller11('black');
        setFiller12('white');
        setPForm(newForms);
    }
    else if(!input && inspect){
        newTotal -= 20;
        newForms.Inspect = false;
        newForms.Total = newTotal;
        setBorder12('2px solid white');
        setBorder11('2px solid black');
        setFiller12('black');
        setFiller11('white');
        setPForm(newForms);
    }
}
function Tile(input){
    let newForms = poolForm;
    const tile = newForms.Tile;
    let newTotal = newForms.Total;

    if(input && !tile){
        newTotal += 30;
        newForms.Tile = true;
        newForms.Total = newTotal;
        setBorder13('2px solid white');
        setBorder14('2px solid black');
        setFiller13('black');
        setFiller14('white');
        setPForm(newForms);
    }
    else if(!input && tile){
        newTotal -= 30;
        newForms.Tile = false;
        newForms.Total = newTotal;
        setBorder14('2px solid white');
        setBorder13('2px solid black');
        setFiller14('black');
        setFiller13('white');
        setPForm(newForms);
    }
}
function Deck(input){
    let newForms = poolForm;
    const deck = newForms.Deck;
    let newTotal = newForms.Total;

    if(input && !deck){
        newTotal += 20;
        newForms.Deck = true;
        newForms.Total = newTotal;
        setBorder15('2px solid white');
        setBorder16('2px solid black');
        setFiller15('black');
        setFiller16('white');
        setPForm(newForms);
    }
    else if(!input && deck){
        newTotal -= 20;
        newForms.Deck = false;
        newForms.Total = newTotal;
        setBorder16('2px solid white');
        setBorder15('2px solid black');
        setFiller16('black');
        setFiller15('white');
        setPForm(newForms);
    }
}

function Irrigation(input){
    let newForms = gardenForm;
    const inspect = newForms.Inspect;
    let newTotal = newForms.Total;

    if(input && !inspect){
        newTotal += 20;
        newForms.Inspect = true;
        newForms.Total = newTotal;
        setBorder17('2px solid white');
        setBorder18('2px solid black');
        setFiller17('black');
        setFiller18('white');
        setGForm(newForms);
    }
    else if(!input && inspect){
        newTotal -= 20;
        newForms.Inspect = false;
        newForms.Total = newTotal;
        setBorder18('2px solid white');
        setBorder17('2px solid black');
        setFiller18('black');
        setFiller17('white');
        setGForm(newForms);
    }
}
function Fertilize(input){
    let newForms = gardenForm;
    const fertilize = newForms.Fertilize;
    let newTotal = newForms.Total;

    if(input && !fertilize){
        newTotal += 20;
        newForms.Fertilize = true;
        newForms.Total = newTotal;
        setBorder19('2px solid white');
        setBorder20('2px solid black');
        setFiller19('black');
        setFiller20('white');
        setGForm(newForms);
    }
    else if(!input && fertilize){
        newTotal -= 20;
        newForms.Fertilize = false;
        newForms.Total = newTotal;
        setBorder20('2px solid white');
        setBorder19('2px solid black');
        setFiller20('black');
        setFiller19('white');
        setGForm(newForms);
    }
}
function Pest(input){
    let newForms = gardenForm;
    const pest = newForms.Pest;
    let newTotal = newForms.Total;

    if(input && !pest){
        newTotal += 20;
        newForms.Pest = true;
        newForms.Total = newTotal;
        setBorder21('2px solid white');
        setBorder22('2px solid black');
        setFiller21('black');
        setFiller22('white');
        setGForm(newForms);
    }
    else if(!input && pest){
        newTotal -= 20;
        newForms.Pest = false;
        newForms.Total = newTotal;
        setBorder22('2px solid white');
        setBorder21('2px solid black');
        setFiller22('black');
        setFiller21('white');
        setGForm(newForms);
    }
}

function Changes(newForms){
    setForms(newForms);
}

function Changes2(newForms){
    setCForm(newForms);
}

function Changes3(newForms){
    setRForm(newForms);
}

function Changes4(newForms){
    setBForm(newForms);
}

function MonthUp(){
    if(Month==11){
        setMonth(0);
        setYear(Year+1);
    }
    else{
        setMonth(Month+1);
    }
}

function MonthDown(){
    if(Month==0){
        setMonth(11);
        setYear(Year-1);
    }
    else{
        setMonth(Month-1);
    }
}

function TimeUp(){
    if(TimeRange==21){
        setTimeRange(0);
    }
    else{
        setTimeRange(TimeRange+1);
    }
}

function TimeDown(){
    if(TimeRange==0){
        setTimeRange(21);
    }
    else{
        setTimeRange(TimeRange-1);
    }
}

function AppointmentDate(dateNo){ 
    if((format(calendarDates[dateNo], 'dd/MM/yyyy') != Appointment && ( calendarDates[dateNo]-(new Date()))>0 || format(calendarDates[dateNo], 'dd/MM/yyyy')==format(new Date(), 'dd/MM/yyyy'))){
        setAppointment(format(calendarDates[dateNo], 'dd/MM/yyyy')); 
        setDateTime(calendarDates[dateNo].getTime());
        let colors = ['black','black','black','black','black','black','black','black','black','black','black','black','black','black','black','black','black','black','black','black','black','black','black','black','black','black','black','black','black','black','black','black','black','black','black','black','black','black','black','black','black','black'];
        let fonts = ['15px','15px','15px','15px','15px','15px','15px','15px','15px','15px','15px','15px','15px','15px','15px','15px','15px','15px','15px','15px','15px','15px','15px','15px','15px','15px','15px','15px','15px','15px','15px','15px','15px','15px','15px','15px','15px','15px','15px','15px','15px','15px'];
        let weights = ['normal','normal','normal','normal','normal','normal','normal','normal','normal','normal','normal','normal','normal','normal','normal','normal','normal','normal','normal','normal','normal','normal','normal','normal','normal','normal','normal','normal','normal','normal','normal','normal','normal','normal','normal','normal','normal','normal','normal','normal','normal','normal']
        colors[dateNo] = 'darkblue';
        fonts[dateNo] = '20px';
        weights[dateNo] = 'bold';
        setDayColor(colors);
        setDayFont(fonts);
        setDayFontWeight(weights);
        setSlot1(null);
        setSlot2(null);
    }
}

function AppointmentSlot(slotNo){
    if(slotNo == Slot1){
        setSlot1(null);
        setSlot2(null);
    }
    else if(Slot1==null){
        setSlot1(slotNo); 
        setSlot2(null);
    }   
}

function SubmitHouse(){
    if(cartFull){
        setMessage0(false);
        setMessage1(false);
        setMessage2(false);
        setMessage3(false);
        setMessage4(true);
    }
    else{
        if(Message0==false && Message2==false && Message3==false){
            const data = {createdDate:new Date().getTime(), 
                        service: 'House Cleaning', 
                        bookerId: userData._id, 
                        servicerId: servicer._id, 
                        type: 'fixed', 
                        amount: price, 
                        appointment:{date: Appointment,
                                    dateTime: DateTime,
                                    slot1: Slot1,
                                    slot2: Slot2 
                                    },
                        house: {laundry:commonform.laundry, 
                                fridge: commonform.fridge, 
                                cabinets: commonform.cabinets, 
                                oven: commonform.oven, 
                                windows: commonform.windows, 
                                totalTime: totalTime, 
                                commonCost: commonform.subTotal, 
                                rooms:[...roomForm.map((room)=>{return room.windows})], 
                                bathrooms:[...bathroomForm.map((room)=>{return room.windows})]
                                }
                        }
            // console.log('Booking Data: ',data);
            createBooking({...data,sessionId:sessionId});
        }
    }
}

function SubmitPool(){
    if(cartFull){
        setMessage0(false);
        setMessage1(false);
        setMessage2(false);
        setMessage3(false);
        setMessage4(true);
    }
    else{
        if(Message0==false){
            const data = {createdDate:new Date().getTime(), 
                        service: 'Pool Cleaning', 
                        bookerId: userData._id, 
                        servicerId: servicer._id, 
                        type: 'fixed', 
                        amount: price, 
                        appointment:{date: Appointment,
                                    dateTime: DateTime,
                                    slot1: Slot1 
                                    },
                        pool: {inspection: poolForm.Inspect,
                            tile: poolForm.Tile,
                            deck: poolForm.Deck
                            }
            }
            // console.log('Booking Data: ',data);
            createBooking({...data,sessionId:sessionId});
        }
    }
}

function SubmitGarden(){
    if(cartFull){
        setMessage0(false);
        setMessage1(false);
        setMessage2(false);
        setMessage3(false);
        setMessage4(true);
    }
    else{
        if(Message0==false){
            const data = {createdDate:new Date().getTime(), 
                        service: 'Gardening', 
                        bookerId: userData._id, 
                        servicerId: servicer._id, 
                        type: 'fixed', 
                        amount: price, 
                        appointment:{date: Appointment,
                                    dateTime: DateTime,
                                    slot1: Slot1 
                                    },
                        garden: {inspection: gardenForm.Inspect,
                                fertilize: gardenForm.Fertilize,
                                control: gardenForm.Pest
                                }
            }
            // console.log('Booking Data: ',data);
            createBooking({...data,sessionId:sessionId});
        }
    }
}

function SubmitCar(){
    if(cartFull){
        setMessage0(false);
        setMessage1(false);
        setMessage2(false);
        setMessage3(false);
        setMessage4(true);
    }
    else{
        if(Message0==false && Message2==false && Message3==false){
            const data = {createdDate:new Date().getTime(), 
                        service: 'Car Cleaning', 
                        bookerId: userData._id, 
                        servicerId: servicer._id, 
                        type: 'fixed', 
                        amount: price, 
                        appointment:{date: Appointment,
                                    dateTime: DateTime,
                                    slot1: Slot1,
                                    slot2: Slot2 
                                    },
                        car: [...forms.map((form)=>{return {carType: form.carType,interior: form.cleanInterior,polish: form.polishTyres}})]
            }
            // console.log('Booking Data: ',data);
            createBooking({...data,sessionId:sessionId});
        }
    }
}

function SubmitAppointment(){
    if(cartFull){
        setMessage4(true);
    }
    else{
        if(Message0==false){
            const data = {createdDate:new Date().getTime(), 
                        service: serviceType, 
                        bookerId: userData._id, 
                        servicerId: servicer._id, 
                        type: 'negotiable', 
                        amount: price, 
                        appointment:{date: Appointment,
                                     dateTime: DateTime,
                                     slot1: Slot1 
                                    }
            }
            // console.log('Booking Data: ',data);
            createBooking({...data,sessionId:sessionId});
        }
    }
}

//Reset the total booking cost, with each change to any form
useEffect(()=>{
    if(serviceType=='Car Cleaner'){
        let total = 0;
        let time = 0;
        for(let i = 0; i < forms.length; i++){
            total += forms[i].subTotal;
            time += forms[i].subTime;
        }
        setPrice(total);
        setCT(time);
    }

},[forms, Slot1, Slot2]);

useEffect(()=>{
    if(serviceType=='House Cleaner'){
        let total = commonform.subTotal;
        let time = commonform.subtotalTime;
        for(let i = 0; i < roomForm.length; i++){
            total += roomForm[i].subTotal;
            time += roomForm[i].time;
        }
        for(let i = 0; i < bathroomForm.length; i++){
            total += bathroomForm[i].subTotal;
            time += bathroomForm[i].time;
        }
        setPrice(total);
        setTT(time);

    }
},[roomForm,bathroomForm,commonform.subTotal,commonform.subtotalTime, Slot1, Slot2]);

useEffect(()=>{
    if(serviceType=='Pool Cleaner'){
        setPrice(poolForm.Total);
    }
},[poolForm.Total])

useEffect(()=>{
    if(serviceType=='Gardener'){
        setPrice(gardenForm.Total);
    }
},[gardenForm.Total])

useEffect(()=>{
    if(serviceType=="Car Washer"){
        setPrice(80);
    }
    else if(serviceType=="House Cleaner"){
        setPrice(200);
    }
    else if(serviceType=="Pool Cleaner"){
        setPrice(250);
    }
    else if(serviceType=="Gardener"){
        setPrice(280);
    }
},[serviceType]);

//Setting up the calendar dates for the selected month
useEffect(()=>{
    const startDate = startOfWeek(startOfMonth(new Date(Year, Month)));
    const endDate = endOfWeek(endOfMonth(new Date(Year, Month)));

    const dates = eachDayOfInterval({ start: startDate, end: endDate });
    setCalendarDates(dates);
},[Year,Month]);

//Setting the current day as the initial appointment date selected 
useEffect(() => {
    if(calendarDates.length>0){
        // Initialize the colors array to all 'black'
        let colors = new Array(calendarDates.length).fill('black');
        let fonts = new Array(calendarDates.length).fill('15px');
        let weights = new Array(calendarDates.length).fill('normal');
        
        // Find the index of the current day in the dates array
        calendarDates.forEach((date, index) => {
            if(Appointment && Appointment == format(date, 'dd/MM/yyyy')){
                colors[index] = 'darkblue'; // Set selected day color to darkblue
                fonts[index] = '20px';
                weights[index] = 'bold';
                setAppointment(format(date, 'dd/MM/yyyy')); 
                setDateTime(date.getTime());
            }
            else if(starting==true && date.getDate() === Day && date.getMonth() === Month) {
                colors[index] = 'darkblue'; // Set current day color to darkblue
                fonts[index] = '20px';
                weights[index] = 'bold';
                setAppointment(format(date, 'dd/MM/yyyy')); 
                setDateTime(date.getTime());
            }
        });
    
        // Set the day color state
        setDayColor(colors);
        setDayFont(fonts);
        setDayFontWeight(weights);
        setStarting(false);
    }
}, [calendarDates]);

useEffect(()=>{
    if(Appointment && bookings && servicer && currentHour){
        const currentDate = format(new Date(), 'dd/MM/yyyy');
        const filteredBookings = bookings.filter((booking)=>(booking.appointment.date==Appointment && booking.servicerId==servicer._id)) //get all bookings on the selected calendar date
        let availability = [true,true,true,true,true,true]; //There can be at most 5 slots, but slots start at index 1  (for car cleaning)

        //Loop through each booking on that selected day
        filteredBookings.forEach((booking, index)=>{
            
            if(booking.appointment.slot2){
                availability[booking.appointment.slot1] = false;
                availability[booking.appointment.slot2] = false;
            }
            else{
                availability[booking.appointment.slot1] = false;
            }
        });
        
        if(serviceType == 'House Cleaner' && currentDate==Appointment){
            if(currentHour>13 || currentHour==13){
                availability[2] = false;
                availability[1] = false;
            }
            else if(currentHour>8 || currentHour==8){
                availability[1] = false;
            }
        }
        else if(serviceType == 'Pool Cleaner' && currentDate==Appointment){
            if(currentHour>14 || currentHour==14){
                availability[3] = false;
                availability[2] = false;
                availability[1] = false;
            }
            else if(currentHour>11 || currentHour==11){
                availability[2] = false;
                availability[1] = false;
            }
            else if(currentHour>8 || currentHour==8){
                availability[1] = false;
            }
        }
        else if(serviceType == 'Car Cleaner' && currentDate==Appointment){
            if(currentHour>15 || currentHour==15){
                availability[5] = false;
                availability[4] = false;
                availability[3] = false;
                availability[2] = false;
                availability[1] = false;
            }
            else if(currentHour>13 || currentHour==13){
                availability[4] = false;
                availability[3] = false;
                availability[2] = false;
                availability[1] = false;
            }
            else if(currentHour>11 || currentHour==11){
                availability[3] = false;
                availability[2] = false;
                availability[1] = false;
            }
            else if(currentHour>9 || currentHour==9){
                availability[2] = false;
                availability[1] = false;
            }
            else if(currentHour>7 || currentHour==7){
                availability[1] = false;
            }
        }
        else if(serviceType == 'Gardener' && currentDate==Appointment){
            if(currentHour>12 || currentHour==12){
                availability[2] = false;
                availability[1] = false;
            }
            else if(currentHour>7 || currentHour==7){
                availability[1] = false;
            }
        }
        else if(currentDate==Appointment){
            if(currentHour>12 || currentHour==12){
                availability[2] = false;
                availability[1] = false;
            }
            else if(currentHour>7 || currentHour==7){
                availability[1] = false;
            }   
        }
        
        setAvailability(availability);
    }
},[Appointment, bookings, servicer, currentHour])

useEffect(()=>{
    if(Appointment && schedules && servicer){
        const filteredSchedules = schedules.filter((schedule)=>(schedule.schedule.date==Appointment && schedule.servicerId==servicer._id)) //get all schedules on the selected calendar date
        let resting = [false,false,false,false,false,false]; //There can be at most 5 slots, but slots start at index 1  (for car cleaning)

        //Loop through each booking on that selected day
        filteredSchedules.forEach((schedule, index)=>{
            resting[schedule.schedule.slot] = true;
        });
        
        setResting(resting);
    }
},[Appointment, schedules, servicer])

useEffect(()=>{
    if(bookings && userData){
        const data = bookings.find((booking)=>booking.bookerId==userData._id && booking.paidDate==null);

        if(data){
            setCartFull(true);
        }
    }
},[bookings, userData])

useEffect(()=>{
    if(serviceType=='Car Cleaner'){
        //Slot hasn't been selected
        if(Slot1==null){
            setMessage0(true);
            setMessage1(false);
            setMessage2(false);
            setMessage3(false);
        }
        //Slot selected and within 1 slot time range
        else if(carTime==2 || carTime<2){
            setMessage0(false);
            setMessage1(false);
            setMessage2(false);
            setMessage3(false);
            setSlot2(null);
        }
        //Above 1 slot time range, slot 2 not picked, and proceeding slot available  
        else if(carTime>2 && carTime<4 && Slot1<5 && Availability[Slot1+1]==true && Resting[Slot1+1]==false){
            setMessage0(false);
            setMessage1(true);
            setMessage2(false);
            setMessage3(false);
            setSlot2(Slot1+1);
        }
        //Above 1 slot time range and slot 2 not picked, and proceeding slot unavailable
        else if(carTime>2 && carTime<4 && (Slot1>4 || (Slot1<5 && Availability[Slot1+1]==true && Resting[Slot1+1]==false)) ){
            setMessage0(false);
            setMessage1(false);
            setMessage2(false);
            setMessage3(true);
        }
        //Above 2 slot time range
        else if(carTime>4){
            setMessage0(false);
            setMessage1(false);
            setMessage2(true);
            setMessage3(false);
        }
    }
    else if(serviceType=='House Cleaner'){
        //Slot hasn't been selected
        if(Slot1==null){
            setMessage0(true);
            setMessage1(false);
            setMessage2(false);
            setMessage3(false);
        }
        //Slot selected and within 1 slot time range
        else if(totalTime==4 || totalTime<4){
            setMessage0(false);
            setMessage1(false);
            setMessage2(false);
            setMessage3(false);
            setSlot2(null);
        }
        //Above 1 slot time range, slot 2 not picked, and proceeding slot available  
        else if(totalTime>4 && totalTime<9 && Slot1==1 && Availability[2]==true && Resting[2]==false){
            setMessage0(false);
            setMessage1(true);
            setMessage2(false);
            setMessage3(false);
            setSlot2(2);
        }
        //Above 1 slot time range and slot 2 not picked, and proceeding slot unavailable
        else if(totalTime>4 && totalTime<9 && Slot1==2){
            setMessage0(false);
            setMessage1(false);
            setMessage2(false);
            setMessage3(true);
        }
        //Above 2 slot time range
        else if(totalTime>9){
            setMessage0(false);
            setMessage1(false);
            setMessage2(true);
            setMessage3(false);
        }
    }
    else{
        if(Slot1==null){
            setMessage0(true);
        }
        else{
            setMessage0(false);
        }
    }
},[Slot1, Slot2, carTime, totalTime, Appointment])

useEffect(()=>{
    const loop = setInterval(()=>{
        setHour(new Date().getHours());
    }, 1000);

    return () => clearInterval(loop);
},[])

const output = (<>
                <div id='booking-page'>
                    {Message0 && (
                        <div className='Message0'>
                            <p className='message0'>
                                Pick a slot. 
                            </p>
                        </div>
                    )}
                    {Message1 && (
                        <div className='Message1'>
                            <p className='close-message1' onClick={()=>{setMessage1(false)}}>&#10006;</p>
                            <p className='message1'>
                                A second slot has been selected to cover the overtime.
                            </p>
                        </div>
                    )}
                    {Message2 && (
                        <div className='Message2'>
                            <p className='message2'>
                                The time required exceeds the two slot limit. Decrease your booking size. 
                            </p>
                        </div>
                    )}
                    {Message3 && (
                        <div className='Message3'>
                            <p className='message3'>
                                The time required exceeds the slot limit.<br></br><br></br>Decrease your booking size or pick a slot with a proceeding slot to enable two slot selection. 
                            </p>
                        </div>
                    )}
                    {Message4 && (
                        <div className='Message4'>
                            <p className='message4'>
                                Your cart is full, complete your pending booking first. 
                            </p>
                        </div>
                    )}
                    {serviceType == 'Car Cleaner' &&
                        (<div className='booking-body'>
                            <p className='close-booking' onClick={()=>setBooking(null)}>&#10006;</p>
                            <h2 className='booking-title'>{serviceType}</h2>
                            <div className='main-disclaimer5'><h3>Select available appointment date and slot</h3></div>
                            <div className='booking-calender fixed-calendar'>
                                <div className='Month-selector'>
                                    <p>{monthsArray[Month]}{' '}{Year}</p>
                                    <img className='month-above' src='/Images/up.png' onClick={MonthUp}></img>
                                    <img className='month-below' src='/Images/down.png' onClick={MonthDown}></img>
                                </div>
                                <div className='Day-selector'>
                                    {calendarDates.length==42 &&
                                        (<><div className='grid'><p>Su</p><p>Mo</p><p>Tu</p><p>We</p><p>Th</p><p>Fr</p><p>Sa</p></div>
                                        <div className='grid'><p onClick={()=>AppointmentDate(0)} style={{fontWeight: dayFontWeight[0], fontSize: dayFont[0], color: dayColor[0]}}>{format(calendarDates[0], 'd')}</p> <p onClick={()=>AppointmentDate(1)} style={{fontWeight: dayFontWeight[1], fontSize: dayFont[1], color: dayColor[1]}}>{format(calendarDates[1], 'd')}</p> <p onClick={()=>AppointmentDate(2)} style={{fontWeight: dayFontWeight[2], fontSize: dayFont[2], color: dayColor[2]}}>{format(calendarDates[2], 'd')}</p> <p onClick={()=>AppointmentDate(3)} style={{fontWeight: dayFontWeight[3], fontSize: dayFont[3], color: dayColor[3]}}>{format(calendarDates[3], 'd')}</p> <p onClick={()=>AppointmentDate(4)} style={{fontWeight: dayFontWeight[4], fontSize: dayFont[4], color: dayColor[4]}}>{format(calendarDates[4], 'd')}</p> <p onClick={()=>AppointmentDate(5)} style={{fontWeight: dayFontWeight[5], fontSize: dayFont[5], color: dayColor[5]}}>{format(calendarDates[5], 'd')}</p> <p onClick={()=>AppointmentDate(6)} style={{fontWeight: dayFontWeight[6], fontSize: dayFont[6], color: dayColor[6]}}>{format(calendarDates[6], 'd')}</p></div>
                                        <div className='grid'><p onClick={()=>AppointmentDate(7)} style={{fontWeight: dayFontWeight[7], fontSize: dayFont[7], color: dayColor[7]}}>{format(calendarDates[7], 'd')}</p> <p onClick={()=>AppointmentDate(8)} style={{fontWeight: dayFontWeight[8], fontSize: dayFont[8], color: dayColor[8]}}>{format(calendarDates[8], 'd')}</p> <p onClick={()=>AppointmentDate(9)} style={{fontWeight: dayFontWeight[9], fontSize: dayFont[9], color: dayColor[9]}}>{format(calendarDates[9], 'd')}</p> <p onClick={()=>AppointmentDate(10)} style={{fontWeight: dayFontWeight[10], fontSize: dayFont[10], color: dayColor[10]}}>{format(calendarDates[10], 'd')}</p> <p onClick={()=>AppointmentDate(11)} style={{fontWeight: dayFontWeight[11], fontSize: dayFont[11], color: dayColor[11]}}>{format(calendarDates[11], 'd')}</p> <p onClick={()=>AppointmentDate(12)} style={{fontWeight: dayFontWeight[12], fontSize: dayFont[12], color: dayColor[12]}}>{format(calendarDates[12], 'd')}</p> <p onClick={()=>AppointmentDate(13)} style={{fontWeight: dayFontWeight[13], fontSize: dayFont[13], color: dayColor[13]}}>{format(calendarDates[13], 'd')}</p> </div>
                                        <div className='grid'><p onClick={()=>AppointmentDate(14)} style={{fontWeight: dayFontWeight[14], fontSize: dayFont[14], color: dayColor[14]}}>{format(calendarDates[14], 'd')}</p> <p onClick={()=>AppointmentDate(15)} style={{fontWeight: dayFontWeight[15], fontSize: dayFont[15], color: dayColor[15]}}>{format(calendarDates[15], 'd')}</p> <p onClick={()=>AppointmentDate(16)} style={{fontWeight: dayFontWeight[16], fontSize: dayFont[16], color: dayColor[16]}}>{format(calendarDates[16], 'd')}</p> <p onClick={()=>AppointmentDate(17)} style={{fontWeight: dayFontWeight[17], fontSize: dayFont[17], color: dayColor[17]}}>{format(calendarDates[17], 'd')}</p> <p onClick={()=>AppointmentDate(18)} style={{fontWeight: dayFontWeight[18], fontSize: dayFont[18], color: dayColor[18]}}>{format(calendarDates[18], 'd')}</p> <p onClick={()=>AppointmentDate(19)} style={{fontWeight: dayFontWeight[19], fontSize: dayFont[19], color: dayColor[19]}}>{format(calendarDates[19], 'd')}</p> <p onClick={()=>AppointmentDate(20)} style={{fontWeight: dayFontWeight[20], fontSize: dayFont[20], color: dayColor[20]}}>{format(calendarDates[20], 'd')}</p></div>
                                        <div className='grid'><p onClick={()=>AppointmentDate(21)} style={{fontWeight: dayFontWeight[21], fontSize: dayFont[21], color: dayColor[21]}}>{format(calendarDates[21], 'd')}</p> <p onClick={()=>AppointmentDate(22)} style={{fontWeight: dayFontWeight[22], fontSize: dayFont[22], color: dayColor[22]}}>{format(calendarDates[22], 'd')}</p> <p onClick={()=>AppointmentDate(23)} style={{fontWeight: dayFontWeight[23], fontSize: dayFont[23], color: dayColor[23]}}>{format(calendarDates[23], 'd')}</p> <p onClick={()=>AppointmentDate(24)} style={{fontWeight: dayFontWeight[24], fontSize: dayFont[24], color: dayColor[24]}}>{format(calendarDates[24], 'd')}</p> <p onClick={()=>AppointmentDate(25)} style={{fontWeight: dayFontWeight[25], fontSize: dayFont[25], color: dayColor[25]}}>{format(calendarDates[25], 'd')}</p> <p onClick={()=>AppointmentDate(26)} style={{fontWeight: dayFontWeight[26], fontSize: dayFont[26], color: dayColor[26]}}>{format(calendarDates[26], 'd')}</p> <p onClick={()=>AppointmentDate(27)} style={{fontWeight: dayFontWeight[27], fontSize: dayFont[27], color: dayColor[27]}}>{format(calendarDates[27], 'd')}</p></div>
                                        <div className='grid'><p onClick={()=>AppointmentDate(28)} style={{fontWeight: dayFontWeight[28], fontSize: dayFont[28], color: dayColor[28]}}>{format(calendarDates[28], 'd')}</p> <p onClick={()=>AppointmentDate(29)} style={{fontWeight: dayFontWeight[29], fontSize: dayFont[29], color: dayColor[29]}}>{format(calendarDates[29], 'd')}</p> <p onClick={()=>AppointmentDate(30)} style={{fontWeight: dayFontWeight[30], fontSize: dayFont[30], color: dayColor[30]}}>{format(calendarDates[30], 'd')}</p> <p onClick={()=>AppointmentDate(31)} style={{fontWeight: dayFontWeight[31], fontSize: dayFont[31], color: dayColor[31]}}>{format(calendarDates[31], 'd')}</p> <p onClick={()=>AppointmentDate(32)} style={{fontWeight: dayFontWeight[32], fontSize: dayFont[32], color: dayColor[32]}}>{format(calendarDates[32], 'd')}</p> <p onClick={()=>AppointmentDate(33)} style={{fontWeight: dayFontWeight[33], fontSize: dayFont[33], color: dayColor[33]}}>{format(calendarDates[33], 'd')}</p> <p onClick={()=>AppointmentDate(34)} style={{fontWeight: dayFontWeight[34], fontSize: dayFont[34], color: dayColor[34]}}>{format(calendarDates[34], 'd')}</p></div>
                                        <div className='grid'><p onClick={()=>AppointmentDate(35)} style={{fontWeight: dayFontWeight[35], fontSize: dayFont[35], color: dayColor[35]}}>{format(calendarDates[35], 'd')}</p> <p onClick={()=>AppointmentDate(36)} style={{fontWeight: dayFontWeight[36], fontSize: dayFont[36], color: dayColor[36]}}>{format(calendarDates[36], 'd')}</p> <p onClick={()=>AppointmentDate(37)} style={{fontWeight: dayFontWeight[37], fontSize: dayFont[37], color: dayColor[37]}}>{format(calendarDates[37], 'd')}</p> <p onClick={()=>AppointmentDate(38)} style={{fontWeight: dayFontWeight[38], fontSize: dayFont[38], color: dayColor[38]}}>{format(calendarDates[38], 'd')}</p> <p onClick={()=>AppointmentDate(39)} style={{fontWeight: dayFontWeight[39], fontSize: dayFont[39], color: dayColor[39]}}>{format(calendarDates[39], 'd')}</p> <p onClick={()=>AppointmentDate(40)} style={{fontWeight: dayFontWeight[40], fontSize: dayFont[40], color: dayColor[40]}}>{format(calendarDates[40], 'd')}</p> <p onClick={()=>AppointmentDate(41)} style={{fontWeight: dayFontWeight[41], fontSize: dayFont[41], color: dayColor[41]}}>{format(calendarDates[41], 'd')}</p></div>
                                    </>)}
                                    {calendarDates.length==35 &&
                                        (<><div className='grid'><p>Su</p><p>Mo</p><p>Tu</p><p>We</p><p>Th</p><p>Fr</p><p>Sa</p></div>
                                        <div className='grid'><p onClick={()=>AppointmentDate(0)} style={{fontWeight: dayFontWeight[0], fontSize: dayFont[0], color: dayColor[0]}}>{format(calendarDates[0], 'd')}</p> <p onClick={()=>AppointmentDate(1)} style={{fontWeight: dayFontWeight[1], fontSize: dayFont[1], color: dayColor[1]}}>{format(calendarDates[1], 'd')}</p> <p onClick={()=>AppointmentDate(2)} style={{fontWeight: dayFontWeight[2], fontSize: dayFont[2], color: dayColor[2]}}>{format(calendarDates[2], 'd')}</p> <p onClick={()=>AppointmentDate(3)} style={{fontWeight: dayFontWeight[3], fontSize: dayFont[3], color: dayColor[3]}}>{format(calendarDates[3], 'd')}</p> <p onClick={()=>AppointmentDate(4)} style={{fontWeight: dayFontWeight[4], fontSize: dayFont[4], color: dayColor[4]}}>{format(calendarDates[4], 'd')}</p> <p onClick={()=>AppointmentDate(5)} style={{fontWeight: dayFontWeight[5], fontSize: dayFont[5], color: dayColor[5]}}>{format(calendarDates[5], 'd')}</p> <p onClick={()=>AppointmentDate(6)} style={{fontWeight: dayFontWeight[6], fontSize: dayFont[6], color: dayColor[6]}}>{format(calendarDates[6], 'd')}</p></div>
                                        <div className='grid'><p onClick={()=>AppointmentDate(7)} style={{fontWeight: dayFontWeight[7], fontSize: dayFont[7], color: dayColor[7]}}>{format(calendarDates[7], 'd')}</p> <p onClick={()=>AppointmentDate(8)} style={{fontWeight: dayFontWeight[8], fontSize: dayFont[8], color: dayColor[8]}}>{format(calendarDates[8], 'd')}</p> <p onClick={()=>AppointmentDate(9)} style={{fontWeight: dayFontWeight[9], fontSize: dayFont[9], color: dayColor[9]}}>{format(calendarDates[9], 'd')}</p> <p onClick={()=>AppointmentDate(10)} style={{fontWeight: dayFontWeight[10], fontSize: dayFont[10], color: dayColor[10]}}>{format(calendarDates[10], 'd')}</p> <p onClick={()=>AppointmentDate(11)} style={{fontWeight: dayFontWeight[11], fontSize: dayFont[11], color: dayColor[11]}}>{format(calendarDates[11], 'd')}</p> <p onClick={()=>AppointmentDate(12)} style={{fontWeight: dayFontWeight[12], fontSize: dayFont[12], color: dayColor[12]}}>{format(calendarDates[12], 'd')}</p> <p onClick={()=>AppointmentDate(13)} style={{fontWeight: dayFontWeight[13], fontSize: dayFont[13], color: dayColor[13]}}>{format(calendarDates[13], 'd')}</p> </div>
                                        <div className='grid'><p onClick={()=>AppointmentDate(14)} style={{fontWeight: dayFontWeight[14], fontSize: dayFont[14], color: dayColor[14]}}>{format(calendarDates[14], 'd')}</p> <p onClick={()=>AppointmentDate(15)} style={{fontWeight: dayFontWeight[15], fontSize: dayFont[15], color: dayColor[15]}}>{format(calendarDates[15], 'd')}</p> <p onClick={()=>AppointmentDate(16)} style={{fontWeight: dayFontWeight[16], fontSize: dayFont[16], color: dayColor[16]}}>{format(calendarDates[16], 'd')}</p> <p onClick={()=>AppointmentDate(17)} style={{fontWeight: dayFontWeight[17], fontSize: dayFont[17], color: dayColor[17]}}>{format(calendarDates[17], 'd')}</p> <p onClick={()=>AppointmentDate(18)} style={{fontWeight: dayFontWeight[18], fontSize: dayFont[18], color: dayColor[18]}}>{format(calendarDates[18], 'd')}</p> <p onClick={()=>AppointmentDate(19)} style={{fontWeight: dayFontWeight[19], fontSize: dayFont[19], color: dayColor[19]}}>{format(calendarDates[19], 'd')}</p> <p onClick={()=>AppointmentDate(20)} style={{fontWeight: dayFontWeight[20], fontSize: dayFont[20], color: dayColor[20]}}>{format(calendarDates[20], 'd')}</p></div>
                                        <div className='grid'><p onClick={()=>AppointmentDate(21)} style={{fontWeight: dayFontWeight[21], fontSize: dayFont[21], color: dayColor[21]}}>{format(calendarDates[21], 'd')}</p> <p onClick={()=>AppointmentDate(22)} style={{fontWeight: dayFontWeight[22], fontSize: dayFont[22], color: dayColor[22]}}>{format(calendarDates[22], 'd')}</p> <p onClick={()=>AppointmentDate(23)} style={{fontWeight: dayFontWeight[23], fontSize: dayFont[23], color: dayColor[23]}}>{format(calendarDates[23], 'd')}</p> <p onClick={()=>AppointmentDate(24)} style={{fontWeight: dayFontWeight[24], fontSize: dayFont[24], color: dayColor[24]}}>{format(calendarDates[24], 'd')}</p> <p onClick={()=>AppointmentDate(25)} style={{fontWeight: dayFontWeight[25], fontSize: dayFont[25], color: dayColor[25]}}>{format(calendarDates[25], 'd')}</p> <p onClick={()=>AppointmentDate(26)} style={{fontWeight: dayFontWeight[26], fontSize: dayFont[26], color: dayColor[26]}}>{format(calendarDates[26], 'd')}</p> <p onClick={()=>AppointmentDate(27)} style={{fontWeight: dayFontWeight[27], fontSize: dayFont[27], color: dayColor[27]}}>{format(calendarDates[27], 'd')}</p></div>
                                        <div className='grid'><p onClick={()=>AppointmentDate(28)} style={{fontWeight: dayFontWeight[28], fontSize: dayFont[28], color: dayColor[28]}}>{format(calendarDates[28], 'd')}</p> <p onClick={()=>AppointmentDate(29)} style={{fontWeight: dayFontWeight[29], fontSize: dayFont[29], color: dayColor[29]}}>{format(calendarDates[29], 'd')}</p> <p onClick={()=>AppointmentDate(30)} style={{fontWeight: dayFontWeight[30], fontSize: dayFont[30], color: dayColor[30]}}>{format(calendarDates[30], 'd')}</p> <p onClick={()=>AppointmentDate(31)} style={{fontWeight: dayFontWeight[31], fontSize: dayFont[31], color: dayColor[31]}}>{format(calendarDates[31], 'd')}</p> <p onClick={()=>AppointmentDate(32)} style={{fontWeight: dayFontWeight[32], fontSize: dayFont[32], color: dayColor[32]}}>{format(calendarDates[32], 'd')}</p> <p onClick={()=>AppointmentDate(33)} style={{fontWeight: dayFontWeight[33], fontSize: dayFont[33], color: dayColor[33]}}>{format(calendarDates[33], 'd')}</p> <p onClick={()=>AppointmentDate(34)} style={{fontWeight: dayFontWeight[34], fontSize: dayFont[34], color: dayColor[34]}}>{format(calendarDates[34], 'd')}</p></div>
                                    </>)}
                                </div>
                                <div className='slots-block car-slots'>
                                    <div className='slot' onClick={()=>( Availability[1]==false || Resting[1]==true)?NullFunc:AppointmentSlot(1)} style={{opacity:(Slot1==1 || Availability[1]==false || Resting[1]==true)?0.5:1}}>
                                        <p className='slot-name'>Slot 1</p>
                                        <p className='slot-duration'>7:00-8:00</p>
                                        {Availability[1]==true && Resting[1]==false && (
                                            <p className='available-tag'>available</p>
                                        )}
                                        {(Availability[1]==false || Resting[1]==true) && (
                                            <p className='unavailable-tag'>unavailable</p>
                                        )}
                                    </div>
                                    <div className='slot' onClick={()=>( Availability[2]==false || Resting[2]==true)?NullFunc:AppointmentSlot(2)} style={{opacity:(Slot1==2 || Slot2==2 || Availability[2]==false || Resting[2]==true)?0.5:1}}>
                                        <p className='slot-name'>Slot 2</p>
                                        <p className='slot-duration'>9:00-10:00</p>
                                        {Availability[2]==true && Resting[2]==false && (
                                            <p className='available-tag'>available</p>
                                        )}
                                        {(Availability[2]==false || Resting[2]==true) && (
                                            <p className='unavailable-tag'>unavailable</p>
                                        )}
                                        <div className='slot-filter'></div>
                                    </div>
                                    <div className='slot' onClick={()=>( Availability[3]==false || Resting[3]==true)?NullFunc:AppointmentSlot(3)} style={{opacity:(Slot1==3 || Slot2==3 ||  Availability[3]==false || Resting[3]==true)?0.5:1}}>
                                        <p className='slot-name'>Slot 3</p>
                                        <p className='slot-duration'>11:00-12:00</p>
                                        {Availability[3]==true && Resting[3]==false && (
                                            <p className='available-tag'>available</p>
                                        )}
                                        {(Availability[3]==false || Resting[3]==true) && (
                                            <p className='unavailable-tag'>unavailable</p>
                                        )}
                                        <div className='slot-filter'></div>
                                    </div>
                                    <div className='slot' onClick={()=>( Availability[4]==false || Resting[4]==true)?NullFunc:AppointmentSlot(4)} style={{opacity:(Slot1==4 || Slot2==4 || Availability[4]==false || Resting[4]==true)?0.5:1}}>
                                        <p className='slot-name'>Slot 4</p>
                                        <p className='slot-duration'>13:00-14:00</p>
                                        {Availability[4]==true && Resting[4]==false && (
                                            <p className='available-tag'>available</p>
                                        )}
                                        {(Availability[4]==false || Resting[4]==true) && (
                                            <p className='unavailable-tag'>unavailable</p>
                                        )}
                                        <div className='slot-filter'></div>
                                    </div>
                                    <div className='slot' onClick={()=>( Availability[5]==false || Resting[5]==true)?NullFunc:AppointmentSlot(5)} style={{opacity:(Slot1==5 || Slot2==5 || Availability[5]==false || Resting[5]==true)?0.5:1}}>
                                        <p className='slot-name'>Slot 5</p>
                                        <p className='slot-duration'>15:00-16:00</p>
                                        {Availability[5]==true && Resting[5]==false && (
                                            <p className='available-tag'>available</p>
                                        )}
                                        {(Availability[5]==false || Resting[5]==true) && (
                                            <p className='unavailable-tag'>unavailable</p>
                                        )}
                                        <div className='slot-filter'></div>
                                    </div>
                                </div>
                            </div>
                            <div className='car-count'>
                                <h3>Number of cars: {carCount}</h3>
                                <div>
                                    <button className='cc-btn1' onClick={Minus}>-</button>
                                    <button className='cc-btn2' onClick={Add}>+</button>
                                </div>
                            </div>
                            <div className='booking-specs'>
                                {toggle && forms.map((form, index)=>
                                    <CarCleaning index={index} forms={forms} setForms={Changes}></CarCleaning>
                                )}
                            </div>
                            <div className='submit-block' onClick={SubmitCar}>
                                <div><h3>Submit Booking</h3>
                                    <h3>R{price}</h3>
                                    {carTime==1 &&
                                        <p className='time'>{`(${carTime} hr)`}</p>
                                    }
                                    {carTime!=1 &&
                                        <p className='time'>{`(${carTime} hrs)`}</p>
                                    }
                                </div>
                            </div>
                        </div>
                    )}
                    {serviceType == 'House Cleaner' &&
                        (<div className='booking-body2'>
                            <p className='close-booking' onClick={()=>setBooking(null)}>&#10006;</p>
                            <h2 className='booking-title'>{serviceType}</h2>
                            <div className='main-disclaimer5'><h3>Select available appointment date and slot</h3></div>
                            <div className='booking-calender fixed-calendar'>
                                <div className='Month-selector'>
                                    <p>{monthsArray[Month]}{' '}{Year}</p>
                                    <img className='month-above' src='/Images/up.png' onClick={MonthUp}></img>
                                    <img className='month-below' src='/Images/down.png' onClick={MonthDown}></img>
                                </div>
                                <div className='Day-selector'>
                                    {calendarDates.length==42 &&
                                        (<><div className='grid'><p>Su</p><p>Mo</p><p>Tu</p><p>We</p><p>Th</p><p>Fr</p><p>Sa</p></div>
                                        <div className='grid'><p onClick={()=>AppointmentDate(0)} style={{fontWeight: dayFontWeight[0], fontSize: dayFont[0], color: dayColor[0]}}>{format(calendarDates[0], 'd')}</p> <p onClick={()=>AppointmentDate(1)} style={{fontWeight: dayFontWeight[1], fontSize: dayFont[1], color: dayColor[1]}}>{format(calendarDates[1], 'd')}</p> <p onClick={()=>AppointmentDate(2)} style={{fontWeight: dayFontWeight[2], fontSize: dayFont[2], color: dayColor[2]}}>{format(calendarDates[2], 'd')}</p> <p onClick={()=>AppointmentDate(3)} style={{fontWeight: dayFontWeight[3], fontSize: dayFont[3], color: dayColor[3]}}>{format(calendarDates[3], 'd')}</p> <p onClick={()=>AppointmentDate(4)} style={{fontWeight: dayFontWeight[4], fontSize: dayFont[4], color: dayColor[4]}}>{format(calendarDates[4], 'd')}</p> <p onClick={()=>AppointmentDate(5)} style={{fontWeight: dayFontWeight[5], fontSize: dayFont[5], color: dayColor[5]}}>{format(calendarDates[5], 'd')}</p> <p onClick={()=>AppointmentDate(6)} style={{fontWeight: dayFontWeight[6], fontSize: dayFont[6], color: dayColor[6]}}>{format(calendarDates[6], 'd')}</p></div>
                                        <div className='grid'><p onClick={()=>AppointmentDate(7)} style={{fontWeight: dayFontWeight[7], fontSize: dayFont[7], color: dayColor[7]}}>{format(calendarDates[7], 'd')}</p> <p onClick={()=>AppointmentDate(8)} style={{fontWeight: dayFontWeight[8], fontSize: dayFont[8], color: dayColor[8]}}>{format(calendarDates[8], 'd')}</p> <p onClick={()=>AppointmentDate(9)} style={{fontWeight: dayFontWeight[9], fontSize: dayFont[9], color: dayColor[9]}}>{format(calendarDates[9], 'd')}</p> <p onClick={()=>AppointmentDate(10)} style={{fontWeight: dayFontWeight[10], fontSize: dayFont[10], color: dayColor[10]}}>{format(calendarDates[10], 'd')}</p> <p onClick={()=>AppointmentDate(11)} style={{fontWeight: dayFontWeight[11], fontSize: dayFont[11], color: dayColor[11]}}>{format(calendarDates[11], 'd')}</p> <p onClick={()=>AppointmentDate(12)} style={{fontWeight: dayFontWeight[12], fontSize: dayFont[12], color: dayColor[12]}}>{format(calendarDates[12], 'd')}</p> <p onClick={()=>AppointmentDate(13)} style={{fontWeight: dayFontWeight[13], fontSize: dayFont[13], color: dayColor[13]}}>{format(calendarDates[13], 'd')}</p> </div>
                                        <div className='grid'><p onClick={()=>AppointmentDate(14)} style={{fontWeight: dayFontWeight[14], fontSize: dayFont[14], color: dayColor[14]}}>{format(calendarDates[14], 'd')}</p> <p onClick={()=>AppointmentDate(15)} style={{fontWeight: dayFontWeight[15], fontSize: dayFont[15], color: dayColor[15]}}>{format(calendarDates[15], 'd')}</p> <p onClick={()=>AppointmentDate(16)} style={{fontWeight: dayFontWeight[16], fontSize: dayFont[16], color: dayColor[16]}}>{format(calendarDates[16], 'd')}</p> <p onClick={()=>AppointmentDate(17)} style={{fontWeight: dayFontWeight[17], fontSize: dayFont[17], color: dayColor[17]}}>{format(calendarDates[17], 'd')}</p> <p onClick={()=>AppointmentDate(18)} style={{fontWeight: dayFontWeight[18], fontSize: dayFont[18], color: dayColor[18]}}>{format(calendarDates[18], 'd')}</p> <p onClick={()=>AppointmentDate(19)} style={{fontWeight: dayFontWeight[19], fontSize: dayFont[19], color: dayColor[19]}}>{format(calendarDates[19], 'd')}</p> <p onClick={()=>AppointmentDate(20)} style={{fontWeight: dayFontWeight[20], fontSize: dayFont[20], color: dayColor[20]}}>{format(calendarDates[20], 'd')}</p></div>
                                        <div className='grid'><p onClick={()=>AppointmentDate(21)} style={{fontWeight: dayFontWeight[21], fontSize: dayFont[21], color: dayColor[21]}}>{format(calendarDates[21], 'd')}</p> <p onClick={()=>AppointmentDate(22)} style={{fontWeight: dayFontWeight[22], fontSize: dayFont[22], color: dayColor[22]}}>{format(calendarDates[22], 'd')}</p> <p onClick={()=>AppointmentDate(23)} style={{fontWeight: dayFontWeight[23], fontSize: dayFont[23], color: dayColor[23]}}>{format(calendarDates[23], 'd')}</p> <p onClick={()=>AppointmentDate(24)} style={{fontWeight: dayFontWeight[24], fontSize: dayFont[24], color: dayColor[24]}}>{format(calendarDates[24], 'd')}</p> <p onClick={()=>AppointmentDate(25)} style={{fontWeight: dayFontWeight[25], fontSize: dayFont[25], color: dayColor[25]}}>{format(calendarDates[25], 'd')}</p> <p onClick={()=>AppointmentDate(26)} style={{fontWeight: dayFontWeight[26], fontSize: dayFont[26], color: dayColor[26]}}>{format(calendarDates[26], 'd')}</p> <p onClick={()=>AppointmentDate(27)} style={{fontWeight: dayFontWeight[27], fontSize: dayFont[27], color: dayColor[27]}}>{format(calendarDates[27], 'd')}</p></div>
                                        <div className='grid'><p onClick={()=>AppointmentDate(28)} style={{fontWeight: dayFontWeight[28], fontSize: dayFont[28], color: dayColor[28]}}>{format(calendarDates[28], 'd')}</p> <p onClick={()=>AppointmentDate(29)} style={{fontWeight: dayFontWeight[29], fontSize: dayFont[29], color: dayColor[29]}}>{format(calendarDates[29], 'd')}</p> <p onClick={()=>AppointmentDate(30)} style={{fontWeight: dayFontWeight[30], fontSize: dayFont[30], color: dayColor[30]}}>{format(calendarDates[30], 'd')}</p> <p onClick={()=>AppointmentDate(31)} style={{fontWeight: dayFontWeight[31], fontSize: dayFont[31], color: dayColor[31]}}>{format(calendarDates[31], 'd')}</p> <p onClick={()=>AppointmentDate(32)} style={{fontWeight: dayFontWeight[32], fontSize: dayFont[32], color: dayColor[32]}}>{format(calendarDates[32], 'd')}</p> <p onClick={()=>AppointmentDate(33)} style={{fontWeight: dayFontWeight[33], fontSize: dayFont[33], color: dayColor[33]}}>{format(calendarDates[33], 'd')}</p> <p onClick={()=>AppointmentDate(34)} style={{fontWeight: dayFontWeight[34], fontSize: dayFont[34], color: dayColor[34]}}>{format(calendarDates[34], 'd')}</p></div>
                                        <div className='grid'><p onClick={()=>AppointmentDate(35)} style={{fontWeight: dayFontWeight[35], fontSize: dayFont[35], color: dayColor[35]}}>{format(calendarDates[35], 'd')}</p> <p onClick={()=>AppointmentDate(36)} style={{fontWeight: dayFontWeight[36], fontSize: dayFont[36], color: dayColor[36]}}>{format(calendarDates[36], 'd')}</p> <p onClick={()=>AppointmentDate(37)} style={{fontWeight: dayFontWeight[37], fontSize: dayFont[37], color: dayColor[37]}}>{format(calendarDates[37], 'd')}</p> <p onClick={()=>AppointmentDate(38)} style={{fontWeight: dayFontWeight[38], fontSize: dayFont[38], color: dayColor[38]}}>{format(calendarDates[38], 'd')}</p> <p onClick={()=>AppointmentDate(39)} style={{fontWeight: dayFontWeight[39], fontSize: dayFont[39], color: dayColor[39]}}>{format(calendarDates[39], 'd')}</p> <p onClick={()=>AppointmentDate(40)} style={{fontWeight: dayFontWeight[40], fontSize: dayFont[40], color: dayColor[40]}}>{format(calendarDates[40], 'd')}</p> <p onClick={()=>AppointmentDate(41)} style={{fontWeight: dayFontWeight[41], fontSize: dayFont[41], color: dayColor[41]}}>{format(calendarDates[41], 'd')}</p></div>
                                    </>)}
                                    {calendarDates.length==35 &&
                                        (<><div className='grid'><p>Su</p><p>Mo</p><p>Tu</p><p>We</p><p>Th</p><p>Fr</p><p>Sa</p></div>
                                        <div className='grid'><p onClick={()=>AppointmentDate(0)} style={{fontWeight: dayFontWeight[0], fontSize: dayFont[0], color: dayColor[0]}}>{format(calendarDates[0], 'd')}</p> <p onClick={()=>AppointmentDate(1)} style={{fontWeight: dayFontWeight[1], fontSize: dayFont[1], color: dayColor[1]}}>{format(calendarDates[1], 'd')}</p> <p onClick={()=>AppointmentDate(2)} style={{fontWeight: dayFontWeight[2], fontSize: dayFont[2], color: dayColor[2]}}>{format(calendarDates[2], 'd')}</p> <p onClick={()=>AppointmentDate(3)} style={{fontWeight: dayFontWeight[3], fontSize: dayFont[3], color: dayColor[3]}}>{format(calendarDates[3], 'd')}</p> <p onClick={()=>AppointmentDate(4)} style={{fontWeight: dayFontWeight[4], fontSize: dayFont[4], color: dayColor[4]}}>{format(calendarDates[4], 'd')}</p> <p onClick={()=>AppointmentDate(5)} style={{fontWeight: dayFontWeight[5], fontSize: dayFont[5], color: dayColor[5]}}>{format(calendarDates[5], 'd')}</p> <p onClick={()=>AppointmentDate(6)} style={{fontWeight: dayFontWeight[6], fontSize: dayFont[6], color: dayColor[6]}}>{format(calendarDates[6], 'd')}</p></div>
                                        <div className='grid'><p onClick={()=>AppointmentDate(7)} style={{fontWeight: dayFontWeight[7], fontSize: dayFont[7], color: dayColor[7]}}>{format(calendarDates[7], 'd')}</p> <p onClick={()=>AppointmentDate(8)} style={{fontWeight: dayFontWeight[8], fontSize: dayFont[8], color: dayColor[8]}}>{format(calendarDates[8], 'd')}</p> <p onClick={()=>AppointmentDate(9)} style={{fontWeight: dayFontWeight[9], fontSize: dayFont[9], color: dayColor[9]}}>{format(calendarDates[9], 'd')}</p> <p onClick={()=>AppointmentDate(10)} style={{fontWeight: dayFontWeight[10], fontSize: dayFont[10], color: dayColor[10]}}>{format(calendarDates[10], 'd')}</p> <p onClick={()=>AppointmentDate(11)} style={{fontWeight: dayFontWeight[11], fontSize: dayFont[11], color: dayColor[11]}}>{format(calendarDates[11], 'd')}</p> <p onClick={()=>AppointmentDate(12)} style={{fontWeight: dayFontWeight[12], fontSize: dayFont[12], color: dayColor[12]}}>{format(calendarDates[12], 'd')}</p> <p onClick={()=>AppointmentDate(13)} style={{fontWeight: dayFontWeight[13], fontSize: dayFont[13], color: dayColor[13]}}>{format(calendarDates[13], 'd')}</p> </div>
                                        <div className='grid'><p onClick={()=>AppointmentDate(14)} style={{fontWeight: dayFontWeight[14], fontSize: dayFont[14], color: dayColor[14]}}>{format(calendarDates[14], 'd')}</p> <p onClick={()=>AppointmentDate(15)} style={{fontWeight: dayFontWeight[15], fontSize: dayFont[15], color: dayColor[15]}}>{format(calendarDates[15], 'd')}</p> <p onClick={()=>AppointmentDate(16)} style={{fontWeight: dayFontWeight[16], fontSize: dayFont[16], color: dayColor[16]}}>{format(calendarDates[16], 'd')}</p> <p onClick={()=>AppointmentDate(17)} style={{fontWeight: dayFontWeight[17], fontSize: dayFont[17], color: dayColor[17]}}>{format(calendarDates[17], 'd')}</p> <p onClick={()=>AppointmentDate(18)} style={{fontWeight: dayFontWeight[18], fontSize: dayFont[18], color: dayColor[18]}}>{format(calendarDates[18], 'd')}</p> <p onClick={()=>AppointmentDate(19)} style={{fontWeight: dayFontWeight[19], fontSize: dayFont[19], color: dayColor[19]}}>{format(calendarDates[19], 'd')}</p> <p onClick={()=>AppointmentDate(20)} style={{fontWeight: dayFontWeight[20], fontSize: dayFont[20], color: dayColor[20]}}>{format(calendarDates[20], 'd')}</p></div>
                                        <div className='grid'><p onClick={()=>AppointmentDate(21)} style={{fontWeight: dayFontWeight[21], fontSize: dayFont[21], color: dayColor[21]}}>{format(calendarDates[21], 'd')}</p> <p onClick={()=>AppointmentDate(22)} style={{fontWeight: dayFontWeight[22], fontSize: dayFont[22], color: dayColor[22]}}>{format(calendarDates[22], 'd')}</p> <p onClick={()=>AppointmentDate(23)} style={{fontWeight: dayFontWeight[23], fontSize: dayFont[23], color: dayColor[23]}}>{format(calendarDates[23], 'd')}</p> <p onClick={()=>AppointmentDate(24)} style={{fontWeight: dayFontWeight[24], fontSize: dayFont[24], color: dayColor[24]}}>{format(calendarDates[24], 'd')}</p> <p onClick={()=>AppointmentDate(25)} style={{fontWeight: dayFontWeight[25], fontSize: dayFont[25], color: dayColor[25]}}>{format(calendarDates[25], 'd')}</p> <p onClick={()=>AppointmentDate(26)} style={{fontWeight: dayFontWeight[26], fontSize: dayFont[26], color: dayColor[26]}}>{format(calendarDates[26], 'd')}</p> <p onClick={()=>AppointmentDate(27)} style={{fontWeight: dayFontWeight[27], fontSize: dayFont[27], color: dayColor[27]}}>{format(calendarDates[27], 'd')}</p></div>
                                        <div className='grid'><p onClick={()=>AppointmentDate(28)} style={{fontWeight: dayFontWeight[28], fontSize: dayFont[28], color: dayColor[28]}}>{format(calendarDates[28], 'd')}</p> <p onClick={()=>AppointmentDate(29)} style={{fontWeight: dayFontWeight[29], fontSize: dayFont[29], color: dayColor[29]}}>{format(calendarDates[29], 'd')}</p> <p onClick={()=>AppointmentDate(30)} style={{fontWeight: dayFontWeight[30], fontSize: dayFont[30], color: dayColor[30]}}>{format(calendarDates[30], 'd')}</p> <p onClick={()=>AppointmentDate(31)} style={{fontWeight: dayFontWeight[31], fontSize: dayFont[31], color: dayColor[31]}}>{format(calendarDates[31], 'd')}</p> <p onClick={()=>AppointmentDate(32)} style={{fontWeight: dayFontWeight[32], fontSize: dayFont[32], color: dayColor[32]}}>{format(calendarDates[32], 'd')}</p> <p onClick={()=>AppointmentDate(33)} style={{fontWeight: dayFontWeight[33], fontSize: dayFont[33], color: dayColor[33]}}>{format(calendarDates[33], 'd')}</p> <p onClick={()=>AppointmentDate(34)} style={{fontWeight: dayFontWeight[34], fontSize: dayFont[34], color: dayColor[34]}}>{format(calendarDates[34], 'd')}</p></div>
                                    </>)}
                                </div>
                                <div className='slots-block house-slots'>
                                    <div className='slot' onClick={()=>( Availability[1]==false || Resting[1]==true)?NullFunc:AppointmentSlot(1)} style={{opacity:(Slot1==1 || Availability[1]==false || Resting[1]==true)?0.5:1}}>
                                        <p className='slot-name'>Slot 1</p>
                                        <p className='slot-duration'>8:00-12:00</p>
                                        {Availability[1]==true && Resting[1]==false && (
                                            <p className='available-tag'>available</p>
                                        )}
                                        {(Availability[1]==false || Resting[1]==true) && (
                                            <p className='unavailable-tag'>unavailable</p>
                                        )}
                                        <div className='slot-filter'></div>
                                    </div>
                                    <div className='slot' onClick={()=>( Availability[2]==false || Resting[2]==true)?NullFunc:AppointmentSlot(2)} style={{opacity:(Slot1==2 || Slot2==2 || Availability[2]==false || Resting[2]==true)?0.5:1}}>
                                        <p className='slot-name'>Slot 2</p>
                                        <p className='slot-duration'>13:00-17:00</p>
                                        {Availability[2]==true && Resting[2]==false && (
                                            <p className='available-tag'>available</p>
                                        )}
                                        {(Availability[2]==false || Resting[2]==true) && (
                                            <p className='unavailable-tag'>unavailable</p>
                                        )}
                                        <div className='slot-filter'></div>
                                    </div>
                                </div>
                            </div>
                            <div className='room-count'>
                                {nbOn1 &&
                                    <p className='rc-disclaimer2' onClick={()=>setNO1(!nbOn1)}>*R15 per extra bedroom</p>
                                }
                                {!nbOn1 &&
                                    <p className='rc-disclaimer2' onClick={()=>setNO1(!nbOn1)}>*NB</p>
                                }
                                <div className='rooms'>
                                    <h3>{`Number of rooms: ${roomCount}`}</h3>
                                    <div>
                                        <button className='cc-btn1' onClick={MinusRoom}>-</button>
                                        <button className='cc-btn2' onClick={AddRoom}>+</button>
                                    </div>
                                </div>
                                {nbOn1 &&
                                    <p className='rc-disclaimer'>*R35 per extra bathroom</p>
                                }
                                {!nbOn1 &&
                                    <p className='rc-disclaimer'></p>
                                }
                                <div className='rooms'>
                                    <h3>{`Number of bathrooms: ${bathroomCount}`}</h3>
                                    <div>
                                        <button className='cc-btn1' onClick={MinusBathroom}>-</button>
                                        <button className='cc-btn2' onClick={AddBathroom}>+</button>
                                    </div>
                                </div>
                            </div>
                            <div className='booking-specs'>
                                <div className='card-label'><h1>Common Tasks</h1></div>
                                <div className='hc-commonTasks'>
                                    {nbOn2 &&
                                        <h4 className='disclaimer' onClick={()=>setNO2(!nbOn2)}>*Fixed fee of R200 for all common areas, passageways, 1 kitchen, 1 bathroom and 1 room.</h4>
                                    }
                                    {!nbOn2 &&
                                        <h4 className='disclaimer2' onClick={()=>setNO2(!nbOn2)}>*NB</h4>
                                    }
                                    <div className='Spec'>
                                        <h4>Laundry and Ironing ?</h4>
                                        {nbOn2 &&
                                            <p className='maxTime'>*Max Time: 2 hrs</p>
                                        }
                                        <div className='option'>
                                            <div onClick={()=>Laundry(true)} style={{border:border1 ,backgroundColor:filler1 }}></div>
                                            <p>Yes</p>
                                            <p>R80</p>
                                        </div>
                                        <div className='option'>
                                            <div onClick={()=>Laundry(false)} style={{border:border2 ,backgroundColor:filler2 }}></div>
                                            <p>No</p>
                                            <p>-</p>
                                        </div>
                                    </div>
                                    <div className='Spec'>
                                        <h4>Clean Fridge Interior ?</h4>
                                        {nbOn2 &&
                                            <p className='maxTime'>*Max Time: 30 min</p>
                                        }
                                        <div className='option'>
                                            <div onClick={()=>Fridge(true)} style={{border:border3 ,backgroundColor:filler3 }}></div>
                                            <p>Yes</p>
                                            <p>R20</p>
                                        </div>
                                        <div className='option'>
                                            <div onClick={()=>Fridge(false)} style={{border:border4 ,backgroundColor:filler4 }}></div>
                                            <p>No</p>
                                            <p>-</p>
                                        </div>
                                    </div>
                                    <div className='Spec'>
                                        <h4>Clean Cabinets ?</h4>
                                        {nbOn2 &&
                                            <p className='maxTime'>*Max Time: 30 min</p>
                                        }
                                        <div className='option'>
                                            <div onClick={()=>Cabinets(true)} style={{border:border5 ,backgroundColor:filler5 }}></div>
                                            <p>Yes</p>
                                            <p>R40</p>
                                        </div>
                                        <div className='option'>
                                            <div onClick={()=>Cabinets(false)} style={{border:border6 ,backgroundColor:filler6 }}></div>
                                            <p>No</p>
                                            <p>-</p>
                                        </div>
                                    </div>
                                    <div className='Spec'>
                                        <h4>Clean Oven Interior ?</h4>
                                        {nbOn2 &&
                                            <p className='maxTime'>*Max Time: 30 min</p>
                                        }
                                        <div className='option'>
                                            <div onClick={()=>Oven(true)} style={{border:border7 ,backgroundColor:filler7 }}></div>
                                            <p>Yes</p>
                                            <p>R20</p>
                                        </div>
                                        <div className='option'>
                                            <div onClick={()=>Oven(false)} style={{border:border8 ,backgroundColor:filler8 }}></div>
                                            <p>No</p>
                                            <p>-</p>
                                        </div>
                                    </div>
                                    <div className='Spec'>
                                        <h4>Clean Windows ?</h4>
                                        {nbOn2 &&
                                            <p className='maxTime'>*Max Time: 1 hr</p>
                                        }
                                        <div className='option'>
                                            <div onClick={()=>Windows(true)} style={{border:border9 ,backgroundColor:filler9 }}></div>
                                            <p>Yes</p>
                                            <p>R30</p>
                                        </div>
                                        <div className='option'>
                                            <div onClick={()=>Windows(false)} style={{border:border10 ,backgroundColor:filler10 }}></div>
                                            <p>No</p>
                                            <p>-</p>
                                        </div>
                                    </div>                                   
                                </div>
                                {roomForm.map((form, index)=>
                                    <HouseCleaning type='Room' index={index} forms={roomForm} setForm={Changes3}></HouseCleaning>
                                )}
                                {bathroomForm.map((form, index)=>
                                    <HouseCleaning type='Bathroom' index={index} forms={bathroomForm} setForm={Changes4}></HouseCleaning>
                                )}
                            </div>
                            <div className='submit-block' onClick={SubmitHouse}>
                                <div><h3>Submit Booking</h3>
                                    <h3>R{price}</h3>
                                    {serviceType=='House Cleaner' && totalTime==1 &&
                                        <p className='time'>{`(${totalTime} hr)`}</p>
                                    }
                                    {serviceType=='House Cleaner' && totalTime!=1 &&
                                        <p className='time'>{`(${totalTime} hrs)`}</p>
                                    }
                                </div>
                            </div>
                        </div>
                    )}
                    {serviceType == 'Pool Cleaner' &&
                        (<div className='booking-body3'>
                            <p className='close-booking' onClick={()=>setBooking(null)}>&#10006;</p>
                            <h2 className='booking-title'>{serviceType}</h2>
                            <div className='main-disclaimer5'><h3>Select available appointment date and slot</h3></div>
                            <div className='booking-calender fixed-calendar'>
                                <div className='Month-selector'>
                                    <p>{monthsArray[Month]}{' '}{Year}</p>
                                    <img className='month-above' src='/Images/up.png' onClick={MonthUp}></img>
                                    <img className='month-below' src='/Images/down.png' onClick={MonthDown}></img>
                                </div>
                                <div className='Day-selector'>
                                    {calendarDates.length==42 &&
                                        (<><div className='grid'><p>Su</p><p>Mo</p><p>Tu</p><p>We</p><p>Th</p><p>Fr</p><p>Sa</p></div>
                                        <div className='grid'><p onClick={()=>AppointmentDate(0)} style={{fontWeight: dayFontWeight[0], fontSize: dayFont[0], color: dayColor[0]}}>{format(calendarDates[0], 'd')}</p> <p onClick={()=>AppointmentDate(1)} style={{fontWeight: dayFontWeight[1], fontSize: dayFont[1], color: dayColor[1]}}>{format(calendarDates[1], 'd')}</p> <p onClick={()=>AppointmentDate(2)} style={{fontWeight: dayFontWeight[2], fontSize: dayFont[2], color: dayColor[2]}}>{format(calendarDates[2], 'd')}</p> <p onClick={()=>AppointmentDate(3)} style={{fontWeight: dayFontWeight[3], fontSize: dayFont[3], color: dayColor[3]}}>{format(calendarDates[3], 'd')}</p> <p onClick={()=>AppointmentDate(4)} style={{fontWeight: dayFontWeight[4], fontSize: dayFont[4], color: dayColor[4]}}>{format(calendarDates[4], 'd')}</p> <p onClick={()=>AppointmentDate(5)} style={{fontWeight: dayFontWeight[5], fontSize: dayFont[5], color: dayColor[5]}}>{format(calendarDates[5], 'd')}</p> <p onClick={()=>AppointmentDate(6)} style={{fontWeight: dayFontWeight[6], fontSize: dayFont[6], color: dayColor[6]}}>{format(calendarDates[6], 'd')}</p></div>
                                        <div className='grid'><p onClick={()=>AppointmentDate(7)} style={{fontWeight: dayFontWeight[7], fontSize: dayFont[7], color: dayColor[7]}}>{format(calendarDates[7], 'd')}</p> <p onClick={()=>AppointmentDate(8)} style={{fontWeight: dayFontWeight[8], fontSize: dayFont[8], color: dayColor[8]}}>{format(calendarDates[8], 'd')}</p> <p onClick={()=>AppointmentDate(9)} style={{fontWeight: dayFontWeight[9], fontSize: dayFont[9], color: dayColor[9]}}>{format(calendarDates[9], 'd')}</p> <p onClick={()=>AppointmentDate(10)} style={{fontWeight: dayFontWeight[10], fontSize: dayFont[10], color: dayColor[10]}}>{format(calendarDates[10], 'd')}</p> <p onClick={()=>AppointmentDate(11)} style={{fontWeight: dayFontWeight[11], fontSize: dayFont[11], color: dayColor[11]}}>{format(calendarDates[11], 'd')}</p> <p onClick={()=>AppointmentDate(12)} style={{fontWeight: dayFontWeight[12], fontSize: dayFont[12], color: dayColor[12]}}>{format(calendarDates[12], 'd')}</p> <p onClick={()=>AppointmentDate(13)} style={{fontWeight: dayFontWeight[13], fontSize: dayFont[13], color: dayColor[13]}}>{format(calendarDates[13], 'd')}</p> </div>
                                        <div className='grid'><p onClick={()=>AppointmentDate(14)} style={{fontWeight: dayFontWeight[14], fontSize: dayFont[14], color: dayColor[14]}}>{format(calendarDates[14], 'd')}</p> <p onClick={()=>AppointmentDate(15)} style={{fontWeight: dayFontWeight[15], fontSize: dayFont[15], color: dayColor[15]}}>{format(calendarDates[15], 'd')}</p> <p onClick={()=>AppointmentDate(16)} style={{fontWeight: dayFontWeight[16], fontSize: dayFont[16], color: dayColor[16]}}>{format(calendarDates[16], 'd')}</p> <p onClick={()=>AppointmentDate(17)} style={{fontWeight: dayFontWeight[17], fontSize: dayFont[17], color: dayColor[17]}}>{format(calendarDates[17], 'd')}</p> <p onClick={()=>AppointmentDate(18)} style={{fontWeight: dayFontWeight[18], fontSize: dayFont[18], color: dayColor[18]}}>{format(calendarDates[18], 'd')}</p> <p onClick={()=>AppointmentDate(19)} style={{fontWeight: dayFontWeight[19], fontSize: dayFont[19], color: dayColor[19]}}>{format(calendarDates[19], 'd')}</p> <p onClick={()=>AppointmentDate(20)} style={{fontWeight: dayFontWeight[20], fontSize: dayFont[20], color: dayColor[20]}}>{format(calendarDates[20], 'd')}</p></div>
                                        <div className='grid'><p onClick={()=>AppointmentDate(21)} style={{fontWeight: dayFontWeight[21], fontSize: dayFont[21], color: dayColor[21]}}>{format(calendarDates[21], 'd')}</p> <p onClick={()=>AppointmentDate(22)} style={{fontWeight: dayFontWeight[22], fontSize: dayFont[22], color: dayColor[22]}}>{format(calendarDates[22], 'd')}</p> <p onClick={()=>AppointmentDate(23)} style={{fontWeight: dayFontWeight[23], fontSize: dayFont[23], color: dayColor[23]}}>{format(calendarDates[23], 'd')}</p> <p onClick={()=>AppointmentDate(24)} style={{fontWeight: dayFontWeight[24], fontSize: dayFont[24], color: dayColor[24]}}>{format(calendarDates[24], 'd')}</p> <p onClick={()=>AppointmentDate(25)} style={{fontWeight: dayFontWeight[25], fontSize: dayFont[25], color: dayColor[25]}}>{format(calendarDates[25], 'd')}</p> <p onClick={()=>AppointmentDate(26)} style={{fontWeight: dayFontWeight[26], fontSize: dayFont[26], color: dayColor[26]}}>{format(calendarDates[26], 'd')}</p> <p onClick={()=>AppointmentDate(27)} style={{fontWeight: dayFontWeight[27], fontSize: dayFont[27], color: dayColor[27]}}>{format(calendarDates[27], 'd')}</p></div>
                                        <div className='grid'><p onClick={()=>AppointmentDate(28)} style={{fontWeight: dayFontWeight[28], fontSize: dayFont[28], color: dayColor[28]}}>{format(calendarDates[28], 'd')}</p> <p onClick={()=>AppointmentDate(29)} style={{fontWeight: dayFontWeight[29], fontSize: dayFont[29], color: dayColor[29]}}>{format(calendarDates[29], 'd')}</p> <p onClick={()=>AppointmentDate(30)} style={{fontWeight: dayFontWeight[30], fontSize: dayFont[30], color: dayColor[30]}}>{format(calendarDates[30], 'd')}</p> <p onClick={()=>AppointmentDate(31)} style={{fontWeight: dayFontWeight[31], fontSize: dayFont[31], color: dayColor[31]}}>{format(calendarDates[31], 'd')}</p> <p onClick={()=>AppointmentDate(32)} style={{fontWeight: dayFontWeight[32], fontSize: dayFont[32], color: dayColor[32]}}>{format(calendarDates[32], 'd')}</p> <p onClick={()=>AppointmentDate(33)} style={{fontWeight: dayFontWeight[33], fontSize: dayFont[33], color: dayColor[33]}}>{format(calendarDates[33], 'd')}</p> <p onClick={()=>AppointmentDate(34)} style={{fontWeight: dayFontWeight[34], fontSize: dayFont[34], color: dayColor[34]}}>{format(calendarDates[34], 'd')}</p></div>
                                        <div className='grid'><p onClick={()=>AppointmentDate(35)} style={{fontWeight: dayFontWeight[35], fontSize: dayFont[35], color: dayColor[35]}}>{format(calendarDates[35], 'd')}</p> <p onClick={()=>AppointmentDate(36)} style={{fontWeight: dayFontWeight[36], fontSize: dayFont[36], color: dayColor[36]}}>{format(calendarDates[36], 'd')}</p> <p onClick={()=>AppointmentDate(37)} style={{fontWeight: dayFontWeight[37], fontSize: dayFont[37], color: dayColor[37]}}>{format(calendarDates[37], 'd')}</p> <p onClick={()=>AppointmentDate(38)} style={{fontWeight: dayFontWeight[38], fontSize: dayFont[38], color: dayColor[38]}}>{format(calendarDates[38], 'd')}</p> <p onClick={()=>AppointmentDate(39)} style={{fontWeight: dayFontWeight[39], fontSize: dayFont[39], color: dayColor[39]}}>{format(calendarDates[39], 'd')}</p> <p onClick={()=>AppointmentDate(40)} style={{fontWeight: dayFontWeight[40], fontSize: dayFont[40], color: dayColor[40]}}>{format(calendarDates[40], 'd')}</p> <p onClick={()=>AppointmentDate(41)} style={{fontWeight: dayFontWeight[41], fontSize: dayFont[41], color: dayColor[41]}}>{format(calendarDates[41], 'd')}</p></div>
                                    </>)}
                                    {calendarDates.length==35 &&
                                        (<><div className='grid'><p>Su</p><p>Mo</p><p>Tu</p><p>We</p><p>Th</p><p>Fr</p><p>Sa</p></div>
                                        <div className='grid'><p onClick={()=>AppointmentDate(0)} style={{fontWeight: dayFontWeight[0], fontSize: dayFont[0], color: dayColor[0]}}>{format(calendarDates[0], 'd')}</p> <p onClick={()=>AppointmentDate(1)} style={{fontWeight: dayFontWeight[1], fontSize: dayFont[1], color: dayColor[1]}}>{format(calendarDates[1], 'd')}</p> <p onClick={()=>AppointmentDate(2)} style={{fontWeight: dayFontWeight[2], fontSize: dayFont[2], color: dayColor[2]}}>{format(calendarDates[2], 'd')}</p> <p onClick={()=>AppointmentDate(3)} style={{fontWeight: dayFontWeight[3], fontSize: dayFont[3], color: dayColor[3]}}>{format(calendarDates[3], 'd')}</p> <p onClick={()=>AppointmentDate(4)} style={{fontWeight: dayFontWeight[4], fontSize: dayFont[4], color: dayColor[4]}}>{format(calendarDates[4], 'd')}</p> <p onClick={()=>AppointmentDate(5)} style={{fontWeight: dayFontWeight[5], fontSize: dayFont[5], color: dayColor[5]}}>{format(calendarDates[5], 'd')}</p> <p onClick={()=>AppointmentDate(6)} style={{fontWeight: dayFontWeight[6], fontSize: dayFont[6], color: dayColor[6]}}>{format(calendarDates[6], 'd')}</p></div>
                                        <div className='grid'><p onClick={()=>AppointmentDate(7)} style={{fontWeight: dayFontWeight[7], fontSize: dayFont[7], color: dayColor[7]}}>{format(calendarDates[7], 'd')}</p> <p onClick={()=>AppointmentDate(8)} style={{fontWeight: dayFontWeight[8], fontSize: dayFont[8], color: dayColor[8]}}>{format(calendarDates[8], 'd')}</p> <p onClick={()=>AppointmentDate(9)} style={{fontWeight: dayFontWeight[9], fontSize: dayFont[9], color: dayColor[9]}}>{format(calendarDates[9], 'd')}</p> <p onClick={()=>AppointmentDate(10)} style={{fontWeight: dayFontWeight[10], fontSize: dayFont[10], color: dayColor[10]}}>{format(calendarDates[10], 'd')}</p> <p onClick={()=>AppointmentDate(11)} style={{fontWeight: dayFontWeight[11], fontSize: dayFont[11], color: dayColor[11]}}>{format(calendarDates[11], 'd')}</p> <p onClick={()=>AppointmentDate(12)} style={{fontWeight: dayFontWeight[12], fontSize: dayFont[12], color: dayColor[12]}}>{format(calendarDates[12], 'd')}</p> <p onClick={()=>AppointmentDate(13)} style={{fontWeight: dayFontWeight[13], fontSize: dayFont[13], color: dayColor[13]}}>{format(calendarDates[13], 'd')}</p> </div>
                                        <div className='grid'><p onClick={()=>AppointmentDate(14)} style={{fontWeight: dayFontWeight[14], fontSize: dayFont[14], color: dayColor[14]}}>{format(calendarDates[14], 'd')}</p> <p onClick={()=>AppointmentDate(15)} style={{fontWeight: dayFontWeight[15], fontSize: dayFont[15], color: dayColor[15]}}>{format(calendarDates[15], 'd')}</p> <p onClick={()=>AppointmentDate(16)} style={{fontWeight: dayFontWeight[16], fontSize: dayFont[16], color: dayColor[16]}}>{format(calendarDates[16], 'd')}</p> <p onClick={()=>AppointmentDate(17)} style={{fontWeight: dayFontWeight[17], fontSize: dayFont[17], color: dayColor[17]}}>{format(calendarDates[17], 'd')}</p> <p onClick={()=>AppointmentDate(18)} style={{fontWeight: dayFontWeight[18], fontSize: dayFont[18], color: dayColor[18]}}>{format(calendarDates[18], 'd')}</p> <p onClick={()=>AppointmentDate(19)} style={{fontWeight: dayFontWeight[19], fontSize: dayFont[19], color: dayColor[19]}}>{format(calendarDates[19], 'd')}</p> <p onClick={()=>AppointmentDate(20)} style={{fontWeight: dayFontWeight[20], fontSize: dayFont[20], color: dayColor[20]}}>{format(calendarDates[20], 'd')}</p></div>
                                        <div className='grid'><p onClick={()=>AppointmentDate(21)} style={{fontWeight: dayFontWeight[21], fontSize: dayFont[21], color: dayColor[21]}}>{format(calendarDates[21], 'd')}</p> <p onClick={()=>AppointmentDate(22)} style={{fontWeight: dayFontWeight[22], fontSize: dayFont[22], color: dayColor[22]}}>{format(calendarDates[22], 'd')}</p> <p onClick={()=>AppointmentDate(23)} style={{fontWeight: dayFontWeight[23], fontSize: dayFont[23], color: dayColor[23]}}>{format(calendarDates[23], 'd')}</p> <p onClick={()=>AppointmentDate(24)} style={{fontWeight: dayFontWeight[24], fontSize: dayFont[24], color: dayColor[24]}}>{format(calendarDates[24], 'd')}</p> <p onClick={()=>AppointmentDate(25)} style={{fontWeight: dayFontWeight[25], fontSize: dayFont[25], color: dayColor[25]}}>{format(calendarDates[25], 'd')}</p> <p onClick={()=>AppointmentDate(26)} style={{fontWeight: dayFontWeight[26], fontSize: dayFont[26], color: dayColor[26]}}>{format(calendarDates[26], 'd')}</p> <p onClick={()=>AppointmentDate(27)} style={{fontWeight: dayFontWeight[27], fontSize: dayFont[27], color: dayColor[27]}}>{format(calendarDates[27], 'd')}</p></div>
                                        <div className='grid'><p onClick={()=>AppointmentDate(28)} style={{fontWeight: dayFontWeight[28], fontSize: dayFont[28], color: dayColor[28]}}>{format(calendarDates[28], 'd')}</p> <p onClick={()=>AppointmentDate(29)} style={{fontWeight: dayFontWeight[29], fontSize: dayFont[29], color: dayColor[29]}}>{format(calendarDates[29], 'd')}</p> <p onClick={()=>AppointmentDate(30)} style={{fontWeight: dayFontWeight[30], fontSize: dayFont[30], color: dayColor[30]}}>{format(calendarDates[30], 'd')}</p> <p onClick={()=>AppointmentDate(31)} style={{fontWeight: dayFontWeight[31], fontSize: dayFont[31], color: dayColor[31]}}>{format(calendarDates[31], 'd')}</p> <p onClick={()=>AppointmentDate(32)} style={{fontWeight: dayFontWeight[32], fontSize: dayFont[32], color: dayColor[32]}}>{format(calendarDates[32], 'd')}</p> <p onClick={()=>AppointmentDate(33)} style={{fontWeight: dayFontWeight[33], fontSize: dayFont[33], color: dayColor[33]}}>{format(calendarDates[33], 'd')}</p> <p onClick={()=>AppointmentDate(34)} style={{fontWeight: dayFontWeight[34], fontSize: dayFont[34], color: dayColor[34]}}>{format(calendarDates[34], 'd')}</p></div>
                                    </>)}
                                </div>
                                <div className='slots-block pool-slots'>
                                    <div className='slot' onClick={()=>( Availability[1]==false || Resting[1]==true)?NullFunc:AppointmentSlot(1)} style={{opacity:(Slot1==1 || Availability[1]==false || Resting[1]==true)?0.5:1}}>
                                        <p className='slot-name'>Slot 1</p>
                                        <p className='slot-duration'>8:00-10:00</p>
                                        {Availability[1]==true && Resting[1]==false && (
                                            <p className='available-tag'>available</p>
                                        )}
                                        {(Availability[1]==false || Resting[1]==true) && (
                                            <p className='unavailable-tag'>unavailable</p>
                                        )}
                                        <div className='slot-filter'></div>
                                    </div>
                                    <div className='slot' onClick={()=>( Availability[2]==false || Resting[2]==true)?NullFunc:AppointmentSlot(2)} style={{opacity:(Slot1==2 || Availability[2]==false || Resting[2]==true)?0.5:1}}>
                                        <p className='slot-name'>Slot 2</p>
                                        <p className='slot-duration'>11:00-13:00</p>
                                        {Availability[2]==true && Resting[2]==false && (
                                            <p className='available-tag'>available</p>
                                        )}
                                        {(Availability[2]==false || Resting[2]==true) && (
                                            <p className='unavailable-tag'>unavailable</p>
                                        )}
                                        <div className='slot-filter'></div>
                                    </div>
                                    <div className='slot' onClick={()=>( Availability[3]==false || Resting[3]==true)?NullFunc:AppointmentSlot(3)} style={{opacity:(Slot1==3 || Availability[3]==false || Resting[3]==true)?0.5:1}}>
                                        <p className='slot-name'>Slot 3</p>
                                        <p className='slot-duration'>14:00-16:00</p>
                                        {Availability[3]==true && Resting[3]==false && (
                                            <p className='available-tag'>available</p>
                                        )}
                                        {(Availability[3]==false || Resting[3]==true) && (
                                            <p className='unavailable-tag'>unavailable</p>
                                        )}
                                        <div className='slot-filter'></div>
                                    </div>
                                </div>
                            </div>
                            <div className='main-disclaimer'><h3>Fixed fee of R250 for all basic pool cleaning services, which include: skimming, vaccuuming, brushing, emptying skimmer and pump baskets, checking and adjusting water chemistry, backwashing and cleaning the filter</h3></div>
                            <div className='card-label'><h1>Additional Services</h1></div>
                            <div className='pool-specs'>
                                
                                <div className='Spec'>
                                    <h4>Equipment Inspection ?</h4>
                                    {nbOn2 &&
                                        <p className='maxTime'>*Max Time: 1 hr</p>
                                    }
                                    <div className='option'>
                                        <div onClick={()=>Inspection(true)} style={{border:border11 ,backgroundColor:filler11 }}></div>
                                        <p>Yes</p>
                                        <p>R20</p>
                                    </div>
                                    <div className='option'>
                                        <div onClick={()=>Inspection(false)} style={{border:border12 ,backgroundColor:filler12 }}></div>
                                        <p>No</p>
                                        <p>-</p>
                                    </div>
                                </div>
                                <div className='Spec'>
                                    <h4>Tile Cleaning ?</h4>
                                    {nbOn2 &&
                                        <p className='maxTime'>*Max Time: 1 hr</p>
                                    }
                                    <div className='option'>
                                        <div onClick={()=>Tile(true)} style={{border:border13 ,backgroundColor:filler13 }}></div>
                                        <p>Yes</p>
                                        <p>R30</p>
                                    </div>
                                    <div className='option'>
                                        <div onClick={()=>Tile(false)} style={{border:border14 ,backgroundColor:filler14 }}></div>
                                        <p>No</p>
                                        <p>-</p>
                                    </div>
                                </div>
                                <div className='Spec'>
                                    <h4>Pool Deck Cleaning ?</h4>
                                    {nbOn2 &&
                                        <p className='maxTime'>*Max Time: 1 hr</p>
                                    }
                                    <div className='option'>
                                        <div onClick={()=>Deck(true)} style={{border:border15 ,backgroundColor:filler15 }}></div>
                                        <p>Yes</p>
                                        <p>R20</p>
                                    </div>
                                    <div className='option'>
                                        <div onClick={()=>Deck(false)} style={{border:border16 ,backgroundColor:filler16 }}></div>
                                        <p>No</p>
                                        <p>-</p>
                                    </div>
                                </div>
                            </div>
                            <div className='submit-block' onClick={SubmitPool}>
                                <div><h3>Submit Booking</h3>
                                    <h3>R{price}</h3>
                                </div>
                            </div>
                        </div>
                    )}
                    {serviceType == 'Gardener' &&
                        (<div className='booking-body4'>
                            <p className='close-booking' onClick={()=>setBooking(null)}>&#10006;</p>
                            <h2 className='booking-title'>{serviceType}</h2>
                            <div className='main-disclaimer5'><h3>Select available appointment date and slot</h3></div>
                            <div className='booking-calender fixed-calendar'>
                                <div className='Month-selector'>
                                    <p>{monthsArray[Month]}{' '}{Year}</p>
                                    <img className='month-above' src='/Images/up.png' onClick={MonthUp}></img>
                                    <img className='month-below' src='/Images/down.png' onClick={MonthDown}></img>
                                </div>
                                <div className='Day-selector'>
                                    {calendarDates.length==42 &&
                                        (<><div className='grid'><p>Su</p><p>Mo</p><p>Tu</p><p>We</p><p>Th</p><p>Fr</p><p>Sa</p></div>
                                        <div className='grid'><p onClick={()=>AppointmentDate(0)} style={{fontWeight: dayFontWeight[0], fontSize: dayFont[0], color: dayColor[0]}}>{format(calendarDates[0], 'd')}</p> <p onClick={()=>AppointmentDate(1)} style={{fontWeight: dayFontWeight[1], fontSize: dayFont[1], color: dayColor[1]}}>{format(calendarDates[1], 'd')}</p> <p onClick={()=>AppointmentDate(2)} style={{fontWeight: dayFontWeight[2], fontSize: dayFont[2], color: dayColor[2]}}>{format(calendarDates[2], 'd')}</p> <p onClick={()=>AppointmentDate(3)} style={{fontWeight: dayFontWeight[3], fontSize: dayFont[3], color: dayColor[3]}}>{format(calendarDates[3], 'd')}</p> <p onClick={()=>AppointmentDate(4)} style={{fontWeight: dayFontWeight[4], fontSize: dayFont[4], color: dayColor[4]}}>{format(calendarDates[4], 'd')}</p> <p onClick={()=>AppointmentDate(5)} style={{fontWeight: dayFontWeight[5], fontSize: dayFont[5], color: dayColor[5]}}>{format(calendarDates[5], 'd')}</p> <p onClick={()=>AppointmentDate(6)} style={{fontWeight: dayFontWeight[6], fontSize: dayFont[6], color: dayColor[6]}}>{format(calendarDates[6], 'd')}</p></div>
                                        <div className='grid'><p onClick={()=>AppointmentDate(7)} style={{fontWeight: dayFontWeight[7], fontSize: dayFont[7], color: dayColor[7]}}>{format(calendarDates[7], 'd')}</p> <p onClick={()=>AppointmentDate(8)} style={{fontWeight: dayFontWeight[8], fontSize: dayFont[8], color: dayColor[8]}}>{format(calendarDates[8], 'd')}</p> <p onClick={()=>AppointmentDate(9)} style={{fontWeight: dayFontWeight[9], fontSize: dayFont[9], color: dayColor[9]}}>{format(calendarDates[9], 'd')}</p> <p onClick={()=>AppointmentDate(10)} style={{fontWeight: dayFontWeight[10], fontSize: dayFont[10], color: dayColor[10]}}>{format(calendarDates[10], 'd')}</p> <p onClick={()=>AppointmentDate(11)} style={{fontWeight: dayFontWeight[11], fontSize: dayFont[11], color: dayColor[11]}}>{format(calendarDates[11], 'd')}</p> <p onClick={()=>AppointmentDate(12)} style={{fontWeight: dayFontWeight[12], fontSize: dayFont[12], color: dayColor[12]}}>{format(calendarDates[12], 'd')}</p> <p onClick={()=>AppointmentDate(13)} style={{fontWeight: dayFontWeight[13], fontSize: dayFont[13], color: dayColor[13]}}>{format(calendarDates[13], 'd')}</p> </div>
                                        <div className='grid'><p onClick={()=>AppointmentDate(14)} style={{fontWeight: dayFontWeight[14], fontSize: dayFont[14], color: dayColor[14]}}>{format(calendarDates[14], 'd')}</p> <p onClick={()=>AppointmentDate(15)} style={{fontWeight: dayFontWeight[15], fontSize: dayFont[15], color: dayColor[15]}}>{format(calendarDates[15], 'd')}</p> <p onClick={()=>AppointmentDate(16)} style={{fontWeight: dayFontWeight[16], fontSize: dayFont[16], color: dayColor[16]}}>{format(calendarDates[16], 'd')}</p> <p onClick={()=>AppointmentDate(17)} style={{fontWeight: dayFontWeight[17], fontSize: dayFont[17], color: dayColor[17]}}>{format(calendarDates[17], 'd')}</p> <p onClick={()=>AppointmentDate(18)} style={{fontWeight: dayFontWeight[18], fontSize: dayFont[18], color: dayColor[18]}}>{format(calendarDates[18], 'd')}</p> <p onClick={()=>AppointmentDate(19)} style={{fontWeight: dayFontWeight[19], fontSize: dayFont[19], color: dayColor[19]}}>{format(calendarDates[19], 'd')}</p> <p onClick={()=>AppointmentDate(20)} style={{fontWeight: dayFontWeight[20], fontSize: dayFont[20], color: dayColor[20]}}>{format(calendarDates[20], 'd')}</p></div>
                                        <div className='grid'><p onClick={()=>AppointmentDate(21)} style={{fontWeight: dayFontWeight[21], fontSize: dayFont[21], color: dayColor[21]}}>{format(calendarDates[21], 'd')}</p> <p onClick={()=>AppointmentDate(22)} style={{fontWeight: dayFontWeight[22], fontSize: dayFont[22], color: dayColor[22]}}>{format(calendarDates[22], 'd')}</p> <p onClick={()=>AppointmentDate(23)} style={{fontWeight: dayFontWeight[23], fontSize: dayFont[23], color: dayColor[23]}}>{format(calendarDates[23], 'd')}</p> <p onClick={()=>AppointmentDate(24)} style={{fontWeight: dayFontWeight[24], fontSize: dayFont[24], color: dayColor[24]}}>{format(calendarDates[24], 'd')}</p> <p onClick={()=>AppointmentDate(25)} style={{fontWeight: dayFontWeight[25], fontSize: dayFont[25], color: dayColor[25]}}>{format(calendarDates[25], 'd')}</p> <p onClick={()=>AppointmentDate(26)} style={{fontWeight: dayFontWeight[26], fontSize: dayFont[26], color: dayColor[26]}}>{format(calendarDates[26], 'd')}</p> <p onClick={()=>AppointmentDate(27)} style={{fontWeight: dayFontWeight[27], fontSize: dayFont[27], color: dayColor[27]}}>{format(calendarDates[27], 'd')}</p></div>
                                        <div className='grid'><p onClick={()=>AppointmentDate(28)} style={{fontWeight: dayFontWeight[28], fontSize: dayFont[28], color: dayColor[28]}}>{format(calendarDates[28], 'd')}</p> <p onClick={()=>AppointmentDate(29)} style={{fontWeight: dayFontWeight[29], fontSize: dayFont[29], color: dayColor[29]}}>{format(calendarDates[29], 'd')}</p> <p onClick={()=>AppointmentDate(30)} style={{fontWeight: dayFontWeight[30], fontSize: dayFont[30], color: dayColor[30]}}>{format(calendarDates[30], 'd')}</p> <p onClick={()=>AppointmentDate(31)} style={{fontWeight: dayFontWeight[31], fontSize: dayFont[31], color: dayColor[31]}}>{format(calendarDates[31], 'd')}</p> <p onClick={()=>AppointmentDate(32)} style={{fontWeight: dayFontWeight[32], fontSize: dayFont[32], color: dayColor[32]}}>{format(calendarDates[32], 'd')}</p> <p onClick={()=>AppointmentDate(33)} style={{fontWeight: dayFontWeight[33], fontSize: dayFont[33], color: dayColor[33]}}>{format(calendarDates[33], 'd')}</p> <p onClick={()=>AppointmentDate(34)} style={{fontWeight: dayFontWeight[34], fontSize: dayFont[34], color: dayColor[34]}}>{format(calendarDates[34], 'd')}</p></div>
                                        <div className='grid'><p onClick={()=>AppointmentDate(35)} style={{fontWeight: dayFontWeight[35], fontSize: dayFont[35], color: dayColor[35]}}>{format(calendarDates[35], 'd')}</p> <p onClick={()=>AppointmentDate(36)} style={{fontWeight: dayFontWeight[36], fontSize: dayFont[36], color: dayColor[36]}}>{format(calendarDates[36], 'd')}</p> <p onClick={()=>AppointmentDate(37)} style={{fontWeight: dayFontWeight[37], fontSize: dayFont[37], color: dayColor[37]}}>{format(calendarDates[37], 'd')}</p> <p onClick={()=>AppointmentDate(38)} style={{fontWeight: dayFontWeight[38], fontSize: dayFont[38], color: dayColor[38]}}>{format(calendarDates[38], 'd')}</p> <p onClick={()=>AppointmentDate(39)} style={{fontWeight: dayFontWeight[39], fontSize: dayFont[39], color: dayColor[39]}}>{format(calendarDates[39], 'd')}</p> <p onClick={()=>AppointmentDate(40)} style={{fontWeight: dayFontWeight[40], fontSize: dayFont[40], color: dayColor[40]}}>{format(calendarDates[40], 'd')}</p> <p onClick={()=>AppointmentDate(41)} style={{fontWeight: dayFontWeight[41], fontSize: dayFont[41], color: dayColor[41]}}>{format(calendarDates[41], 'd')}</p></div>
                                    </>)}
                                    {calendarDates.length==35 &&
                                        (<><div className='grid'><p>Su</p><p>Mo</p><p>Tu</p><p>We</p><p>Th</p><p>Fr</p><p>Sa</p></div>
                                        <div className='grid'><p onClick={()=>AppointmentDate(0)} style={{fontWeight: dayFontWeight[0], fontSize: dayFont[0], color: dayColor[0]}}>{format(calendarDates[0], 'd')}</p> <p onClick={()=>AppointmentDate(1)} style={{fontWeight: dayFontWeight[1], fontSize: dayFont[1], color: dayColor[1]}}>{format(calendarDates[1], 'd')}</p> <p onClick={()=>AppointmentDate(2)} style={{fontWeight: dayFontWeight[2], fontSize: dayFont[2], color: dayColor[2]}}>{format(calendarDates[2], 'd')}</p> <p onClick={()=>AppointmentDate(3)} style={{fontWeight: dayFontWeight[3], fontSize: dayFont[3], color: dayColor[3]}}>{format(calendarDates[3], 'd')}</p> <p onClick={()=>AppointmentDate(4)} style={{fontWeight: dayFontWeight[4], fontSize: dayFont[4], color: dayColor[4]}}>{format(calendarDates[4], 'd')}</p> <p onClick={()=>AppointmentDate(5)} style={{fontWeight: dayFontWeight[5], fontSize: dayFont[5], color: dayColor[5]}}>{format(calendarDates[5], 'd')}</p> <p onClick={()=>AppointmentDate(6)} style={{fontWeight: dayFontWeight[6], fontSize: dayFont[6], color: dayColor[6]}}>{format(calendarDates[6], 'd')}</p></div>
                                        <div className='grid'><p onClick={()=>AppointmentDate(7)} style={{fontWeight: dayFontWeight[7], fontSize: dayFont[7], color: dayColor[7]}}>{format(calendarDates[7], 'd')}</p> <p onClick={()=>AppointmentDate(8)} style={{fontWeight: dayFontWeight[8], fontSize: dayFont[8], color: dayColor[8]}}>{format(calendarDates[8], 'd')}</p> <p onClick={()=>AppointmentDate(9)} style={{fontWeight: dayFontWeight[9], fontSize: dayFont[9], color: dayColor[9]}}>{format(calendarDates[9], 'd')}</p> <p onClick={()=>AppointmentDate(10)} style={{fontWeight: dayFontWeight[10], fontSize: dayFont[10], color: dayColor[10]}}>{format(calendarDates[10], 'd')}</p> <p onClick={()=>AppointmentDate(11)} style={{fontWeight: dayFontWeight[11], fontSize: dayFont[11], color: dayColor[11]}}>{format(calendarDates[11], 'd')}</p> <p onClick={()=>AppointmentDate(12)} style={{fontWeight: dayFontWeight[12], fontSize: dayFont[12], color: dayColor[12]}}>{format(calendarDates[12], 'd')}</p> <p onClick={()=>AppointmentDate(13)} style={{fontWeight: dayFontWeight[13], fontSize: dayFont[13], color: dayColor[13]}}>{format(calendarDates[13], 'd')}</p> </div>
                                        <div className='grid'><p onClick={()=>AppointmentDate(14)} style={{fontWeight: dayFontWeight[14], fontSize: dayFont[14], color: dayColor[14]}}>{format(calendarDates[14], 'd')}</p> <p onClick={()=>AppointmentDate(15)} style={{fontWeight: dayFontWeight[15], fontSize: dayFont[15], color: dayColor[15]}}>{format(calendarDates[15], 'd')}</p> <p onClick={()=>AppointmentDate(16)} style={{fontWeight: dayFontWeight[16], fontSize: dayFont[16], color: dayColor[16]}}>{format(calendarDates[16], 'd')}</p> <p onClick={()=>AppointmentDate(17)} style={{fontWeight: dayFontWeight[17], fontSize: dayFont[17], color: dayColor[17]}}>{format(calendarDates[17], 'd')}</p> <p onClick={()=>AppointmentDate(18)} style={{fontWeight: dayFontWeight[18], fontSize: dayFont[18], color: dayColor[18]}}>{format(calendarDates[18], 'd')}</p> <p onClick={()=>AppointmentDate(19)} style={{fontWeight: dayFontWeight[19], fontSize: dayFont[19], color: dayColor[19]}}>{format(calendarDates[19], 'd')}</p> <p onClick={()=>AppointmentDate(20)} style={{fontWeight: dayFontWeight[20], fontSize: dayFont[20], color: dayColor[20]}}>{format(calendarDates[20], 'd')}</p></div>
                                        <div className='grid'><p onClick={()=>AppointmentDate(21)} style={{fontWeight: dayFontWeight[21], fontSize: dayFont[21], color: dayColor[21]}}>{format(calendarDates[21], 'd')}</p> <p onClick={()=>AppointmentDate(22)} style={{fontWeight: dayFontWeight[22], fontSize: dayFont[22], color: dayColor[22]}}>{format(calendarDates[22], 'd')}</p> <p onClick={()=>AppointmentDate(23)} style={{fontWeight: dayFontWeight[23], fontSize: dayFont[23], color: dayColor[23]}}>{format(calendarDates[23], 'd')}</p> <p onClick={()=>AppointmentDate(24)} style={{fontWeight: dayFontWeight[24], fontSize: dayFont[24], color: dayColor[24]}}>{format(calendarDates[24], 'd')}</p> <p onClick={()=>AppointmentDate(25)} style={{fontWeight: dayFontWeight[25], fontSize: dayFont[25], color: dayColor[25]}}>{format(calendarDates[25], 'd')}</p> <p onClick={()=>AppointmentDate(26)} style={{fontWeight: dayFontWeight[26], fontSize: dayFont[26], color: dayColor[26]}}>{format(calendarDates[26], 'd')}</p> <p onClick={()=>AppointmentDate(27)} style={{fontWeight: dayFontWeight[27], fontSize: dayFont[27], color: dayColor[27]}}>{format(calendarDates[27], 'd')}</p></div>
                                        <div className='grid'><p onClick={()=>AppointmentDate(28)} style={{fontWeight: dayFontWeight[28], fontSize: dayFont[28], color: dayColor[28]}}>{format(calendarDates[28], 'd')}</p> <p onClick={()=>AppointmentDate(29)} style={{fontWeight: dayFontWeight[29], fontSize: dayFont[29], color: dayColor[29]}}>{format(calendarDates[29], 'd')}</p> <p onClick={()=>AppointmentDate(30)} style={{fontWeight: dayFontWeight[30], fontSize: dayFont[30], color: dayColor[30]}}>{format(calendarDates[30], 'd')}</p> <p onClick={()=>AppointmentDate(31)} style={{fontWeight: dayFontWeight[31], fontSize: dayFont[31], color: dayColor[31]}}>{format(calendarDates[31], 'd')}</p> <p onClick={()=>AppointmentDate(32)} style={{fontWeight: dayFontWeight[32], fontSize: dayFont[32], color: dayColor[32]}}>{format(calendarDates[32], 'd')}</p> <p onClick={()=>AppointmentDate(33)} style={{fontWeight: dayFontWeight[33], fontSize: dayFont[33], color: dayColor[33]}}>{format(calendarDates[33], 'd')}</p> <p onClick={()=>AppointmentDate(34)} style={{fontWeight: dayFontWeight[34], fontSize: dayFont[34], color: dayColor[34]}}>{format(calendarDates[34], 'd')}</p></div>
                                    </>)}
                                </div>
                                <div className='slots-block garden-slots'>
                                    <div className='slot' onClick={()=>( Availability[1]==false || Resting[1]==true)?NullFunc:AppointmentSlot(1)} style={{opacity:(Slot1==1 || Availability[1]==false || Resting[1]==true)?0.5:1}}>
                                        <p className='slot-name'>Slot 1</p>
                                        <p className='slot-duration'>7:00-11:00</p>
                                        {Availability[1]==true && Resting[1]==false && (
                                            <p className='available-tag'>available</p>
                                        )}
                                        {(Availability[1]==false || Resting[1]==true) && (
                                            <p className='unavailable-tag'>unavailable</p>
                                        )}
                                        <div className='slot-filter'></div>
                                    </div>
                                    <div className='slot' onClick={()=>( Availability[2]==false || Resting[2]==true)?NullFunc:AppointmentSlot(2)} style={{opacity:(Slot1==2 || Availability[2]==false || Resting[2]==true)?0.5:1}}>
                                        <p className='slot-name'>Slot 2</p>
                                        <p className='slot-duration'>12:00-16:00</p>
                                        {Availability[2]==true && Resting[2]==false && (
                                            <p className='available-tag'>available</p>
                                        )}
                                        {(Availability[2]==false || Resting[2]==true) && (
                                            <p className='unavailable-tag'>unavailable</p>
                                        )}
                                        <div className='slot-filter'></div>
                                    </div>
                                </div>
                            </div>
                            <div className='main-disclaimer4'><h3>Fixed fee of R280 for all basic gardening services, which includes: planting, watering, weeding, pruning, trimming and lawn care</h3></div>
                            <div className='card-label'><h1>Additional Services</h1></div>
                            <div className='garden-specs'>
                                <div className='Spec'>
                                    <h4>Irrigation System Inspection ?</h4>
                                    
                                    {!nbOn2 &&
                                        <p className='disclaimer-4' onClick={()=>setNO2(!nbOn2)}>*NB</p>
                                    }
                                    {nbOn2 &&
                                        <p className='disclaimer-4' onClick={()=>setNO2(!nbOn2)}>*</p>
                                    }
                                    <div className='option'>
                                        <div onClick={()=>Irrigation(true)} style={{border:border17 ,backgroundColor:filler17 }}></div>
                                        <p>Yes</p>
                                        <p>R20</p>
                                    </div>
                                    <div className='option'>
                                        <div onClick={()=>Irrigation(false)} style={{border:border18 ,backgroundColor:filler18 }}></div>
                                        <p>No</p>
                                        <p>-</p>
                                    </div>
                                </div>
                                <div className='Spec'>
                                    <h4>Fertilizing ?</h4>
                                    {nbOn2 &&
                                        <p className='disclaimer-4'>*Application only, purchase your own</p>
                                    }
                                    <div className='option'>
                                        <div onClick={()=>Fertilize(true)} style={{border:border19 ,backgroundColor:filler19 }}></div>
                                        <p>Yes</p>
                                        <p>R20</p>
                                    </div>
                                    <div className='option'>
                                        <div onClick={()=>Fertilize(false)} style={{border:border20 ,backgroundColor:filler20 }}></div>
                                        <p>No</p>
                                        <p>-</p>
                                    </div>
                                </div>
                                <div className='Spec'>
                                    <h4>Pest and Disease Control ?</h4>
                                    {nbOn2 &&
                                        <p className='disclaimer-4'>*Application only, purchase your own</p>
                                    }
                                    <div className='option'>
                                        <div onClick={()=>Pest(true)} style={{border:border21 ,backgroundColor:filler21 }}></div>
                                        <p>Yes</p>
                                        <p>R20</p>
                                    </div>
                                    <div className='option'>
                                        <div onClick={()=>Pest(false)} style={{border:border22 ,backgroundColor:filler22 }}></div>
                                        <p>No</p>
                                        <p>-</p>
                                    </div>
                                </div>
                            </div>
                            <div className='submit-block' onClick={SubmitGarden}>
                                <div><h3>Submit Booking</h3>
                                    <h3>R{price}</h3>
                                </div>
                            </div>
                        </div>
                    )}
                    {serviceType != 'Car Cleaner' && serviceType != 'House Cleaner' && serviceType != 'Pool Cleaner' && serviceType != 'Gardener' &&
                        (<div className='booking-body5'>
                            <p className='close-booking' onClick={()=>setBooking(null)}>&#10006;</p>
                            <h2 className='booking-title'>{serviceType}</h2>
                            <div className='main-disclaimer5'><h3>Select available appointment period</h3></div>
                            <div className='booking-calender'>
                                <div className='Month-selector'>
                                    <p>{monthsArray[Month]}{' '}{Year}</p>
                                    <img className='month-above' src='/Images/up.png' onClick={MonthUp}></img>
                                    <img className='month-below' src='/Images/down.png' onClick={MonthDown}></img>
                                </div>
                                <div className='Day-selector'>
                                    {calendarDates.length==42 &&
                                        (<><div className='grid'><p>Su</p><p>Mo</p><p>Tu</p><p>We</p><p>Th</p><p>Fr</p><p>Sa</p></div>
                                        <div className='grid'><p onClick={()=>AppointmentDate(0)} style={{fontWeight: dayFontWeight[0], fontSize: dayFont[0], color: dayColor[0]}}>{format(calendarDates[0], 'd')}</p> <p onClick={()=>AppointmentDate(1)} style={{fontWeight: dayFontWeight[1], fontSize: dayFont[1], color: dayColor[1]}}>{format(calendarDates[1], 'd')}</p> <p onClick={()=>AppointmentDate(2)} style={{fontWeight: dayFontWeight[2], fontSize: dayFont[2], color: dayColor[2]}}>{format(calendarDates[2], 'd')}</p> <p onClick={()=>AppointmentDate(3)} style={{fontWeight: dayFontWeight[3], fontSize: dayFont[3], color: dayColor[3]}}>{format(calendarDates[3], 'd')}</p> <p onClick={()=>AppointmentDate(4)} style={{fontWeight: dayFontWeight[4], fontSize: dayFont[4], color: dayColor[4]}}>{format(calendarDates[4], 'd')}</p> <p onClick={()=>AppointmentDate(5)} style={{fontWeight: dayFontWeight[5], fontSize: dayFont[5], color: dayColor[5]}}>{format(calendarDates[5], 'd')}</p> <p onClick={()=>AppointmentDate(6)} style={{fontWeight: dayFontWeight[6], fontSize: dayFont[6], color: dayColor[6]}}>{format(calendarDates[6], 'd')}</p></div>
                                        <div className='grid'><p onClick={()=>AppointmentDate(7)} style={{fontWeight: dayFontWeight[7], fontSize: dayFont[7], color: dayColor[7]}}>{format(calendarDates[7], 'd')}</p> <p onClick={()=>AppointmentDate(8)} style={{fontWeight: dayFontWeight[8], fontSize: dayFont[8], color: dayColor[8]}}>{format(calendarDates[8], 'd')}</p> <p onClick={()=>AppointmentDate(9)} style={{fontWeight: dayFontWeight[9], fontSize: dayFont[9], color: dayColor[9]}}>{format(calendarDates[9], 'd')}</p> <p onClick={()=>AppointmentDate(10)} style={{fontWeight: dayFontWeight[10], fontSize: dayFont[10], color: dayColor[10]}}>{format(calendarDates[10], 'd')}</p> <p onClick={()=>AppointmentDate(11)} style={{fontWeight: dayFontWeight[11], fontSize: dayFont[11], color: dayColor[11]}}>{format(calendarDates[11], 'd')}</p> <p onClick={()=>AppointmentDate(12)} style={{fontWeight: dayFontWeight[12], fontSize: dayFont[12], color: dayColor[12]}}>{format(calendarDates[12], 'd')}</p> <p onClick={()=>AppointmentDate(13)} style={{fontWeight: dayFontWeight[13], fontSize: dayFont[13], color: dayColor[13]}}>{format(calendarDates[13], 'd')}</p> </div>
                                        <div className='grid'><p onClick={()=>AppointmentDate(14)} style={{fontWeight: dayFontWeight[14], fontSize: dayFont[14], color: dayColor[14]}}>{format(calendarDates[14], 'd')}</p> <p onClick={()=>AppointmentDate(15)} style={{fontWeight: dayFontWeight[15], fontSize: dayFont[15], color: dayColor[15]}}>{format(calendarDates[15], 'd')}</p> <p onClick={()=>AppointmentDate(16)} style={{fontWeight: dayFontWeight[16], fontSize: dayFont[16], color: dayColor[16]}}>{format(calendarDates[16], 'd')}</p> <p onClick={()=>AppointmentDate(17)} style={{fontWeight: dayFontWeight[17], fontSize: dayFont[17], color: dayColor[17]}}>{format(calendarDates[17], 'd')}</p> <p onClick={()=>AppointmentDate(18)} style={{fontWeight: dayFontWeight[18], fontSize: dayFont[18], color: dayColor[18]}}>{format(calendarDates[18], 'd')}</p> <p onClick={()=>AppointmentDate(19)} style={{fontWeight: dayFontWeight[19], fontSize: dayFont[19], color: dayColor[19]}}>{format(calendarDates[19], 'd')}</p> <p onClick={()=>AppointmentDate(20)} style={{fontWeight: dayFontWeight[20], fontSize: dayFont[20], color: dayColor[20]}}>{format(calendarDates[20], 'd')}</p></div>
                                        <div className='grid'><p onClick={()=>AppointmentDate(21)} style={{fontWeight: dayFontWeight[21], fontSize: dayFont[21], color: dayColor[21]}}>{format(calendarDates[21], 'd')}</p> <p onClick={()=>AppointmentDate(22)} style={{fontWeight: dayFontWeight[22], fontSize: dayFont[22], color: dayColor[22]}}>{format(calendarDates[22], 'd')}</p> <p onClick={()=>AppointmentDate(23)} style={{fontWeight: dayFontWeight[23], fontSize: dayFont[23], color: dayColor[23]}}>{format(calendarDates[23], 'd')}</p> <p onClick={()=>AppointmentDate(24)} style={{fontWeight: dayFontWeight[24], fontSize: dayFont[24], color: dayColor[24]}}>{format(calendarDates[24], 'd')}</p> <p onClick={()=>AppointmentDate(25)} style={{fontWeight: dayFontWeight[25], fontSize: dayFont[25], color: dayColor[25]}}>{format(calendarDates[25], 'd')}</p> <p onClick={()=>AppointmentDate(26)} style={{fontWeight: dayFontWeight[26], fontSize: dayFont[26], color: dayColor[26]}}>{format(calendarDates[26], 'd')}</p> <p onClick={()=>AppointmentDate(27)} style={{fontWeight: dayFontWeight[27], fontSize: dayFont[27], color: dayColor[27]}}>{format(calendarDates[27], 'd')}</p></div>
                                        <div className='grid'><p onClick={()=>AppointmentDate(28)} style={{fontWeight: dayFontWeight[28], fontSize: dayFont[28], color: dayColor[28]}}>{format(calendarDates[28], 'd')}</p> <p onClick={()=>AppointmentDate(29)} style={{fontWeight: dayFontWeight[29], fontSize: dayFont[29], color: dayColor[29]}}>{format(calendarDates[29], 'd')}</p> <p onClick={()=>AppointmentDate(30)} style={{fontWeight: dayFontWeight[30], fontSize: dayFont[30], color: dayColor[30]}}>{format(calendarDates[30], 'd')}</p> <p onClick={()=>AppointmentDate(31)} style={{fontWeight: dayFontWeight[31], fontSize: dayFont[31], color: dayColor[31]}}>{format(calendarDates[31], 'd')}</p> <p onClick={()=>AppointmentDate(32)} style={{fontWeight: dayFontWeight[32], fontSize: dayFont[32], color: dayColor[32]}}>{format(calendarDates[32], 'd')}</p> <p onClick={()=>AppointmentDate(33)} style={{fontWeight: dayFontWeight[33], fontSize: dayFont[33], color: dayColor[33]}}>{format(calendarDates[33], 'd')}</p> <p onClick={()=>AppointmentDate(34)} style={{fontWeight: dayFontWeight[34], fontSize: dayFont[34], color: dayColor[34]}}>{format(calendarDates[34], 'd')}</p></div>
                                        <div className='grid'><p onClick={()=>AppointmentDate(35)} style={{fontWeight: dayFontWeight[35], fontSize: dayFont[35], color: dayColor[35]}}>{format(calendarDates[35], 'd')}</p> <p onClick={()=>AppointmentDate(36)} style={{fontWeight: dayFontWeight[36], fontSize: dayFont[36], color: dayColor[36]}}>{format(calendarDates[36], 'd')}</p> <p onClick={()=>AppointmentDate(37)} style={{fontWeight: dayFontWeight[37], fontSize: dayFont[37], color: dayColor[37]}}>{format(calendarDates[37], 'd')}</p> <p onClick={()=>AppointmentDate(38)} style={{fontWeight: dayFontWeight[38], fontSize: dayFont[38], color: dayColor[38]}}>{format(calendarDates[38], 'd')}</p> <p onClick={()=>AppointmentDate(39)} style={{fontWeight: dayFontWeight[39], fontSize: dayFont[39], color: dayColor[39]}}>{format(calendarDates[39], 'd')}</p> <p onClick={()=>AppointmentDate(40)} style={{fontWeight: dayFontWeight[40], fontSize: dayFont[40], color: dayColor[40]}}>{format(calendarDates[40], 'd')}</p> <p onClick={()=>AppointmentDate(41)} style={{fontWeight: dayFontWeight[41], fontSize: dayFont[41], color: dayColor[41]}}>{format(calendarDates[41], 'd')}</p></div>
                                    </>)}
                                    {calendarDates.length==35 &&
                                        (<><div className='grid'><p>Su</p><p>Mo</p><p>Tu</p><p>We</p><p>Th</p><p>Fr</p><p>Sa</p></div>
                                        <div className='grid'><p onClick={()=>AppointmentDate(0)} style={{fontWeight: dayFontWeight[0], fontSize: dayFont[0], color: dayColor[0]}}>{format(calendarDates[0], 'd')}</p> <p onClick={()=>AppointmentDate(1)} style={{fontWeight: dayFontWeight[1], fontSize: dayFont[1], color: dayColor[1]}}>{format(calendarDates[1], 'd')}</p> <p onClick={()=>AppointmentDate(2)} style={{fontWeight: dayFontWeight[2], fontSize: dayFont[2], color: dayColor[2]}}>{format(calendarDates[2], 'd')}</p> <p onClick={()=>AppointmentDate(3)} style={{fontWeight: dayFontWeight[3], fontSize: dayFont[3], color: dayColor[3]}}>{format(calendarDates[3], 'd')}</p> <p onClick={()=>AppointmentDate(4)} style={{fontWeight: dayFontWeight[4], fontSize: dayFont[4], color: dayColor[4]}}>{format(calendarDates[4], 'd')}</p> <p onClick={()=>AppointmentDate(5)} style={{fontWeight: dayFontWeight[5], fontSize: dayFont[5], color: dayColor[5]}}>{format(calendarDates[5], 'd')}</p> <p onClick={()=>AppointmentDate(6)} style={{fontWeight: dayFontWeight[6], fontSize: dayFont[6], color: dayColor[6]}}>{format(calendarDates[6], 'd')}</p></div>
                                        <div className='grid'><p onClick={()=>AppointmentDate(7)} style={{fontWeight: dayFontWeight[7], fontSize: dayFont[7], color: dayColor[7]}}>{format(calendarDates[7], 'd')}</p> <p onClick={()=>AppointmentDate(8)} style={{fontWeight: dayFontWeight[8], fontSize: dayFont[8], color: dayColor[8]}}>{format(calendarDates[8], 'd')}</p> <p onClick={()=>AppointmentDate(9)} style={{fontWeight: dayFontWeight[9], fontSize: dayFont[9], color: dayColor[9]}}>{format(calendarDates[9], 'd')}</p> <p onClick={()=>AppointmentDate(10)} style={{fontWeight: dayFontWeight[10], fontSize: dayFont[10], color: dayColor[10]}}>{format(calendarDates[10], 'd')}</p> <p onClick={()=>AppointmentDate(11)} style={{fontWeight: dayFontWeight[11], fontSize: dayFont[11], color: dayColor[11]}}>{format(calendarDates[11], 'd')}</p> <p onClick={()=>AppointmentDate(12)} style={{fontWeight: dayFontWeight[12], fontSize: dayFont[12], color: dayColor[12]}}>{format(calendarDates[12], 'd')}</p> <p onClick={()=>AppointmentDate(13)} style={{fontWeight: dayFontWeight[13], fontSize: dayFont[13], color: dayColor[13]}}>{format(calendarDates[13], 'd')}</p> </div>
                                        <div className='grid'><p onClick={()=>AppointmentDate(14)} style={{fontWeight: dayFontWeight[14], fontSize: dayFont[14], color: dayColor[14]}}>{format(calendarDates[14], 'd')}</p> <p onClick={()=>AppointmentDate(15)} style={{fontWeight: dayFontWeight[15], fontSize: dayFont[15], color: dayColor[15]}}>{format(calendarDates[15], 'd')}</p> <p onClick={()=>AppointmentDate(16)} style={{fontWeight: dayFontWeight[16], fontSize: dayFont[16], color: dayColor[16]}}>{format(calendarDates[16], 'd')}</p> <p onClick={()=>AppointmentDate(17)} style={{fontWeight: dayFontWeight[17], fontSize: dayFont[17], color: dayColor[17]}}>{format(calendarDates[17], 'd')}</p> <p onClick={()=>AppointmentDate(18)} style={{fontWeight: dayFontWeight[18], fontSize: dayFont[18], color: dayColor[18]}}>{format(calendarDates[18], 'd')}</p> <p onClick={()=>AppointmentDate(19)} style={{fontWeight: dayFontWeight[19], fontSize: dayFont[19], color: dayColor[19]}}>{format(calendarDates[19], 'd')}</p> <p onClick={()=>AppointmentDate(20)} style={{fontWeight: dayFontWeight[20], fontSize: dayFont[20], color: dayColor[20]}}>{format(calendarDates[20], 'd')}</p></div>
                                        <div className='grid'><p onClick={()=>AppointmentDate(21)} style={{fontWeight: dayFontWeight[21], fontSize: dayFont[21], color: dayColor[21]}}>{format(calendarDates[21], 'd')}</p> <p onClick={()=>AppointmentDate(22)} style={{fontWeight: dayFontWeight[22], fontSize: dayFont[22], color: dayColor[22]}}>{format(calendarDates[22], 'd')}</p> <p onClick={()=>AppointmentDate(23)} style={{fontWeight: dayFontWeight[23], fontSize: dayFont[23], color: dayColor[23]}}>{format(calendarDates[23], 'd')}</p> <p onClick={()=>AppointmentDate(24)} style={{fontWeight: dayFontWeight[24], fontSize: dayFont[24], color: dayColor[24]}}>{format(calendarDates[24], 'd')}</p> <p onClick={()=>AppointmentDate(25)} style={{fontWeight: dayFontWeight[25], fontSize: dayFont[25], color: dayColor[25]}}>{format(calendarDates[25], 'd')}</p> <p onClick={()=>AppointmentDate(26)} style={{fontWeight: dayFontWeight[26], fontSize: dayFont[26], color: dayColor[26]}}>{format(calendarDates[26], 'd')}</p> <p onClick={()=>AppointmentDate(27)} style={{fontWeight: dayFontWeight[27], fontSize: dayFont[27], color: dayColor[27]}}>{format(calendarDates[27], 'd')}</p></div>
                                        <div className='grid'><p onClick={()=>AppointmentDate(28)} style={{fontWeight: dayFontWeight[28], fontSize: dayFont[28], color: dayColor[28]}}>{format(calendarDates[28], 'd')}</p> <p onClick={()=>AppointmentDate(29)} style={{fontWeight: dayFontWeight[29], fontSize: dayFont[29], color: dayColor[29]}}>{format(calendarDates[29], 'd')}</p> <p onClick={()=>AppointmentDate(30)} style={{fontWeight: dayFontWeight[30], fontSize: dayFont[30], color: dayColor[30]}}>{format(calendarDates[30], 'd')}</p> <p onClick={()=>AppointmentDate(31)} style={{fontWeight: dayFontWeight[31], fontSize: dayFont[31], color: dayColor[31]}}>{format(calendarDates[31], 'd')}</p> <p onClick={()=>AppointmentDate(32)} style={{fontWeight: dayFontWeight[32], fontSize: dayFont[32], color: dayColor[32]}}>{format(calendarDates[32], 'd')}</p> <p onClick={()=>AppointmentDate(33)} style={{fontWeight: dayFontWeight[33], fontSize: dayFont[33], color: dayColor[33]}}>{format(calendarDates[33], 'd')}</p> <p onClick={()=>AppointmentDate(34)} style={{fontWeight: dayFontWeight[34], fontSize: dayFont[34], color: dayColor[34]}}>{format(calendarDates[34], 'd')}</p></div>
                                    </>)}
                                </div>
                                <div className='slots-block unfixed-slots'>
                                    <div className='slot' onClick={()=>( Availability[1]==false || Resting[1]==true)?NullFunc:AppointmentSlot(1)} style={{opacity:(Slot1==1 || Availability[1]==false || Resting[1]==true)?0.5:1}}>
                                        <p className='slot-name'>Slot 1</p>
                                        <p className='slot-duration'>7:00-11:00</p>
                                        {Availability[1]==true && Resting[1]==false && (
                                            <p className='available-tag'>available</p>
                                        )}
                                        {(Availability[1]==false || Resting[1]==true) && (
                                            <p className='unavailable-tag'>unavailable</p>
                                        )}
                                    </div>
                                    <div className='slot' onClick={()=>( Availability[2]==false || Resting[2]==true)?NullFunc:AppointmentSlot(2)} style={{opacity:(Slot1==2 || Slot2==2 || Availability[2]==false || Resting[2]==true)?0.5:1}}>
                                        <p className='slot-name'>Slot 2</p>
                                        <p className='slot-duration'>12:00-16:00</p>
                                        {Availability[2]==true && Resting[2]==false && (
                                            <p className='available-tag'>available</p>
                                        )}
                                        {(Availability[2]==false || Resting[2]==true) && (
                                            <p className='unavailable-tag'>unavailable</p>
                                        )}
                                        <div className='slot-filter'></div>
                                    </div>
                                </div>
                                {/* <div className='Hour-selector'>
                                    <p>{timeArray[TimeRange]}</p>
                                    <img className='hour-above' src='/Images/up.png'  onClick={TimeUp}></img>
                                    <img className='hour-below' src='/Images/down.png'  onClick={TimeDown}></img>
                                </div> */}
                            </div>
                            <div className='submit-block' onClick={SubmitAppointment}>
                                <div>
                                    <h3>Submit Appointment</h3>
                                    {Appointment != null && calendarDates[0] &&
                                        (<h3 className='Appointment-date'>{Appointment}</h3>)
                                    }
                                    {/* {TimeRange!=null &&
                                        (<p className='TimeRange'>{timeArray[TimeRange]}</p>)
                                    } */}
                                </div>
                            </div>
                        </div>)
                    }          
                </div>
                </>);

return output;
}