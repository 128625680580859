import '../Style/ImageCard.css';
import { useState, useEffect } from 'react';
import ImageDisplay from '../PopUps/ImageDisplay';
import UpdateVideo from '../PopUps/UpdateVideo';
import DeleteVideo from '../PopUps/DeleteVideo';
import DeleteImage from '../PopUps/DeleteImage';
import UpdateImage from '../PopUps/UpdateImage';

export default function ImageCard({data, owner}){
    const [display, setDisplay] = useState(false);
    const [options, setOptions] =useState(false);
    const [Delete, setDelete] = useState(false);
    const [update, setUpdate] = useState(false);
    const [imagePost, setImgPost] = useState({description:data.description, imgFile:null});
    const months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec']
    const [date, setDate] = useState(new Date(data.createdDate));

    const output = (<>
                    <div id='ImageCard'>
                        <img src={data.imgLink} className='image'></img>
                        <p className='createdDate'>{date.getDate()} {months[date.getMonth()]} {date.getFullYear()}</p>
                        {owner &&(   
                            <img className='options' src='/Images/options2.png' onClick={()=>setOptions(!options)}></img>
                        )}
                        <img className='click' src='/Images/click.png' onClick={()=>setDisplay(true)}></img>
                        {options && (
                            <div className='options-block'>
                                <div className='option-button'>
                                    <p onClick={()=>{setDelete(true);setOptions(false);setUpdate(false);}}>Delete Post</p>
                                    <img src='/Images/delete.png'></img>
                                </div>
                                <div className='option-button'>
                                    <p onClick={()=>{setUpdate(true);setOptions(false);setDelete(false);}}>Update Post</p>
                                    <img src='/Images/update.png'></img>
                                </div>
                            </div>
                        )}
                    {Delete && data &&(
                        <DeleteImage setDelete={setDelete} id={data._id}></DeleteImage>
                    )}
                    {update && data &&(
                        <UpdateImage setUpdate={setUpdate} data={data}></UpdateImage>
                    )}
                    </div>
                    {display==true &&(
                        <ImageDisplay setDisplay={setDisplay} data={data}></ImageDisplay>
                    )}
                    </>)

    return output;
}