import "../Style/ResetPassword.css";
import { Link, useParams } from "react-router-dom";
import { useState, useEffect, useRef } from "react";
import { UserDataContext } from "../Data/Session";
import { useContext } from "react";
import { resetPassword } from "../Actions/User";
import { useNavigate } from "react-router-dom";

function ResetPassword(){
    const [isFocused1, setFocused1] = useState(false);
    const [isFocused2, setFocused2] = useState(false);
    const [input, setInput] = useState({
        password1: null,
        password2: null
    });
    const [display1, setDisplay1] = useState('none');
    const [display2, setDisplay2] = useState('none');
    const {sessionId} = useContext(UserDataContext);
    const navigate = useNavigate();

    const {code} = useParams();

    function handleBlur1(){
        setFocused1(false);
    }

    function handleBlur2(){
        setFocused2(false);
    }

    function handleFocus1(){
        setFocused1(true);
    }

    function handleFocus2(){
        setFocused2(true);
    }

    const handleSubmit = async(e)=>{
        e.preventDefault();
        if(input.password1 && input.password2 && input.password1==input.password2){
            if(code){
                const response = await resetPassword(code,{password:input.password1,sessionId:sessionId});

                if(response && response.successful){
                    navigate('/signIn');
                }
                else{
                    setDisplay1('block');
                    setDisplay2('block');
                }
            }
        }
        else{
            setDisplay1('block');
            setDisplay2('block');
        }
    }

    function handleChange(e){
        const {name, value} = e.target;

        setInput({
            ...input,
            [name]: value
        })

        if(name=='password1'){
            setDisplay1('none');
        }
        else if(name == 'password2'){
            setDisplay2('none');
        }
    }

    const output = (<>
                        <div id="resetPasswordPage">
                            <div className="logo-block">
                                <img src="/Images/logo3.png"></img>
                            </div>
                            <div className="Underliner">
                                <div className="Line1"></div>
                                <div className="Line2"></div>
                            </div>
                            <form onSubmit={handleSubmit}>
                                <h2>Enter the new password.</h2>
                                <div className='password1'>
                                    <label className={`password1Placeholder ${isFocused1 || input.password1 ? 'active':''}`}>
                                        Password
                                    </label>
                                    <input id='password1Input' type="text" name="password1" onFocus={handleFocus1} onBlur={handleBlur1} onChange={handleChange}></input>
                                    <p className="required" style={{display:display1}}>error</p>
                                </div>
                                <div className='password2'>
                                    <label className={`password2Placeholder ${isFocused2 || input.password2 ? 'active':''}`}>
                                        Verify Password
                                    </label>
                                    <input id='password2Input' type="text" name="password2" onFocus={handleFocus2} onBlur={handleBlur2} onChange={handleChange}></input>
                                    <p className="required" style={{display:display2}}>error</p>
                                </div>
                                <div className="send">
                                    <button type='submit'>
                                        Send
                                    </button> 
                                </div> 
                                <div className="link">
                                    <div>
                                        <p>Don't have an account?</p>
                                        <Link to="/signUp1">Sign Up</Link>  
                                    </div>
                                </div>
                            </form> 
                        </div>
                    </>);

    return output;
}

export default ResetPassword; 