import '../Style/BookingsList.css';
import { useState, useEffect } from 'react';
import { useContext } from 'react';
import { UserDataContext } from '../Data/Session';
import { useNavigate } from 'react-router-dom';

export default function Chats({setBookingsOpen}){
const [search, setSearch] = useState('');
const [upcBookers, setUpcBookers] = useState([]);
const [prevBookers, setPrevBookers] = useState([]);
const [selected, setSelected] = useState('previous');
const {bookings, users, userData} = useContext(UserDataContext); 
const navigate = useNavigate();

function SameDayOrLater(savedDate, currentDate = new Date()){
    // Extract the year, month, and day from both dates
    const savedYear = savedDate.getFullYear();
    const savedMonth = savedDate.getMonth();
    const savedDay = savedDate.getDate();
  
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth();
    const currentDay = currentDate.getDate();
  
    // Compare the year, month, and day
    if (savedYear > currentYear) {
      return true; // It's a later year
    } 
    else if (savedYear === currentYear) {
      if (savedMonth > currentMonth) {
        return true; // It's a later month in the same year
      } 
      else if (savedMonth === currentMonth) {
        if (savedDay >= currentDay) {
          return true; // It's the same day or later day
        }
      }
    }
    
    return false; // It's an earlier day
}
  
useEffect(()=>{
    let prevBookers = [];
    let upcBookers = []

    if(bookings && users){
        const myUpcBookings = bookings.filter((booking)=>(booking.servicerId==userData._id && booking.completedDate==null && SameDayOrLater(new Date(booking.appointment.dateTime)))) //get servicer's upcoming bookings
        const myPrevBookings = bookings.filter((booking)=>(booking.servicerId==userData._id && (booking.completedDate!=null || !SameDayOrLater(new Date(booking.appointment.dateTime))))) //get servicer's previous bookings
        myUpcBookings.map((booking)=>{
            let booker = users.find((user)=>(user._id==booking.bookerId)) //get bookers data
            if(booker){    
               upcBookers = [...upcBookers,{booker:booker,booking:booking}];
            }
        })
        myPrevBookings.map((booking)=>{
            let booker = users.find((user)=>(user._id==booking.bookerId)) //get bookers data
            if(booker){    
               prevBookers = [...prevBookers,{booker:booker,booking:booking}];
            }
        })
    }

    setPrevBookers(prevBookers.sort((a, b) => ((new Date(a.booking.appointment.dateTime) - new Date(b.booking.appointment.dateTime)))));
    setUpcBookers(upcBookers.sort((a, b) => ((new Date(a.booking.appointment.dateTime) - new Date(b.booking.appointment.dateTime)))));
},[bookings,users])

const output = (<>
        <div id='BookingsList'>
            <div className='Bookings-list'>
                <p className='close-bookings' onClick={()=>setBookingsOpen(false)}>&#10006;</p>
                <input className='search-tab' type='text' onChange={(e)=>setSearch(e.target.value)}></input>
                <div className='booking-tabs'>
                    <div className={`tab1 ${selected=='previous'?'selected':''}`} onClick={()=>setSelected('previous')}>
                        <p>Previous</p>
                    </div>
                    <div className={`tab2 ${selected=='upcoming'?'selected':''}`} onClick={()=>setSelected('upcoming')}>
                        <p>Upcoming</p>
                    </div>
                </div>
                <div className='list'>
                    {selected=='upcoming' && upcBookers && upcBookers.filter((data)=>( (data.booker.name+' '+data.booker.surname).includes(search) || data.booking.appointment.date.includes(search) )).map((data)=>(
                        <div className='bookingCard' onClick={()=>{navigate(`/booking/${data.booking._id}`);}}>
                            <div className='profile-frame'>
                                <img src={(data.booker.profileLink && data.booker.profileLink!='')?data.booker.profileLink:'/Images/user.png'} ></img>
                            </div>
                            <div className='data'>
                                <div className='bookerData'>
                                    <p className='name'>{data.booker.name} {data.booker.surname}</p>
                                    <p className='time'>{data.booking.appointment.date}</p>
                                </div>
                            </div>
                        </div>
                    ))}
                    {selected=='previous' && prevBookers && prevBookers.filter((data)=>( (data.booker.name+' '+data.booker.surname).includes(search) || data.booking.appointment.date.includes(search) )).map((data)=>(
                        <div className='bookingCard' onClick={()=>{navigate(`/booking/${data.booking._id}`);}}>
                            <div className='profile-frame'>
                                <img src={(data.booker.profileLink && data.booker.profileLink!='')?data.booker.profileLink:'/Images/user.png'} ></img>
                            </div>
                            <div className='data'>
                                <div className='bookerData'>
                                    <p className='name'>{data.booker.name} {data.booker.surname}</p>
                                    <p className='time'>{data.booking.appointment.date}</p>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
        </>);

return output;

}