import '../Style/VideoDisplay.css';
import { useState, useEffect } from 'react';

export default function VideoDisplay({setPlay, data}){
    const months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec']
    const [date, setDate] = useState(new Date(data.createdDate));
    const [duration, setDuration] = useState(null);

    const handleLoadedMetadata = (event) => {
        const duration = event.target.duration; //in seconds
        const seconds = Math.round(duration%60)
        const minutes = Math.floor(duration/60)

        setDuration(`${minutes}:${seconds}`);
    }
    
    const output = (
                <div id='VideoDisplay'>
                    <div className='faint'>
                        <div className='video-block'>
                            <video className='video' src={data.vidLink}  onLoadedMetadata={handleLoadedMetadata} playsInline loop muted autoPlay></video>
                            <p className='close-image' onClick={()=>setPlay(false)}>&#10006;</p>
                            <div className='description-block'>
                                <p>{data.description}</p>
                            </div>
                            <p className='duration'>{duration}</p>
                            <p className='createdDate'>{date.getDate()} {months[date.getMonth()]} {date.getFullYear()}</p>
                        </div>
                    </div>
                </div>
    )

    return output
}