import '../Style/LatestNews.css'
import { useNavigate } from 'react-router-dom';


export default function PrivacyPolicy(){

    const navigate = useNavigate();

    const output = (
        <div id="LatestNews">
            <div className="logo-block">
                <img src="/Images/logo3.png"></img>
            </div>
            <button className='backButton' onClick={()=>navigate(-1)}>&#8592; Back</button>
            <div className='body'>
                <h1>Coming Soon</h1>
            </div>
        </div>
    );

    return output;
}