import '../Style/ErrorPage.css';

export default function ErrorPage(){

    const output = (<div id="ErrorPage">
        <h1>
            Oops!
        </h1>
        <h3>
            404 - Page not found chommie.
        </h3>
    </div>);

    return output;
}