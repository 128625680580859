import { useRef } from "react";
import '../Style/SlideShow.css';
import Thumbnail from "./Thumbnail";

export default function SlideShow({data}){

    const linkN = [useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef()];
    const linkP = [useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef()];

    function Prev(){
        for(let i=0; i<data.length; i++){
            linkP[(i+1)%Total()].current.click();
        }
    }

    function Next(){
        for(let i=0; i<data.length; i++){
            linkN[(i+1)%Total()].current.click();
        }  
    }

    function Total(){
        if(data.length<7){
            return 7;
        }
        else{
            return data.length;
        }
    }
    const output = ( 
        <div className="SlideShow">
            <p className="service-name">{data[0].service}</p>
            <div className="underline"></div>
            <div className="slide-blocka"><div onClick={Prev}><img src="/Images/left2.png"></img></div></div>
                <div className="slide-blockb">
                    {data && data.map((servicer, index)=>
                        <Thumbnail linkN={linkN[(index+1)%Total()]} linkP={linkP[(index+1)%Total()]} total={Total()} pos={(index+1)%Total()} data={servicer._id}></Thumbnail>
                    )}
                </div>
            <div className="slide-blockc"><div onClick={Next}><img src="/Images/next2.png"></img></div></div>
        </div>
    )
    
    return output;
}