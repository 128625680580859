import '../Style/VideoCard.css';
import { useEffect, useState } from 'react';
import { useContext } from 'react';
import { UserDataContext } from '../Data/Session';
import { useNavigate } from 'react-router-dom';

export default function VideoCard({data}){
    const {ratings, userData} = useContext(UserDataContext);
    const [rating, setRating] = useState(0)
    const navigate = useNavigate();

    function NullFunc(){}

    useEffect(()=>{
        if(ratings){
            const ratingArr = ratings.filter((rating)=>rating.servicerId==data.servicer._id);
            let ratingAve = 0;
            for(let i = 0; i<ratingArr.length; i++){
                ratingAve += ratingArr[i].rating;
            }
            ratingAve = (ratingArr.length>0)?Math.round(ratingAve/ratingArr.length):0;  
            setRating(ratingAve);  
        }
    },[ratings])

    const output = (<div id='VideoCard' onClick={()=>(userData && userData.type=='User')?navigate(`/Account/servicer/${data.servicer._id}`):NullFunc()}>
                        <video src={data.video.vidLink} playsInline loop muted autoPlay></video>
                        <div className='data-block'>
                            <h3 className='vc-service'>
                                <span>.  </span>
                                {data.servicer.service}
                                <span>  .</span>
                            </h3>
                            <div className='vc-profile'>
                                <div className='vc-image'>
                                    <img src={(data.servicer.profileLink)?data.servicer.profileLink:'/Images/user.png'}></img>
                                </div>
                                <div className='vc-data'>
                                    <p className='vc-name'>{data.servicer.name} {data.servicer.surname}</p>
                                    <div className='vc-jobsData'>
                                        <div className='vc-rating'>
                                            <p className='vc-rating'>{rating.toFixed(1)}</p>
                                            <img src='/Images/star-yellow.png' className='vc-star'></img>
                                        </div>
                                        <p className='vc-jobs'>{(data.servicer.jobs)?data.servicer.jobs:0} Jobs</p>
                                    </div>
                                    <div className='vc-location'>
                                        <img src='/Images/pin.png' ></img>
                                        <p>{(data.servicer.city)?data.servicer.city:'-'}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>);
    return output;

}