import '../Style/UpdateVideo.css';
import { useState } from 'react';
import { updateVideo } from '../Actions/Video';

export default function UpdateVideo({setUpdate, data}){
    const [videoPost, setVidPost] = useState({description:data.description, vidFile:null});

    const handleChange = (e)=>{
        const {value, name} = e.target;
        if(name=='description'){
            setVidPost({...videoPost,[name]:value});
        }
        else{
            setVidPost({...videoPost,[name]:e.target.files[0]});
        }
    }

    const handleSubmit = (e)=>{
        e.preventDefault();
        updateVideo(data._id,videoPost);
        setVidPost({...videoPost, vidFile:null});
        setUpdate(false);
    }

    const output = (<div id='UpdateVideo'>
                        <div className="updateContent">
                            <p className='close-tab' onClick={()=>setUpdate(false)}>&#10006;</p>
                            <h1>Update Post</h1>
                            <form className="form" onSubmit={handleSubmit}>
                                <p>Video:</p>
                                <input name='vidFile' type='file' onChange={handleChange} value={(videoPost.vidFile)?videoPost.vidFile.filename:''}></input>
                                <p>Description:</p>
                                <textarea maxlength="200" name="description" onChange={handleChange} value={videoPost.description}></textarea>
                                <button type='submit'>Post</button>
                            </form>
                        </div>
                    </div>);

    return output;
}