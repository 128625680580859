import '../Style/AboutUs.css';
import { useState, useEffect, useRef } from 'react';
import ExecCard from '../Components/ExecCard';

export default function AboutUs(){
    const [pLink, setPLink] = useState([useRef(),useRef(),useRef(),useRef()])
    const [nLink, setNLink] = useState([useRef(),useRef(),useRef(),useRef()])
    const [data, setData] = useState([{name:'Vuka',surname:'Dlamini',position:'CTO',imgLink:'/Images/CTO.png',message:"As a co-founder of ScheduleEase, I'm passionate about using technology to create opportunities and solve real-world problems. Our platform is designed to empower both service providers and clients, ensuring everyone benefits from reliable, quality connections."},
                                      {name:'Mzansi',surname:'Africa',position:'Service Providers',imgLink:'/Images/Mzansi.jpg',message:"We're the South African housekeepers, gardeners, plumbers, electricians, and more who work hard every day to bring comfort and quality to our communities. ScheduleEase is more than a platform for us — it's a partner that lets us reach more people, grow our businesses, and be trusted names in our neighborhoods. With this seat at the table, we know our voices matter. Thank you for letting us serve you."},
                                      {name:'Luthando',surname:'Buthelezi',position:'CEO',imgLink:'/Images/CEO.jpg',message:"I'm dedicated to making ScheduleEase a force for good in South Africa. Our goal is to create a platform that not only connects people but also uplifts communities by providing fair opportunities and fostering trust."}])

    const Next = ()=>{
        for(let i = 0; i<3; i++){
            if(nLink[i].current){
                nLink[i].current.click();
            }
        }
    }

    const Prev = ()=>{
        for(let i = 0; i<3; i++){
            if(pLink[i].current){
                pLink[i].current.click();
            }
        }
    }

    const output = (
        <div id="AboutUs-page">
            <div className='mission-block'>
                <img src='/Images/theme.jpg'></img>
                <h2>Our Mission</h2>
                <p>At ScheduleEase, our mission is to empower South Africans by creating a platform that connects skilled 
                service providers with those in need of their expertise. We aim to bridge the gap between job seekers 
                and those searching for trustworthy services, ensuring fair wages and quality work for all. By fostering a 
                community of excellence, trust, and reliability, we strive to uplift lives and create sustainable opportunities 
                in a country where every connection counts.</p>
            </div>
            <div className='story-block'>
                <div className='story-image'>
                    <div className='story-frame'>
                        <img src='/Images/icon2.png'></img>
                    </div>
                </div>
                <div className='story-text'>
                    <h2>Our Story</h2>
                    <p>ScheduleEase was born out of a desire to make a difference in our communities. As two young South African black men 
                    in our 20s, we've witnessed firsthand the struggles faced by both service providers and those seeking their help. 
                    From exploitation to scams, the challenges are real, and we knew something had to change. We created ScheduleEase as a solution—a platform 
                    where talent meets opportunity, where quality meets trust, and where people can find the services they need while supporting those 
                    who need it most. ScheduleEase is more than just a business; it's our commitment to building a better, more connected South Africa.</p>
                </div>
            </div>
            <div className='team-block'>
                <h2>Meet The Team</h2>
                <div className="item-roll">
                    <button className='button1' onClick={Prev} style={{cursor: 'pointer'}}>❮</button>
                    <div className='items-block'>
                        <div className='items'>   
                            {data.map((folder, index)=>(
                                <ExecCard data={folder} index={index} nLink={nLink[index]} pLink={pLink[index]}></ExecCard>
                            ))}
                        </div>
                    </div>
                    <button className='button2' onClick={Next} style={{cursor: 'pointer'}}>❯</button>
                </div>
            </div>
        </div>
    )

    return output;
}