import '../Style/ImageDisplay.css';
import { useState, useEffect } from 'react';

export default function ImageDisplay({setDisplay, data}){
    const months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec']
    const [date, setDate] = useState(new Date(data.createdDate));

    const output = (
                <div id='ImageDisplay'>
                    <div className='faint'>
                        <div className='image-block'>
                            <img src={data.imgLink} className='image'></img>
                            <p className='close-image' onClick={()=>setDisplay(false)}>&#10006;</p>
                            <div className='description-block'>
                                <p>{data.description}</p>
                            </div>
                            <p className='createdDate'>{date.getDate()} {months[date.getMonth()]} {date.getFullYear()}</p>
                        </div>
                    </div>
                </div>
    )

    return output
}