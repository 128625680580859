import axios from 'axios';

const url = 'https://scheduleease.co.za/api/image/';

///////////////////////////// GET REQUEST /////////////////////////////////////

export const getImages = async() => {
    try {
        const {data} = await axios.get(url);
        return data;
    } catch (error) {
        console.log(error)
    }
}

////////////////////////////// POST REQUEST ///////////////////////////////////

export const createImage = (newData) => { 
    
    try {
        const formData = new FormData();
        
        if(newData.imgFile!=null){
            formData.append('imgFile',newData.imgFile);
        }
        if(newData.createdDate!=null){
            formData.append('createdDate',String(newData.createdDate));
        }
        if(newData.description!=null){
            formData.append('description',newData.description);
        }
        if(newData.userId!=null){
            formData.append('userId',newData.userId);
        }
        
        axios.post(url,formData);
        
    } catch (error) {
       console.log(error);
    }
}

///////////////////////////////// UPDATE REQUEST /////////////////////////////////////////

export const updateImage = (id, updatedData) =>{
    try {
        const formData = new FormData();
        
        if(updatedData.imgFile!=null){
            formData.append('imgFile',updatedData.imgFile);
        }
        if(updatedData.createdDate!=null){
            formData.append('createdDate',String(updatedData.createdDate));
        }
        if(updatedData.description!=null){
            formData.append('description',updatedData.description);
        }
        if(updatedData.userId!=null){
            formData.append('userId',updatedData.userId);
        }
        
        axios.patch(`${url}${id}`,formData);
  
    } catch (error) {
      console.log(error); 
    }
}

/////////////////////////////// DELETE REQUEST /////////////////////////////////////////
export const deleteImage = (id)=> {
    try {
      axios.delete(`${url}${id}`);
    } catch (error) {
        console.log(error);
    }
  
}