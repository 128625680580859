import '../Style/AddImage.css';
import { useState, useEffect, useContext } from 'react';
import { UserDataContext } from "../Data/Session";
import { createImage } from '../Actions/Image';

export default function AddImage({setAddImage, servicer,accountType}){
    const [imagePost, setImgPost] = useState({description:'', imgFile:null});
    const {userData} = useContext(UserDataContext);  

    const handleChange1 = (e)=>{
        const {value, name} = e.target;
        if(name=='description'){
            setImgPost({...imagePost,[name]:value});
        }
        else{
            setImgPost({...imagePost,[name]:e.target.files[0]});
        }
    }

    const handleSubmit1 = (e)=>{
        e.preventDefault();
        createImage({...imagePost, createdDate:new Date().getTime(),userId:(accountType=='user')?userData._id:servicer._id});
        setImgPost({description:'', imgFile:null});
        setAddImage(false);
    }

    const output = (<div id='AddImage'>
                        <div className="adContent" >
                            <p className='close-tab' onClick={()=>setAddImage(false)}>&#10006;</p>
                            <h1>Post An Image Of Your Work</h1>
                            <form className="form" onSubmit={handleSubmit1}>
                                <p>Image:</p>
                                <input name='imgFile' type='file' onChange={handleChange1} value={(imagePost.imgFile)?imagePost.imgFile.filename:''}></input>
                                <p>Description:</p>
                                <textarea maxlength="200" name='description' onChange={handleChange1} value={imagePost.description}></textarea>
                                <button type='submit'>Post</button>
                            </form>
                        </div>                       
                    </div>);

    return output;
}