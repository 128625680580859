import '../Style/Report.css';
import {createReport} from '../Actions/Report.js';
import { useState } from 'react';

export default function RemoveCard({setReport, reporterId, offenderId, offender}){
    const [data, setData] = useState({
                                        type: null,
                                        details: ''
                                    });

    function SubmitReport(){
        const reportData = {
                        type: data.type,
                        offender: offender,
                        details: data.details,
                        createdDate: new Date().getTime(),
                        offenderId:offenderId ,
                        reporterId: reporterId
                     };
                     
        createReport(reportData);
        setReport(false);
    }

    const output = (<div id='Report'>
                        <div className='report-block'>
                            <h3>Why are you reporting this account?</h3>
                            <div className='list'>
                                <div className={`report-type ${(data.type=='Physical Abuse')?'selected':''}`} onClick={()=>setData({...data, type:'Physical Abuse'})}>
                                    <p>Physical Abuse</p>
                                </div>
                                <div className={`report-type ${(data.type=='Verbal Abuse')?'selected':''}`} onClick={()=>setData({...data, type:'Verbal Abuse'})}>
                                    <p>Verbal Abuse</p>
                                </div>
                                {offender=='Servicer' && (<>
                                    <div className={`report-type ${(data.type=='Poor Service')?'selected':''}`} onClick={()=>setData({...data, type:'Poor Service'})}>
                                        <p>Poor Service</p>
                                    </div>
                                    <div className={`report-type ${(data.type=='Late Arrival')?'selected':''}`} onClick={()=>setData({...data, type:'Late Arrival'})}>
                                        <p>Late Arrival</p>
                                    </div>
                                    <div className={`report-type ${(data.type=='Scam or fraud')?'selected':''}`} onClick={()=>setData({...data, type:'Scam or fraud'})}>
                                        <p>Scam or fraud</p>
                                    </div>
                                </>)}
                                {offender=='Booker' && (
                                    <div className={`report-type ${(data.type=='Unsafe Environment')?'selected':''}`} onClick={()=>setData({...data, type:'Unsafe Environment'})}>
                                        <p>Unsafe Environment</p>
                                    </div>
                                )}
                                <div className={`report-type ${(data.type=='Other')?'selected':''}`} onClick={()=>setData({...data, type:'Other'})}>
                                    <p>Other</p>
                                </div>
                            </div>
                            <p>Details:</p>
                            <textarea onChange={(e)=>setData({...data, details:e.target.value})}></textarea>
                            <button className='Delete' onClick={SubmitReport}>Submit</button>
                            <button className='Cancel' onClick={()=>setReport(false)}>Cancel</button>
                        </div>
                    </div>)

    return output;

}