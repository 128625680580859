import {useEffect, useState, useRef} from "react";
import '../Style/Services.css';
import Thumbnail4 from "../Components/Thumbnail4";
import VideoCard from "../Components/VideoCard";
import { useContext } from "react";
import { UserDataContext } from "../Data/Session";
import LocationSelector from "../Components/LocationSelector";
import CheckoutButton from "../Components/CheckoutButton";
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

export default function Services(){
    const linkN = [useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef()];
    const linkP = [useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef()];
    const {servicers, images, videos, userData} = useContext(UserDataContext);
    const navigate = useNavigate();
    const [left, setLeft] = useState(0);
    const [left2, setLeft2] = useState(0);
    const [filter, setFilter] = useState(0);
    const [gridData, setData] = useState([]);
    const [videoData, setVids] = useState([]);
    const [flip, setFlip] = useState(true);
    const servs = [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80]
    const vids1 = [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59]    
    const groups = ['All','House Cleaner','Gardener','Plumber','Pool Cleaner','Car Cleaner','Painter','Pet Caretaker','Pest Controller','Waste Remover','Electrician','Gutter Cleaner'];

    useEffect(()=>{
        if(!userData){
            navigate('/signIn')
        }
    },[userData])

    useEffect(()=>{
        if(servicers && videos){
            let data = [];
            videos.map((video)=>{
                const servicer = servicers.find((servicer)=>servicer.idNumber && servicer._id == video.userId);
                if(servicer && servicer!={}){
                    data = [...data,{servicer:servicer,video:video}];
                }
            })
            setVids(data);
        }
    },[servicers, videos])

    useEffect(()=>{
        if(videoData && servicers && userData && userData.type=='User' && userData.city){
            let data = [] // data of type {servicers:[] ,videos:[]} 
            const vidData = videoData.filter((data)=> ((filter==0)||(data.servicer.service==groups[filter])) && data.city!=null && data.city==userData.city); 
            const servData = servicers.filter((data)=>((filter==0)||(data.service==groups[filter])) && data.idNumber && data.city!=null && data.city==userData.city); 
            // console.log(servData)

            const total1 = servData.length; //number of servicers
            const total2 = vidData.length; //number of videos
            const setCount1 = Math.ceil(total1/15); // number of sets of 15
            const setCount2 = Math.ceil(total2/5) // number of sets of 5

            const spread = Math.floor(setCount2/setCount1);

            for(let a=0; a<setCount1; a++){
                let start1 = a*15;
                let end1 = ((a*15)+15<total1)?a*15+15:total1;
                let servArr = servData.slice(start1,end1);

                let start2 = spread*5*a;
                let end2 = (a==setCount1-1 && (total2-start2-spread*5) < (setCount1*5) )?total2:start2+spread*5;
               
                if(start2<total2){
                    let vidArr = vidData.slice(start2,end2); 
                    data = [...data,{servicers:servArr,videos:vidArr}]
                }
                else{
                    data = [...data,{servicers:servArr,videos:[]}]
                }
            }
          
            setData(data);
        }
        else if(videoData && servicers && userData && userData.type=='Servicer'){
            let data = [] // data of type {servicers:[] ,videos:[]} 
            const vidData = videoData.filter((data)=>(filter==0)||(data.servicer.service==groups[filter])); 
            const servData = servicers.filter((data)=>(data.idNumber && filter==0)||(data.idNumber && data.service==groups[filter])); 
            // console.log(servData)
            const total1 = servData.length; //number of servicers
            const total2 = vidData.length; //number of videos
            const setCount1 = Math.ceil(total1/15); // number of sets of 15
            const setCount2 = Math.ceil(total2/5) // number of sets of 5

            const spread = Math.floor(setCount2/setCount1);

            for(let a=0; a<setCount1; a++){
                let start1 = a*15;
                let end1 = ((a*15)+15<total1)?a*15+15:total1;
                let servArr = servData.slice(start1,end1);

                let start2 = spread*5*a;
                let end2 = (a==setCount1-1 && (total2-start2-spread*5) < (setCount1*5) )?total2:start2+spread*5;
               
                if(start2<total2){
                    let vidArr = vidData.slice(start2,end2); 
                    data = [...data,{servicers:servArr,videos:vidArr}]
                }
                else{
                    data = [...data,{servicers:servArr,videos:[]}]
                }
            }
          
            setData(data);
        }
    },[videoData,servicers, filter, userData])

    const output = (<div id="Services">
                        <div className="filter-block">
                            <p onClick={()=>setFilter(0)} className={(filter==0)?'selected':''}>All</p>
                            <p onClick={()=>setFilter(1)} className={(filter==1)?'selected':''}>House Cleaner</p>
                            <p onClick={()=>setFilter(2)} className={(filter==2)?'selected':''}>Gardner</p>
                            <p onClick={()=>setFilter(3)} className={(filter==3)?'selected':''}>Plumber</p>
                            <p onClick={()=>setFilter(4)} className={(filter==4)?'selected':''}>Pool Cleaner</p>
                            <p onClick={()=>setFilter(5)} className={(filter==5)?'selected':''}>Car Cleaner</p>
                            <p onClick={()=>setFilter(6)} className={(filter==6)?'selected':''}>Painter</p>
                            <p onClick={()=>setFilter(7)} className={(filter==7)?'selected':''}>Pet Caretaker</p>
                            <p onClick={()=>setFilter(8)} className={(filter==8)?'selected':''}>Pest Controller</p>
                            <p onClick={()=>setFilter(9)} className={(filter==9)?'selected':''}>Waste Remover</p>
                            <p onClick={()=>setFilter(10)} className={(filter==10)?'selected':''}>Electrician</p>
                            <p onClick={()=>setFilter(11)} className={(filter==11)?'selected':''}>Gutter Cleaner</p>
                        </div>
                        {gridData && gridData.map((data)=>
                            <>
                            <div className="thumbnail-block">
                                {data.servicers && data.servicers.map((servicer)=>
                                    <Thumbnail4 servicer={servicer}></Thumbnail4>
                                )}
                            </div>
                            <div className="video-block">
                                {data.videos && data.videos.map((video)=>
                                    <VideoCard data={video}></VideoCard>
                                )}
                            </div>
                            </>
                        )}
                        <LocationSelector></LocationSelector>
                        <CheckoutButton></CheckoutButton>
                    </div>);

    return output;
}