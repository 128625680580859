import '../Style/CheckoutButton.css';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useContext } from 'react';
import { UserDataContext } from '../Data/Session';

export default function CheckoutButton(){
    const navigate = useNavigate();
    const {userData, bookings} = useContext(UserDataContext);
    const [show, setShow] = useState(false);

    useEffect(()=>{
        if(bookings && userData){
            const data = bookings.find((Data)=>Data.bookerId==userData._id && Data.paidDate==null && Data.completedDate==null);
            if(data){
                setShow(true)
            }
        }
    },[bookings, userData])

    const output = (
                        <div id='CheckoutButton' onClick={()=>navigate('/checkout')} style={{display:(show==true)?'grid':'none'}}>
                            <img src='/Images/cart.png'></img>
                            <p>Checkout</p>
                        </div>
                    );

    return output;
}