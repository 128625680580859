import '../Style/HouseCleaning.css';
import { useState } from 'react';

export default function HouseCleaning(props){
   
    //Room Windows
    const [border1, setBorder1] = useState('2px solid black');
    const [border2, setBorder2] = useState('2px solid white');

    //Room Windows
    const [filler1, setFiller1] = useState('white');
    const [filler2, setFiller2] = useState('black');

    //Bathroom Windows
    const [border3, setBorder3] = useState('2px solid black');
    const [border4, setBorder4] = useState('2px solid white');
    
    //Bathroom Windows
    const [filler3, setFiller3] = useState('white');
    const [filler4, setFiller4] = useState('black');

    const [nbOn, setNO] = useState(false);

    function Windows(clean){
        const newForms = [...props.forms];
        const windows = newForms[props.index].windows;
        let time = newForms[props.index].time;
        let newSubtotal = newForms[props.index].subTotal;
    
        if(clean && !windows){
            newSubtotal += 20;
            time += 0.25;
            newForms[props.index].windows = true;
            newForms[props.index].subTotal = newSubtotal;
            newForms[props.index].time = time;
            setBorder1('2px solid white');
            setBorder2('2px solid black');
            setFiller1('black');
            setFiller2('white');
            props.setForm(newForms);
        }
        else if(!clean && windows){
            newSubtotal -= 20;
            time -= 0.25;
            newForms[props.index].windows = false;
            newForms[props.index].subTotal = newSubtotal;
            newForms[props.index].time = time;
            setBorder1('2px solid black');
            setBorder2('2px solid white');
            setFiller1('white');
            setFiller2('black');
            props.setForm(newForms);
        }
        
    }

const output = (<>
                <div className='card-label'><h1>{`${props.type} ${props.index+1}`}</h1></div>
                <div className='HouseCleaning'>
                   {props.type=='Room' &&
                        (<>
                            {nbOn &&
                                <h4 className='hc-disclaimer' onClick={()=>setNO(!nbOn)}>*Cleaning of the floor and furniture is the standard</h4>
                            }
                            {!nbOn &&
                                <h4 className='hc-disclaimer2' onClick={()=>setNO(!nbOn)}>*NB</h4>
                            }
                            <div className='Spec'>
                                <h4>Clean Windows ?</h4>
                                {nbOn &&
                                    (<p className='maxTime'>*Max Time: 25 min</p>
                                )}
                                <div className='option'>
                                    <div onClick={()=>Windows(true)} style={{border:border1 ,backgroundColor:filler1 }}></div>
                                    <p>Yes</p>
                                    <p>R20</p>
                                </div>
                                <div className='option'>
                                    <div onClick={()=>Windows(false)} style={{border:border2 ,backgroundColor:filler2 }}></div>
                                    <p>No</p>
                                    <p>-</p>
                                </div>
                            </div>
                   </>)}
                   {props.type=='Bathroom' &&
                        (<>
                            {nbOn &&
                                <h4 className='hc-disclaimer' onClick={()=>setNO(!nbOn)}>*Cleaning of the toilet, basin, shower and/or the bath tub is the standard</h4>
                            }
                            {!nbOn &&
                                <h4 className='hc-disclaimer2' onClick={()=>setNO(!nbOn)}>*NB</h4>
                            }
                            <div className='Spec'>
                                <h4>Clean Windows ?</h4>
                                {nbOn &&
                                    (<p className='maxTime'>*Max Time: 25 min</p>
                                )}
                                <div className='option'>
                                    <div onClick={()=>Windows(true)} style={{border:border1 ,backgroundColor:filler1 }} ></div>
                                    <p>Yes</p>
                                    <p>R20</p>
                                </div>
                                <div className='option'>
                                    <div onClick={()=>Windows(false)} style={{border:border2 ,backgroundColor:filler2 }}></div>
                                    <p>No</p>
                                    <p>-</p>
                                </div>
                            </div>
                   </>)}
                </div>
                </>)

return output;
}