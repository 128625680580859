import '../Style/Directions.css';
import { useNavigate } from 'react-router-dom';
import { useState, useEffect, useRef, useContext } from 'react';
import { UserDataContext } from "../Data/Session.js";
import { MapContainer, TileLayer, Marker, useMapEvents, useMap, Popup } from 'react-leaflet';
import { GeoSearchControl, OpenStreetMapProvider } from 'leaflet-geosearch';
import 'leaflet-geosearch/dist/geosearch.css';
import axios from 'axios';
import {updateUser} from '../Actions/User.js';
import {updateServicer} from '../Actions/Servicer.js';

export default function Directions({setOpen}){

    const output = (
      <div id="Directions">
        <div className='directions-block'>
            <p className='close-button' onClick={()=>setOpen(false)}>&#10006;</p>
        </div>
      </div>
    );

    return output;
}