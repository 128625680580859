import '../Style/AddFaq.css';
import { useState, useEffect, useContext } from 'react';
import { UserDataContext } from "../Data/Session";
import { createFaq } from '../Actions/Faq';

export default function AddFaq({setAddFaq}){
    const [faqData, setFaqData] = useState({question:'', answer:''});

    const handleChange = (e)=>{
        const {value, name} = e.target;
        //console.log('Changing:',value);
        //console.log('Changing:',name);
        setFaqData({...faqData,[name]:value});
    }

    const handleSubmit = (e)=>{
        e.preventDefault();
        createFaq(faqData);
        setFaqData({question:'', answer:''});
        setAddFaq(false);
    }

    const output = (<div id='AddFaq'>
                        <div className="table" >
                            <p className='close-tab' onClick={()=>setAddFaq(false)}>&#10006;</p>
                            <h2>Enter A New FAQ</h2>
                            <div className='qBlock'>
                                <p>Question:</p>
                                <textarea className='questionInput' name="question" onChange={handleChange}></textarea>
                            </div>
                            <div className='aBlock'>
                                <p>Answer:</p>
                                <textarea className='answerInput' name="answer" onChange={handleChange}></textarea>
                            </div>
                            <button onClick={handleSubmit}>Submit</button>
                        </div>                       
                    </div>);

    return output;
}