import '../Style/CancelBooking.css';
import { updateBooking } from '../Actions/Booking';
import { refund } from '../Actions/Card';
import { UserDataContext } from '../Data/Session';
import { useContext, useEffect, useState } from 'react';

export default function CancelBooking({setCancelBooking, booking, canceller}){
    const [card, setCard] = useState(null);
    const [user, setUser] = useState(null);
    const {cards, users} = useContext(UserDataContext);

    const CancelBooking = async()=>{
        if(booking && booking.paymentOption=='card' && user && user!=undefined && user._id && card && card!=undefined && card._id){
            const data = {bookingId:booking._id, cancelType:'General', canceller:canceller, refundType:(canceller=='User')?'partial':'full',amount:booking.amount+30,card, userId:booking.bookerId, Category1:user.Category1, Category2to4:user.Category2to4}
            const response = await refund(data);

            // console.log('Refund Response: ',response)
            setCancelBooking(false);
        }
        else if(booking && booking.paymentOption=='cash'){
            updateBooking(booking._id,{canceller:canceller, cancelType:'General'});
            setCancelBooking(false);
        }
    }

    useEffect(()=>{
        if(users && cards && booking && booking.paymentOption=='Card'){
            const cardData = cards.find((info)=>info._id==booking.cardId);
            const userData = users.find((info)=>info._id==booking.bookerId);

            setCard(cardData);
            setUser(userData);
        }
    },[cards, users, booking])

    useEffect(()=>{
        if(booking && booking.canceller){
            setCancelBooking(false);
        }
    },[booking])

    const output = (<div id='CancelBooking'>
                        <div>
                            <h1>Are you sure you want to cancel this booking?</h1>
                            <button className='Delete' onClick={()=>CancelBooking()}>Yes</button>
                            <button className='Cancel' onClick={()=>setCancelBooking(false)}>No</button>
                        </div>
                    </div>)

    return output;

}