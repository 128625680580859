import '../Style/DeleteVideo.css';
import { deleteVideo } from '../Actions/Video';

export default function DeleteVideo({setDelete, id}){

    const output = (<div id='DeleteVideo'>
                        <div className='delete-block'>
                            <h2>Are you certain about deleting this post?</h2>
                            <button className='delete-button' onClick={()=>{setDelete(false);deleteVideo(id);}}>Delete</button>
                            <button className='cancel-button' onClick={()=>setDelete(false)}>Cancel</button>
                        </div>
                    </div>);

    return output;
}