import '../Style/VideoCard2.css'
import {useState} from 'react'
import VideoDisplay from '../PopUps/VideoDisplay';
import UpdateVideo from '../PopUps/UpdateVideo';
import DeleteVideo from '../PopUps/DeleteVideo';

export default function VideoCard2({data, owner}){
    const [play, setPlay] = useState(false);
    const [options, setOptions] =useState(false);
    const [Delete, setDelete] = useState(false);
    const [update, setUpdate] = useState(false);
    const months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec']
    const [date, setDate] = useState(new Date(data.createdDate));
    const [duration, setDuration] = useState(null);

    const handleLoadedMetadata = (event) => {
        const duration = event.target.duration; //in seconds
        const seconds = Math.round(duration%60)
        const minutes = Math.floor(duration/60)

        setDuration(`${minutes}:${seconds}`);
    };

    const output = (
                <>
                <div id='VideoCard2'>
                    <video className='video' src={data.vidLink} muted play={false} onLoadedMetadata={handleLoadedMetadata}></video>
                    <p className='duration'>{(duration)?duration:'-'}</p>
                    <p className='createdDate'>{date.getDate()} {months[date.getMonth()]} {date.getFullYear()}</p>
                    {owner && (
                        <img className='options' src='/Images/options2.png' onClick={()=>setOptions(!options)}></img>
                    )}
                    <img className='play' src='/Images/play.png' onClick={()=>setPlay(true)}></img>
                    {options && (
                        <div className='options-block'>
                            <div className='option-button'>
                                <p onClick={()=>{setDelete(true);setOptions(false);setUpdate(false);}}>Delete Post</p>
                                <img src='/Images/delete.png'></img>
                            </div>
                            <div className='option-button'>
                                <p onClick={()=>{setUpdate(true);setOptions(false);setDelete(false);}}>Update Post</p>
                                <img src='/Images/update.png'></img>
                            </div>
                        </div>
                    )}
                    {Delete && data &&(
                        <DeleteVideo setDelete={setDelete} id={data._id}></DeleteVideo>
                    )}
                    {update && data &&(
                        <UpdateVideo setUpdate={setUpdate} data={data}></UpdateVideo>
                    )}
                </div>
                {play && (
                    <VideoDisplay setPlay={setPlay} data={data}></VideoDisplay>
                )}
                </>
    )

    return output;
}