import axios from 'axios';

const url = 'https://scheduleease.co.za/api/message/';

///////////////////////////// GET REQUEST /////////////////////////////////////

export const getMessages = async() => {
    try {
        const {data} = await axios.get(url);
        return data;
    } catch (error) {
        console.log(error)
    }
}

////////////////////////////// POST REQUEST ///////////////////////////////////

export const createMessage = (newData) => { 
    
    try {
        const formData = new FormData();
        
        if(newData.senderId!=null){
            formData.append('senderId',newData.senderId);
        }
        if(newData.receiverId!=null){
            formData.append('receiverId',newData.receiverId);
        }
        if(newData.createdDate!=null){
            formData.append('createdDate',String(newData.createdDate));
        }
        if(newData.type!=null){
            formData.append('type',newData.type);
        }
        if(newData.message!=null){
            formData.append('message',newData.message);
        }
        if(newData.imgFile!=null){
            formData.append('imgFile',newData.imgFile);
        }
        if(newData.seen!=null){
            formData.append('seen',String(newData.seen));
        }
        if(newData.sent!=null){
            formData.append('sent',String(newData.sent));
        }
        
        axios.post(url,formData);
        
    } catch (error) {
       console.log(error);
    }
}

///////////////////////////////// UPDATE REQUEST /////////////////////////////////////////

export const updateMessage = (id, updatedData) =>{
    try {
        const formData = new FormData();
        
        if(updatedData.senderId!=null){
            formData.append('senderId',updatedData.senderId);
        }
        if(updatedData.receiverId!=null){
            formData.append('receiverId',updatedData.receiverId);
        }
        if(updatedData.createdDate!=null){
            formData.append('createdDate',String(updatedData.createdDate));
        }
        if(updatedData.type!=null){
            formData.append('type',updatedData.type);
        }
        if(updatedData.message!=null){
            formData.append('message',updatedData.message);
        }
        if(updatedData.imgFile!=null){
            formData.append('imgFile',updatedData.imgFile);
        }
        if(updatedData.seen!=null){
            formData.append('seen',String(updatedData.seen));
        }
        if(updatedData.sent!=null){
            formData.append('sent',String(updatedData.sent));
        }
        
        axios.patch(`${url}${id}`,formData);
  
    } catch (error) {
      console.log(error); 
    }
}

/////////////////////////////// DELETE REQUEST /////////////////////////////////////////
export const deleteMessage = (id)=> {
    try {
      axios.delete(`${url}${id}`);
    } catch (error) {
        console.log(error);
    }
  
}