import '../Style/Thumbnail2.css';
import { useState } from 'react';

export default function Thumbnail2(props){
    
    const [top,setTop] = useState(20+75*props.pos-150);
    const [pos, setPos] = useState(props.pos);
    const [total, setTot] = useState(props.total);
    const [transition, setTrans] = useState(1.5);

    function Prev(){
        if(pos == 0){
            setTop(top+(total-1)*75);
            setPos(pos+(total-1));
            setTrans(0);
        }
        else{
            setTop(top-75);
            setPos(pos-1);
            setTrans(1.5);
        }
    }

    function Next(){
        if(pos == (total-1)){
            setTop(top-(total-1)*75);
            setPos(pos-(total-1));
            setTrans(0);
        }
        else{
            setTop(top+75);
            setPos(pos+1);
            setTrans(1.5);
        }
    }

    const output = (<>
                    <div className='Thumbnail2' style={{top: top,transition: `${transition}s`}} onClick={()=>{props.Search(props.category); props.setSearch(props.category);}}>
                        <p>{props.category}</p>
                    </div>
                    <div ref={props.linkP} onClick={Prev} style={{display:'none'}}></div>
                    <div ref={props.linkN} onClick={Next} style={{display:'none'}}></div>
                    </>
                    );

    return output;
}