import '../Style/ServicerEditor.css';
import { useState, useEffect } from 'react';
import { useContext } from 'react';
import { UserDataContext } from '../Data/Session';
import { updateServicer } from '../Actions/Servicer';

export default function ServicerEditor({setEdit}){
    const [form ,setForm] = useState({name:null, surname:null, email:null, number:null, description:null , imgFile:null })
    const [image, setImage] = useState(null);
    const {userData,sessionId} = useContext(UserDataContext);

    const handleChange = (e) =>{
        const {value, name} = e.target;
        setForm(
            {
                ...form,
                [name]:value
            }
        )
    }

    const handleImage = (e) =>{
        const file = e.target.files[0];
        const reader = new FileReader();

        setForm({...form,imgFile:file});
        reader.onloadend = () => {
            setImage(reader.result)
        };
        reader.readAsDataURL(file);
    }

    const handleSubmit = (e) =>{
        e.preventDefault();
        updateServicer(userData._id,{...form,sessionId:sessionId});
        setEdit(false);
    }

    const output = (
        <div id='ServicerEditor'>
            <div className='fainted'>
                <div className='form-block'>
                    <p onClick={()=>setEdit(false)} className='closer'>&#10006;</p>
                    <h1 className='main-header'>Edit Account Details</h1>
                    <form>
                        <div className='input-block'>
                            <p>Name:</p>
                            <input value={form.name} defaultValue={userData.name} type='text' name='name' onChange={handleChange}></input>
                        </div>
                        <div className='input-block'>
                            <p>Surname:</p>
                            <input value={form.surname} defaultValue={userData.surname} type='text' name='surname' onChange={handleChange}></input>
                        </div>
                        {/* <div className='input-block'>
                            <p>Email:</p>
                            <input value={form.email} defaultValue={userData.email} type='text' name='email' onChange={handleChange}></input>
                        </div> */}
                        <div className='input-block'>
                            <p>Number:</p>
                            <input value={form.number} defaultValue={userData.number} type='text' name='number' onChange={handleChange}></input>
                        </div>
                        <div className='input-block'>
                            <p>Description:</p>
                            <textarea maxlength="220" className='description' value={form.description} defaultValue={(userData.description)?userData.description:''} name='description' onChange={handleChange}></textarea>
                        </div>
                        <div className='input-block'>
                            <p>Profile Image:</p>
                            <input value={(form.imgFile)?form.imgFile.filename:''} type='file' onChange={handleImage}></input>
                            <div className='profileInput'>
                                <img src={(image)?image:"/Images/user.png"}></img>
                                <p className='closeImg' onClick={()=>{setImage(null); setForm({...form,imgFile:null})}}>&#10006;</p>
                            </div>
                        </div>
                    </form>
                    <button className='submit-button'  onClick={handleSubmit}>Submit</button>
                </div>
            </div>
        </div>
    );

    return output;

}