import axios from 'axios';

const url = 'https://scheduleease.co.za/api/report/';

///////////////////////////// GET REQUEST /////////////////////////////////////

export const getReports = async() => {
    try {
        const {data} = await axios.get(url);
        return data;
    } catch (error) {
        console.log(error)
    }
}

////////////////////////////// POST REQUEST ///////////////////////////////////

export const createReport = (newData) => { 
    
    try {  
        axios.post(url,newData);
        
    } catch (error) {
       console.log(error);
    }
}

/////////////////////////////// DELETE REQUEST /////////////////////////////////////////
export const deleteReport = (id)=> {
    try {
      axios.delete(`${url}${id}`);
    } catch (error) {
        console.log(error);
    }
}