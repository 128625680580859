import '../Style/ExecCard.css';
import { useState, useEffect } from 'react';

export default function ExecCard({data,index,pLink, nLink}){
    const [Index, setIndex] = useState(index);
    const [zIndex, setZIndex] = useState();
    const [width,setWidth] = useState();
    const [height, setHeight] = useState();
    const [opacity, setOpacity] = useState();
    const [right, setRight] = useState();
    const [left, setLeft] = useState();
    const [top, setTop] = useState();
    const [font1, setFont1] = useState();
    const [font2, setFont2] = useState();
    const [font3, setFont3] = useState();
    
    const Width = ()=>{
        if(Index==0){
            setWidth('200px');
        }
        else if(Index==1){
            setWidth('260px');
        }
        else if(Index==2){
            setWidth('200px');
        }
    }

    const Height = ()=>{
        if(Index==0){
            setHeight('250px');
        }
        else if(Index==1){
            setHeight('400px');
        }
        else if(Index==2){
            setHeight('250px');
        }
    }

    const ZIndex = ()=>{
        if(Index==0){
            setZIndex('1');
        }
        else if(Index==1){
            setZIndex('2');
        }
        else if(Index==2){
            setZIndex('1');
        }
    }

    const Opacity = ()=>{
        if(Index==0){
            setOpacity(0.1);
        }
        else if(Index==1){
            setOpacity(1);
        }
        else if(Index==2){
            setOpacity(0.1);
        }
    }

    const Top = ()=>{
        if(Index==0){
            setTop('75px');
        }
        else if(Index==1){
            setTop('0px');
        }
        else if(Index==2){
            setTop('75px');
        }
    }

    const Left = ()=>{
        if(Index==0){
            setLeft('0px');
        }
        else if(Index==1){
            setLeft('245px');
        }
        else if(Index==2){
            setLeft('550px');
        }
    }

    const Clockwise = ()=>{
        if(Index==0){
            setIndex(2);
        }
        else if(Index==1){
            setIndex(0);
        }
        else if(Index==2){
            setIndex(1);
        }
    }

    const Anticlockwise = ()=>{
        if(Index==0){
            setIndex(1);
        }
        else if(Index==1){
            setIndex(2);
        }
        else if(Index==2){
            setIndex(0);
        }
    }

    const Font1 = ()=>{
        if(Index==0){
            setFont1('15px');
        }
        else if(Index==1){
            setFont1('20px');
        }
        else if(Index==2){
            setFont1('15px');
        }
    }

    const Font2 = ()=>{
        if(Index==0){
            setFont2('12');
        }
        else if(Index==1){
            setFont2('15px');
        }
        else if(Index==2){
            setFont2('12');
        }
    }

    const Font3 = ()=>{
        if(Index==0){
            setFont3('10px');
        }
        else if(Index==1){
            setFont3('12px');
        }
        else if(Index==2){
            setFont3('10px');
        }
    }


    useEffect(()=>{
        if(Index!=null){
            Width();
            Height();
            ZIndex();
            Opacity();
            Top();
            Left(); 
            Font1();
            Font2();
            Font3();
        }
    },[Index])

    const output = (<>
                    <div className='ExecCard' style={{height:height, width:width, zIndex:zIndex,opacity:opacity, left:left, top:top}}>
                        <div className='imgFrame'><img src={data.imgLink}></img></div>
                        <h3 style={{fontSize:font1}}>{data.position}</h3>
                        <h4 style={{fontSize:font2}}>{data.name} {data.surname}</h4>
                        <p style={{fontSize:font3}}>{data.message}</p>
                    </div>
                    <button ref={pLink} onClick={Clockwise} style={{display:'none'}}></button>
                    <button ref={nLink} onClick={Anticlockwise} style={{display:'none'}}></button>
                    </>);
    return output;
}