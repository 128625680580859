import React, { useState, useEffect,useContext } from 'react';
import { UserDataContext } from "../Data/Session.js";
import { MapContainer, TileLayer, Marker, useMapEvents, useMap, Popup } from 'react-leaflet';
import { GeoSearchControl, OpenStreetMapProvider } from 'leaflet-geosearch';
import 'leaflet-geosearch/dist/geosearch.css';
import axios from 'axios';
import '../Style/Location.css';


export default function Location({streetNo, streetName}){
    const [position, setPosition] = useState(null);
    const {userData} = useContext(UserDataContext);
    const [Map, setMap] = useState(null);
    const provider = new OpenStreetMapProvider();

    const toAddress = async (lat, lng, stNo, stName) => {
        const url = `https://nominatim.openstreetmap.org/reverse?format=jsonv2&lat=${lat}&lon=${lng}`;
    
        try {
          const response = await axios.get(url);
          const result = response.data;
    
          if (result && result.address) {
            // Extract more fields from the response
            const {city, country} = result.address;
            const query = `${streetNo} ${streetName}, ${city}, ${country}`;
            const results = await provider.search({ query });

            if(results.length>0 && Map){
              Map.flyTo({lat:results[0].y,lng:results[0].x}, Map.getMaxZoom());
            }
          }
        } catch (error) {
          console.error('Error fetching address:', error);
        }
    };

    const MapComponent = () => {
      // const map = useMapEvents({
      //   locationfound: (location) => {
      //       setPosition((prevPosition) => {
      //           const newLat = location.latlng.lat;
      //           const newLng = location.latlng.lng;
      //           if (prevPosition?.lat !== newLat || prevPosition?.lng !== newLng) {
      //             return { lat: newLat, lng: newLng };
      //           }
      //           return prevPosition; // No state update if the same position
      //       });
      //     map.flyTo(location.latlng, map.getZoom());
      //   }
      // });
  
      // useEffect(() => {
      //   map.locate({ setView: true, maxZoom: 16 });
      // }, []);

      const map = useMapEvents({
        locationfound: () => { 
          map.flyTo(userData.location, map.getZoom());
          //console.log(userData.location);
        }
      });

      setMap(map);
    
      return (position)?(<Marker position={position}>
                            <Popup>
                                A pretty CSS3 popup. <br /> Easily customizable.
                            </Popup>
                        </Marker>) 
                        : 
                        (<Marker position={[-33.9249, 18.4241]}>
                            <Popup>
                                A pretty CSS3 popup. <br /> Easily customizable.
                            </Popup>
                        </Marker>);
    };

    useEffect(() => {
      if(userData && userData.location && Map){
        Map.locate({ setView: true, maxZoom: 16 });
      }
    }, [userData, Map]);

    useEffect(()=>{
      if(streetNo && streetName && userData && userData.location){
        toAddress(userData.location.lat,userData.location.lng,streetNo,streetName)
      }
    },[streetNo, streetName, userData])

    // useEffect(()=>{
    //     if(position){
    //         fetchAddress(position.lat, position.lng);
    //     }
    // },[position])

    const output = (
                        <MapContainer center={[51.505, -0.09]} style={{ height: '100%', width: '100%', display:(userData && userData.location)?'block':'none'}} zoom={13} scrollWheelZoom={false}>
                            <TileLayer
                              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                            />
                            <MapComponent />
                        </MapContainer>
                    );

    return output;
}