import axios from 'axios';

const url = 'https://scheduleease.co.za/api/servicer/';

///////////////////////////// GET REQUEST /////////////////////////////////////

export const getServicers = async() => {
    try {
        const {data} = await axios.get(url);
        return data;
    } catch (error) {
        console.log(error)
    }
}

////////////////////////////// POST REQUEST ///////////////////////////////////

export const signUp2B = async(input) => { 
    
    try {
        const response = await axios.post(`${url}signUp2`,input);
        return response;
  
    } catch (error) {
       console.log(error);
    }
}

///////////////////////////////// UPDATE REQUEST /////////////////////////////////////////

export const updateServicer = async(id, updatedData) =>{
    try {
        const formData = new FormData();
        
        if(updatedData.createdDate!=null){
            formData.append('createdDate',String(updatedData.createdDate));
        }
        if(updatedData.location!=null){
            formData.append('location',JSON.stringify(updatedData.location));
        }
        if(updatedData.city!=null){
            formData.append('city',updatedData.city);
        }
        if(updatedData.name!=null){
            formData.append('name',updatedData.name);
        }
        if(updatedData.surname!=null){
            formData.append('surname',updatedData.surname);
        }
        if(updatedData.email!=null){
            formData.append('email',updatedData.email);
        }
        if(updatedData.imgFile!=null){
            formData.append('imgFile',updatedData.imgFile);
        }
        if(updatedData.number!=null){
            formData.append('number',updatedData.number);
        }
        if(updatedData.idNumber!=null){
            formData.append('idNumber',updatedData.idNumber);
        }
        if(updatedData.password!=null){
            formData.append('password',updatedData.password);
        }
        if(updatedData.description!=null){
            formData.append('description',updatedData.description);
        }
        if(updatedData.promotions!=null){
            formData.append('promotions',String(updatedData.promotions));
        }
        if(updatedData.sessionId!=null){
            formData.append('sessionId',updatedData.sessionId);
        }
        if(updatedData.terms!=null){
            formData.append('terms',String(updatedData.terms));
        }
        if(updatedData.jobs!=null){
            formData.append('jobs',String(updatedData.jobs));
        }
        if(updatedData.service!=null){
            formData.append('service',updatedData.service);
        }
        
        
        const response = await axios.patch(`${url}${id}`,formData);
        return response.data;
  
    } catch (error) {
      console.log(error); 
      return {updated:false}
    }
}

/////////////////////////////// DELETE REQUEST /////////////////////////////////////////
export const deleteServicer = (id)=> {
    try {
      axios.delete(`${url}${id}`);
    } catch (error) {
        console.log(error);
    }
  
}