import '../Style/RatingCard.css';
import { useContext, useState, useEffect} from 'react';
import { UserDataContext } from '../Data/Session';

export default function RatingCard({data}){
    const [rater, setRater] = useState(null);
    const [date, setDate] = useState('');
    const {users} = useContext(UserDataContext);
    const months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];

    useEffect(()=>{
        if(users && data){
            const raterInfo = users.find((user)=>user._id==data.raterId);
            setRater(raterInfo);
        
            if(data.createdDate){
                const day = new Date(data.createdDate).getDate();
                const month = new Date(data.createdDate).getMonth(); //returns an integer which will be the index for the month
                const year = new Date(data.createdDate).getFullYear();

                setDate(day+' '+months[month]+' '+year);
            }
        }
    },[data, users])    

    const output = (
        <div id="RatingCard">
            <div className='rating-profile'>
                <div className='imgSection'>
                    <div className='imgFrame'>
                        <img src={(rater && rater.profileLink && rater.profileLink!='')?rater.profileLink:"/Images/user.png"}></img>
                    </div>
                </div>
                <div className='nameSection'>
                    <p>{(rater && rater.name)?rater.name:'-'}</p>
                    <p>{(rater && rater.surname)?rater.surname:'-'}</p>
                </div>
            </div>
            <div className='rating-data'>
                <div className='starSection'>
                    <img src={`/Images/${(data.rating>0)?'star-yellow.png':'star.png'}`}></img>
                    <img src={`/Images/${(data.rating>1)?'star-yellow.png':'star.png'}`}></img>
                    <img src={`/Images/${(data.rating>2)?'star-yellow.png':'star.png'}`}></img>
                    <img src={`/Images/${(data.rating>3)?'star-yellow.png':'star.png'}`}></img>
                    <img src={`/Images/${(data.rating>4)?'star-yellow.png':'star.png'}`}></img>
                    <p className='date'>{date}</p>
                </div>
                <div className='textSection'>
                    <p>{data.comment}</p>
                </div>
            </div>
        </div>
    );

    return output;
}