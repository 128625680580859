import '../Style/Chats.css';
import { useState, useEffect } from 'react';
import { useContext } from 'react';
import { UserDataContext } from '../Data/Session';
import Message from './message';

export default function Chats({setChats, servicer}){
const months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];
const [messengers, setMessengers] = useState([]); 
const [Messenger, setMessenger] = useState(null)
const [openMessages, setOpen] = useState(false);
const [search, setSearch] = useState('');
const {messages, users, userData} = useContext(UserDataContext); 

function formatDate(date){
    const day = String(date.getDate());
    const month = String(date.getMonth());
    const year = String(date.getFullYear());

    return `${day} ${months[month]} ${year}`;
}

useEffect(()=>{
    let contacts = [];

    if(messages && users){
        const myMessages = messages.filter((message)=>(message.senderId==userData._id || message.receiverId==userData._id))
        myMessages.map((message)=>{
            let messenger = users.find((user)=>(user._id==message.senderId || user._id==message.receiverId))
            if(messenger){
                let oldMessenger = contacts.find((contact)=>(messenger && contact.messenger._id == messenger._id))
                if(oldMessenger==undefined){
                    if(!message.seen && message.receiverId==userData._id){
                        contacts = [...contacts, {messenger:messenger,message:message, unseen:1}];
                    }
                    else{
                        contacts = [...contacts, {messenger:messenger,message:message, unseen:0}];
                    }
                }
                else if(oldMessenger!=undefined){
                    const unseen = contacts.find((contact)=>contact.messenger._id==messenger._id).unseen;
                    const data = contacts.filter((contact)=>contact.messenger._id!=messenger._id);

                    if(!message.seen && message.receiverId==userData._id){
                        contacts = [...data, {messenger:messenger, message:message, unseen:unseen+1}];
                    }
                    else{
                        contacts = [...data, {messenger:messenger, message:message, unseen:unseen}];
                    }
                }
            }
        })
    }

    setMessengers(contacts);
},[messages,users])

const output = (<>
        {openMessages && Messenger &&(
            <Message setMessage={setOpen} messenger={Messenger} type={'user'}></Message>
        )}
        <div id='Chats'>
            
            <div className='Chats-list'>
                <p className='close-chats' onClick={()=>setChats(false)}>&#10006;</p>
                <input className='search-tab' type='text' onChange={(e)=>setSearch(e.target.value)}></input>
                <div className='list'>
                    {messengers && messengers.filter((data)=>((data.messenger.name+' '+data.messenger.surname).includes(search) || formatDate(new Date(data.message.createdDate)).includes(search))).map((data)=>(
                        <div className='chatCard' onClick={()=>{setMessenger(data.messenger); setOpen(true);}}>
                            <div className='profile-frame'>
                                <img src={(data.messenger.profileLink && data.messenger.profileLink!='')?data.messenger.profileLink:'/Images/user.png'} ></img>
                            </div>
                            <div className='data'>
                                <div className='messengerData'>
                                    <p className='name'>{data.messenger.name} {data.messenger.surname}</p>
                                    <p className='time'>{formatDate(new Date(data.message.createdDate))}</p>
                                </div>
                                <div className='snippet'>
                                    <p className='text'>{(data.message.imgLink)?'image':data.message.message}</p>
                                    {data.unseen!=null && data.unseen>0 &&(   
                                        <div className={(data.unseen<10)?'unseen1':'unseen2'}>
                                            <p>{data.unseen}</p>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
        </>);

return output;

}