import '../Style/DeleteFaq.css';
import { deleteFaq } from '../Actions/Faq';

export default function DeleteFaq({setDelete, id}){

    const output = (<div id='DeleteFaq'>
                        <div className='delete-block'>
                            <h2>Are you certain about deleting this faq?</h2>
                            <button className='delete-button' onClick={()=>{setDelete(false);deleteFaq(id);}}>Delete</button>
                            <button className='cancel-button' onClick={()=>setDelete(false)}>Cancel</button>
                        </div>
                    </div>);

    return output;
}