import '../Style/DeleteImage.css';
import { deleteImage } from '../Actions/Image';

export default function DeleteImage({setDelete, id}){

    const output = (<div id='DeleteImage'>
                        <div className='delete-block'>
                            <h2>Are you certain about deleting this post?</h2>
                            <button className='delete-button' onClick={()=>{setDelete(false);deleteImage(id);}}>Delete</button>
                            <button className='cancel-button' onClick={()=>setDelete(false)}>Cancel</button>
                        </div>
                    </div>);

    return output;
}