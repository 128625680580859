import '../Style/UpdateImage.css';
import { useState } from 'react';
import { updateImage } from '../Actions/Image';

export default function UpdateImage({setUpdate, data}){
    const [imagePost, setImgPost] = useState({description:data.description, imgFile:null});

    const handleChange = (e)=>{
        const {value, name} = e.target;
        if(name=='description'){
            setImgPost({...imagePost,[name]:value});
        }
        else{
            setImgPost({...imagePost,[name]:e.target.files[0]});
        }
    }

    const handleSubmit = (e)=>{
        e.preventDefault();
        updateImage(data._id,imagePost);
        setImgPost({...imagePost, imgFile:null});
        setUpdate(false);
    }

    const output = (<div id='UpdateImage'>
                             <div className="updateContent">
                                <p className='close-tab' onClick={()=>setUpdate(false)}>&#10006;</p>
                                <h1>Update Post</h1>
                                <form className="form" onSubmit={handleSubmit}>
                                    <p>Image:</p>
                                    <input name='imgFile' type='file' onChange={handleChange} value={(imagePost.imgFile)?imagePost.imgFile.filename:''}></input>
                                    <p>Description:</p>
                                    <textarea maxlength="200" name="description" onChange={handleChange} value={imagePost.description}></textarea>
                                    <button type='submit'>Post</button>
                                </form>
                            </div>
                    </div>);

    return output;
}