import '../Style/BookingPage.css';
import { useState, useEffect, useContext } from 'react';
import { UserDataContext } from '../Data/Session';
import { useParams } from 'react-router-dom';
import { updateBooking } from '../Actions/Booking'; 
import { format } from 'date-fns';
import CancelBooking from '../PopUps/CancelBooking';
import Directions from '../PopUps/Directions';
import Report from '../PopUps/Report';
import Rater from '../PopUps/Rater';
import Message from "../PopUps/message";
import {refund} from '../Actions/Card';

export default function Booking(){
    const {userData, servicers, users,cards , bookings, ratings, sessionId} = useContext(UserDataContext);
    const [currentHour, setHour] = useState(new Date().getHours())
    const [booking, setBooking] = useState(null);
    const [booker, setBooker] = useState(null);
    const [servicer, setServicer] = useState(null);
    const [card, setCard] = useState(null); // used for booking cancellations
    const {bookingId} = useParams();
    const houseSlots = ['','8:00-12:00','13:00-17:00'];
    const carSlots = ['','7:00-8:00','9:00-10:00','11:00-12:00','13:00-14:00','15:00-16:00'];
    const gardenSlots = ['','7:00-11:00','12:00-16:00'];
    const poolSlots = ['','8:00-10:00','11:00-13:00','14:00-16:00'];
    const negotiableSlots = ['','7:00-11:00','12:00-16:00'];
    const [width1, setWidth1] = useState('0%'); //stage 1 circle
    const [width2, setWidth2] = useState('0%'); //connector 1
    const [width3, setWidth3] = useState('0%'); //stage 1 circle
    const [width4, setWidth4] = useState('0%'); //connector 2
    const [width5, setWidth5] = useState('0%'); //stage 1 circle
    const [width6, setWidth6] = useState('0%'); //connector 3
    const [width7, setWidth7] = useState('0%'); //stage 1 circle
    const [circleDelay, setDelay] =useState('0s');
    const [delay1, setDelay1] = useState('0s');
    const [delay2, setDelay2] = useState('0s');
    const [transition, setTransition] = useState('0s');
    const [starting, setStarting] = useState(true);
    const [startTimer, setTimer] = useState(false);
    const [late, setLate] = useState(false);
    const [bookingDay, setDay] = useState(false);
    const [countDown, setCountDown] = useState();
    const [slotTime, setSlot] = useState();
    const [ReportOn, setReport] = useState(false);
    const [CancelBookingOn, setCancelBooking] = useState(false);
    const [RaterOn, setRater] = useState(false);
    const [OptionsOn,setOptions] = useState(false);
    const [period1, setPeriod1] = useState(false); //before slot period
    const [period2, setPeriod2] = useState(false); //during slot period or later
    const [rating, setRating] = useState(0.0)
    const [message1, setMessage1] = useState(false);
    const [message2, setMessage2] = useState(false);
    const [direction1, setDirection1] = useState(false);
    const [direction2, setDirection2] = useState(false);
    const [jobs, setJobs] = useState(null);
    const [rated, setRated] = useState(true);

    function SpecificTimeToday(hour, minute, second){
        const today = new Date(); // Get the current date and time
        today.setHours(hour, minute, second, 0); // Set the specific time (e.g., 7:00:00 AM)
        return today;
    };

    function TimeFormat(time){
        const min = Math.floor(time/60000);
        const secs = Math.floor((time % 60000) / 1000);
        return String(min).padStart(2, '0')+':'+String(secs).padStart(2, '0') 
    }

    function Period(slotNo){
        if(booking.service == 'House Cleaning'){
            return houseSlots[slotNo];
        }
        else if(booking.service == 'Pool Cleaning'){
            return poolSlots[slotNo];
        }
        else if(booking.service == 'Car Cleaning'){
            return carSlots[slotNo];
        }
        else if(booking.service == 'Gardening'){
            return gardenSlots[slotNo];
        }
    }

    function CarCost(data){
        let cost = 0;
        if(data.interior){
            cost += 20;
        }
        if(data.polish){
            cost += 30;
        }
        if(data.carType=='Light Vehicle'){
            cost += 80;
        }
        else if(data.carType=='2 Axle Heavy Vehicle'){
            cost += 120;
        }
        else if(data.carType=='3 & 4 Axle Heavy Vehicle'){
            cost += 250;
        }
        
        return cost;
    }

    const CancelBookingFunc = async()=>{
        if(booking && !booking.canceller && booking.paymentOption=='card' && userData && card && card!=undefined && card._id){
            const data = {sessionId:sessionId,bookingId:booking._id, cancelType:'Late Servicer', canceller:'User', refundType:'full',amount:booking.amount+30,card, userId:userData._id, Category1:userData.Category1, Category2to4:userData.Category2to4}
            const response = await refund(data);

            // console.log('Refund Response: ',response)
        }
        else if(booking && booking.paymentOption=='cash'){
            updateBooking(booking._id,{ cancelType:'Late Servicer', canceller:'User'});
        }
    }

    //Get my booking and the servicer/booker
    useEffect(()=>{
        if(userData && bookings && users && servicers){
            const data1 = bookings.find((booking)=>booking._id==bookingId);
            setBooking(data1);
    
            if(data1 && userData.type=='User'){
                const data2 = servicers.find((servicer)=>servicer._id == data1.servicerId); 
                setServicer(data2);
            }
            else if(data1 && userData.type=='Servicer'){
                const data2 = users.find((user)=>user._id == data1.bookerId); 
                setBooker(data2);
            }
        }
    },[bookings, userData, servicers, users])

    //Handling the visibilty and function of the buttons based off of the booking progress stages
    useEffect(()=>{
        if(booking && starting==false){
    
            if(booking.progress.stage4a==true && booking.progress.stage4b==true){
                setDelay('2s');
                setTransition('2s');
                setWidth6('100%');
                setWidth7('100%');
            }
            else if(booking.progress.stage3==true && booking.paymentOption=='Cash'){
                setDelay('2s');
                setDelay1('0s');
                setDelay2('2s');
                setTransition('2s');
                setWidth4('100%');
                setWidth5('100%');
            }
            else if(booking.progress.stage2==true && booking.paymentOption=='Cash'){
                setDelay('2s');
                setTransition('2s');
                setWidth2('100%');
                setWidth3('100%');
            }
            else if(booking.progress.stage2==true && booking.paymentOption=='Card'){
                setDelay1('4s');
                setDelay2('6s');
                setDelay('2s');
                setTransition('2s');
                
                setWidth2('100%');
                setWidth3('100%');
                setWidth4('100%');
                setWidth5('100%');
            }
            else if(booking.progress.stage1==true){
                setTransition('2s');
                setWidth1('100%');
            }
        }
        else if(booking && starting==true){
            setDelay('0s');
            setTransition('0s');
            if(booking.progress.stage4a==true && booking.progress.stage4b==true){
                setWidth6('100%');
                setWidth7('100%');
            }
            if(booking.progress.stage3==true && booking.progress.stage2==true){
                setWidth4('100%');
                setWidth5('100%');
            }
            if(booking.progress.stage2==true){
                setWidth2('100%');
                setWidth3('100%');
            }
            if(booking.progress.stage1==true){
                setWidth1('100%');
            }
            setStarting(false);
        }
    },[booking])

    //Checking if its the booking day and time
    useEffect(()=>{
        if(booking && currentHour!=null){
            const currentDate = format(new Date(), 'dd/MM/yyyy');
          
            if(booking.service == 'House Cleaning' && currentDate==booking.appointment.date){
                setDay(true);
                if(booking.appointment.slot1 == 1 && currentHour>8){
                    setTimer(true);
                    setLate(true);
                    setSlot(8);
                }
                else if(booking.appointment.slot1 == 2 && currentHour>13){
                    setTimer(true);
                    setLate(true);
                    setSlot(13);
                }
            }
            else if(booking.service == 'Pool Cleaning' && currentDate==booking.appointment.date){
                setDay(true);
                if(booking.appointment.slot1 == 1 && currentHour>8){
                    setTimer(true);
                    setLate(true);
                    setSlot(8);
                }
                else if(booking.appointment.slot1 == 2 && currentHour>11){
                    setTimer(true);
                    setLate(true);
                    setSlot(11);
                }
                else if(booking.appointment.slot1 == 3 && currentHour>14){
                    setTimer(true);
                    setLate(true);
                    setSlot(14);
                }
            }
            else if(booking.service == 'Car Cleaning' && currentDate==booking.appointment.date){
                setDay(true);
                if(booking.appointment.slot1 == 1 && currentHour>7){
                    setTimer(true);
                    setLate(true);
                    setSlot(7);
                }
                else if(booking.appointment.slot1 == 2 && currentHour>9){
                    setTimer(true);
                    setLate(true);
                    setSlot(9);
                }
                else if(booking.appointment.slot1 == 3 && currentHour>11){
                    setTimer(true);
                    setLate(true);
                    setSlot(11);
                }
                else if(booking.appointment.slot1 == 4 && currentHour>13){
                    setTimer(true);
                    setLate(true);
                    setSlot(13);
                }
                else if(booking.appointment.slot1 == 5 && currentHour>15){
                    setTimer(true);
                    setLate(true);
                    setSlot(15);
                }
            }
            else if(booking.service == 'Gardener' && currentDate==booking.appointment.date){
                setDay(true);
                if(booking.appointment.slot1 == 1 && currentHour>7){
                    setTimer(true);
                    setLate(true);
                    setSlot(7);
                }
                else if(booking.appointment.slot1 == 2 && currentHour>12){
                    setTimer(true);
                    setLate(true);
                    setSlot(12);
                }
            }
            else if(booking.type == 'negotiable' && currentDate==booking.appointment.date){
                setDay(true);
                if(booking.appointment.slot1 == 1 && currentHour>7){
                    setTimer(true);
                    setLate(true);
                    setSlot(7);
                }
                else if(booking.appointment.slot1 == 2 && currentHour>12){
                    setTimer(true);
                    setLate(true);
                    setSlot(12);
                }
            }
            
        }
    },[booking, currentHour])

    //Keeping track of the current hour period
    useEffect(()=>{
        const loop = setInterval(()=>{
            setHour(new Date().getHours());
        }, 1000);
    
        return () => clearInterval(loop);
    },[])

    //Count down of 10 minutes once the booking starts
    useEffect(()=>{
        if(startTimer==true && slotTime){
            let end =  SpecificTimeToday(slotTime, 10,0);
            const loop = setInterval(()=>{
                const timeDifference = end - (new Date());
                setCountDown(TimeFormat(timeDifference));
                if(timeDifference < 0){
                    setTimer(false);
                    return () => clearInterval(loop);
                }
            }, 1000);
        
            return () => clearInterval(loop);
        }
    },[startTimer, slotTime])

    //Determing the current period (booking day and before OR booking day and after)
    useEffect(()=>{
        if(booking && currentHour!=null){
            const currentDateTime = new Date();

            //if its during the slot period or later (Period 2)
            if(late || (currentDateTime-booking.appointment.dateTime>0)){
                setPeriod2(true);
            }
            else{
                setPeriod1(true);
            }
        }
    },[booking, currentHour, late])

    //Count the number of bookings completed by the servicer
    useEffect(()=>{
        if(bookings && servicer){
            const data = bookings.filter((booking)=>booking.servicerId == servicer._id && booking.progress.stage4a==true && booking.progress.stage4b==true);
            setJobs(data.length);
        }
    },[bookings, servicer])

    useEffect(()=>{
        if(ratings && servicer){
            const ratingArr = ratings.filter((rating)=>rating.servicerId==servicer._id);
            let ratingAve = 0;
            for(let i = 0; i<ratingArr.length; i++){
                ratingAve += ratingArr[i].rating;
            }
            ratingAve = (ratingArr.length>0)?Math.round(ratingAve/ratingArr.length):0;  
            setRating(parseFloat(ratingAve.toFixed(1)));  
        }
        else if(ratings && booker){
            const ratingArr = ratings.filter((rating)=>rating.servicerId==userData._id);
            let ratingAve = 0;
            for(let i = 0; i<ratingArr.length; i++){
                ratingAve += ratingArr[i].rating;
            }
            ratingAve = (ratingArr.length>0)?Math.round(ratingAve/ratingArr.length):0;  
            setRating(parseFloat(ratingAve.toFixed(1)));  
        }
    },[ratings, servicer, booker])

    useEffect(()=>{
        if(ratings && servicer && booking){
            const bookingRating = ratings.find((rating)=>rating.servicerId==servicer._id && rating.bookingId && bookingId==rating.bookingId);
            if(bookingRating){
                setRated(true);
            }
            else{
                setRated(false);
            }
        }
    },[booking, ratings, servicer])

    useEffect(()=>{
        if(cards && booking && booking.paymentOption=='Card'){
            const cardData = cards.find((info)=>info._id==booking.cardId);
            setCard(cardData);
        }
    },[cards, booking])

    const output = (<div id='BookingPage'>
                        {ReportOn==true && (
                            <Report setReport={setReport}  reporterId={userData._id} offenderId={(booker)?booker._id:servicer._id} offender={(booker)?'Booker':'Servicer'}></Report>
                        )}
                        {RaterOn==true && servicer && booking &&(
                            <Rater setRater={setRater} servicerId={servicer._id} bookingId={booking._id}></Rater>
                        )}
                        {CancelBookingOn==true && (
                            <CancelBooking setCancelBooking={setCancelBooking} booking={booking} canceller={(booker)?'Servicer':'User'}></CancelBooking>
                        )}
                        {message1==true && servicer &&(<>
                            <Message setMessage={setMessage1} messenger={servicer} type={'servicer'}></Message>
                        </>)}
                        {message2==true && booker && (<>
                            <Message setMessage={setMessage2} messenger={booker} type={'user'}></Message>
                        </>)}
                        {direction1==true && (<>
                            <Directions setOpen={setDirection1}></Directions>
                        </>)}
                        {direction2==true && (<>
                            <Directions setOpen={setDirection2}></Directions>
                        </>)}
                        <div className='progress-block'>
                            <div className='circle1'>
                                <div className='inner-circle'>
                                    <p>1</p>
                                    <div className='circle-filler' style={{width:width1, height:width1, transition: transition}}></div>
                                </div>
                            </div>

                            <div className='connector1'>
                                <div className='inner-connector'>
                                    <div className='connector-filler' style={{width:width2, transition: transition}}></div>
                                </div>
                            </div>

                            <div className='circle2'>
                                <div className='inner-circle'>
                                    <p>2</p>
                                    <div className='circle-filler' style={{width:width3, height:width3, transitionDelay:circleDelay, transition: transition}}></div>
                                </div>
                            </div>

                            <div className='connector2'>
                                <div className='inner-connector'>
                                    <div className='connector-filler' style={{width:width4, transitionDelay:delay1, transition: transition}}></div>
                                </div>
                            </div>

                            <div className='circle3'>
                                <div className='inner-circle'>
                                    <p>3</p>
                                    <div className='circle-filler' style={{width:width5, height:width5, transitionDelay:delay2, transition: transition}}></div>
                                </div>
                            </div>

                            <div className='connector3'>
                                <div className='inner-connector'>
                                    <div className='connector-filler' style={{width:width6, transition: transition}}></div>
                                </div>
                            </div>

                            <div className='circle4'>
                                <div className='inner-circle'>
                                    <p>4</p>
                                    <div className='circle-filler' style={{width:width7, height:width7, transitionDelay:circleDelay, transition: transition}}></div>
                                </div>
                            </div>
                        </div>
                        <div className='progress-labels'>                            
                            <p className='label1'>On The Way</p>
                            <p className='label2'>Arrived</p>
                            <p className='label3'>Paid</p>
                            <p className='label4'>Task Completed</p>                       
                        </div>
                        <div className='data-block'>
                            <div className='left-block'>
                                {booking && servicer && (
                                    <div className='profile-block'>
                                        <div className={`options-block ${(period1==true)?'grid1':'grid2'}`} style={{display:(OptionsOn==true)?'grid':'none'}}>
                                            <div style={{display:(!booking.canceller && period1==true)?'flex':'none'}}>
                                                <p onClick={()=>setCancelBooking(true)}>Cancel Booking</p>
                                            </div>
                                            <div style={{display:(!booking.canceller && period2==true)?'flex':'none'}}>
                                                <p onClick={()=>setReport(true)}>Report Servicer</p>
                                            </div>
                                            <div style={{display:(!booking.canceller && period2==true && booking.progress.stage4a==true && rated==false)?'flex':'none'}}>
                                                <p onClick={()=>setRater(true)}>Rate Servicer</p>
                                            </div>
                                        </div>
                                        <img className='options' src='/Images/options.png' onClick={()=>setOptions(!OptionsOn)}></img>
                                        <div className='frame'>
                                            <img src={(servicer.profileLink)?servicer.profileLink:'/Images/user.png'}></img>
                                        </div>
                                        <p className='booking-statement'>Booking of {booking.service} from:</p>
                                        <p className='booking-name'>{servicer.name}</p>
                                        <p className='booking-surname'>{servicer.surname}</p>
                                        <p className='booking-rating'>{rating.toFixed(1)}</p>
                                        <img className='rating-star' src='/Images/star-yellow.png'></img>
                                        <p className='booking-jobs'>{(jobs)?jobs:'0'} Jobs</p>
                                        <div className='buttons-block2'>
                                            <button className="message-button" onClick={()=>setMessage1(true)}>Message</button>
                                            <button className="directions-button" onClick={()=>setDirection1(true)}>Directions</button>
                                        </div>
                                    </div>
                                )}
                                {booking && booker && (
                                    <div className='profile-block'>
                                        <div className='options-block grid1' style={{display:(OptionsOn==true)?'grid':'none'}}>
                                            <div style={{display:(!booking.canceller && period1==true)?'flex':'none'}}>
                                                <p onClick={()=>setCancelBooking(true)}>Cancel Booking</p>
                                            </div>
                                            <div style={{display:(!booking.canceller && period2==true)?'flex':'none'}}>
                                                <p onClick={()=>setReport(true)}>Report Booker</p>
                                            </div>
                                        </div>
                                        <img className='options' src='/Images/options.png' onClick={()=>setOptions(!OptionsOn)}></img>
                                        <div className='frame'>
                                            <img src={(booker.profileLink)?booker.profileLink:'/Images/user.png'}></img>
                                        </div>
                                        <p className='booking-statement'>Booking made by:</p>
                                        <p className='booking-name'>{booker.name}</p>
                                        <p className='booking-surname'>{booker.surname}</p>
                                        <div className='buttons-block2'>
                                            <button className="message-button" onClick={()=>setMessage2(true)}>Message</button>
                                            <button className="directions-button" onClick={()=>setDirection2(true)}>Directions</button>
                                        </div>
                                    </div>
                                )}
                                {booking && userData && userData.type=='User' && (
                                    <div className='buttons-block1'>
                                        <button style={{opacity:(booking.progress.stage2==false && bookingDay==true  && !booking.canceller)?1:0.5}} onClick={()=>(booking.progress.stage2==false && bookingDay==true && !booking.canceller)?updateBooking(booking._id,{progress:{...booking.progress,stage1:true,stage2:true}}):()=>{}}>Servicer has arrived</button>
                                        <button style={{opacity:(startTimer==false && bookingDay==true && !booking.canceller)?1:0.5, display:(late==true && booking.progress.stage2==false)?'block':'none'}}  onClick={()=>CancelBookingFunc()}>Cancel booking</button>
                                        <button style={{opacity:(booking.progress.stage4a==false && booking.progress.stage3==true && bookingDay==true && !booking.canceller)?1:0.5}} onClick={()=>(booking.progress.stage4a==false && booking.progress.stage3==true && bookingDay==true && !booking.canceller)?updateBooking(booking._id,{progress:{...booking.progress,stage4a:true}, completedDate:new Date().getTime()}):()=>{}}>Tasks completed</button>
                                    </div>
                                )}
                                {booking && userData && userData.type=='Servicer' && (
                                    <div className='buttons-block1'>
                                        <button style={{opacity:(booking.progress.stage1==false && bookingDay==true && !booking.canceller)?1:0.5}} onClick={()=>(booking.progress.stage1==false && bookingDay==true && !booking.canceller)?updateBooking(booking._id,{progress:{...booking.progress,stage1:true}}):()=>{}}>On the way</button>
                                        <button style={{opacity:(booking.progress.stage3==false && booking.progress.stage2==true && bookingDay==true && !booking.canceller)?1:0.5}} onClick={()=>(booking.progress.stage3==false && booking.progress.stage2==true && bookingDay==true && !booking.canceller)?updateBooking(booking._id,{progress:{...booking.progress,stage3:true}}):()=>{}}>Payment recieved</button>
                                        <button style={{opacity:(booking.progress.stage4b==false && booking.progress.stage3==true && bookingDay==true && !booking.canceller)?1:0.5}} onClick={()=>(booking.progress.stage4b==false && booking.progress.stage3==true && bookingDay==true && !booking.canceller)?updateBooking(booking._id,{progress:{...booking.progress,stage4b:true}, completedDate:new Date().getTime()}):()=>{}}>Tasks completed</button>
                                    </div>
                                )}
                            </div>
                            <div className='right-block'>
                                <div className='late-block' style={{display:(late==true && startTimer==true && bookingDay==true && !booking.canceller)?'block':'none'}}>
                                    <p className='late-message'>If more than 10 minutes late you can cancel the booking and get a refund</p>
                                    <p className='late-time'>{countDown}</p>
                                </div>
                                <div className='booking-block'>
                                    {booking && booking.type=='negotiable' &&(
                                        <div className='slot-card'>
                                            <p className='sc-name'>Slot {booking.appointment.slot1}</p>
                                            <p className='sc-fee'>FREE</p>
                                            <p className='sc-period'>{negotiableSlots[booking.appointment.slot1]}</p>
                                            <p className='sc-date'>{booking.appointment.date}</p>
                                        </div>
                                    )}
                                    {booking && booking.type=='fixed' && (
                                        <div className='slot-card'>
                                            <p className='sc-name'>Slot {booking.appointment.slot1}</p>
                                            <p className='sc-fee'>FREE</p>
                                            <p className='sc-period'>{Period(booking.appointment.slot1)}</p>
                                            <p className='sc-date'>{booking.appointment.date}</p>
                                        </div>
                                    )}
                                    {booking && booking.type=='fixed' && booking.appointment.slot2 &&(
                                        <div className='slot-card'>
                                            <p className='sc-name'>Slot {booking.appointment.slot2}</p>
                                            <p className='sc-fee'>FREE</p>
                                            <p className='sc-period'>{Period(booking.appointment.slot2)}</p>
                                            <p className='sc-date'>{booking.appointment.date}</p>
                                        </div>
                                    )}
                                    {booking && booking.service=='House Cleaning' &&(
                                        <div className='house-card'>
                                            <p className='hc-name'>Common Areas</p>
                                            <p className='hc-fee'>R{ booking.house.commonCost}</p>
                                            <div className='extras-block'>    
                                                <p className='extra' style={{display:( booking.house.laundry)?'block':'none'}}>laundry</p>
                                                <p className='extra' style={{display:( booking.house.cabinets)?'block':'none'}}>cabinets</p>
                                                <p className='extra' style={{display:( booking.house.windows)?'block':'none'}}>windows</p>
                                                <p className='extra' style={{display:( booking.house.fridge)?'block':'none'}}>fridge</p>
                                                <p className='extra' style={{display:( booking.house.oven)?'block':'none'}}>oven</p>
                                            </div>
                                        </div>
                                    )}
                                    {booking && booking.service=='House Cleaning' &&  booking.house.rooms.map((data,index)=>
                                        <div className='room-card'>
                                            <p className='rc-name'>Room {index+1}</p>
                                            <p className='rc-fee'>R{(data==true)?'35':'15'}</p>
                                            <p className='room-windows' style={{display:(data==true)?'block':'none'}}>windows</p>
                                        </div>
                                    )} 
                                    {booking && booking.service=='House Cleaning' &&  booking.house.bathrooms.map((data,index)=>
                                        <div className='bathroom-card'>
                                            <p className='bc-name'>Bathroom {index+1}</p>
                                            <p className='bc-fee'>R{(data==true)?'55':'35'}</p>
                                            <p className='bathroom-windows' style={{display:(data==true)?'block':'none'}}>windows</p>
                                        </div>
                                    )}
                                    {booking && booking.service=='Pool Cleaning' &&(
                                        <div className='pool-card'>
                                            <p className='pc-name'>Pool Cleaning</p>
                                            <p className='pc-fee'>R{ booking.amount}</p>
                                            <div className='extras-block'>
                                                <p className='extra' style={{display:( booking.pool.tile)?'block':'none'}}>tile</p>
                                                <p className='extra' style={{display:( booking.pool.deck)?'block':'none'}}>deck</p>
                                                <p className='extra' style={{display:( booking.pool.inspection)?'block':'none'}}>inspection</p>
                                            </div>
                                        </div>
                                    )}
                                    {booking && booking.service=='Gardening' &&(
                                        <div className='garden-card'>
                                            <p className='gc-name'>Pool Cleaning</p>
                                            <p className='gc-fee'>R{ booking.amount}</p>
                                            <div className='extras-block'>
                                                <p className='extra' style={{display:( booking.garden.fertilize)?'block':'none'}}>fertilize</p>
                                                <p className='extra' style={{display:( booking.garden.control)?'block':'none'}}>pest control</p>
                                                <p className='extra' style={{display:( booking.garden.inspection)?'block':'none'}}>inspection</p>
                                            </div>
                                        </div>
                                    )}
                                    {booking && booking.service=='Car Cleaning' && booking.car.map((data)=>
                                        <div className='car-card'>
                                            <p className='cc-name'>{data.carType}</p>
                                            <p className='cc-fee'>R{CarCost(data)}</p>
                                            <div className='extras-block'>
                                                <p className='extra' style={{display:(data.interior)?'block':'none'}}>interior</p>
                                                <p className='extra' style={{display:(data.polish)?'block':'none'}}>polish tyres</p>
                                            </div>
                                        </div>
                                    )}
                                </div>
                                <div className='totals-block'>
                                    <div className='tb-left'>
                                        <p className='subtotal-statement'>Sub-Total:</p>
                                        <p className='fee-statement'>Service Fee:</p>
                                        <p className='total-statement'>Total:</p>
                                    </div>
                                    <div className='tb-right'>
                                        <p className='subtotal-value'>R{(booking)?booking.amount:'-'}</p>
                                        <p className='fee-value'>R30</p>
                                        <p className='total-value'>R{(booking)?booking.amount+30:'30'}</p>
                                        <div className='payment-type1'><p>{(booking && booking.canceller)?'Cancelled & Refunded':(booking && booking.paymentOption=='Cash')?'Cash Payment':'Card Payment'}</p></div>
                                    </div>
                                </div>
                            </div>
                            <div className='payment-type2'><p>{(booking && booking.canceller)?'Cancelled & Refunded':(booking && booking.paymentOption=='Cash')?'Cash Payment':'Card Payment'}</p></div>
                        </div>
                    </div>
                    );

    return output;
}